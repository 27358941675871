export enum CreateAddInvestmentTransferStep {
  Form = 'form',
  Summary = 'summary',
}

export const HOMEPAGE = '/';

export const ADVISER = '/adviser';
export const ADVISER_ADD = '/add-adviser';
export const ADVISER_VIEW = '/view-adviser/:id';
export const createAdviserViewRoute = (id) => ADVISER_VIEW.replace(':id', id);
export const ADVISER_EDIT = '/update-adviser/:id';
export const createAdviserEditRoute = (id) => `/update-adviser/${id}`;

export const ALLOCATIONS_UPLOAD = '/add-update-upload-sheet';

export const ALL_FUNDS = '/all-tranches';

export const COMING_SOON = '/coming-soon';

export const COMPANY = '/company';
export const COMPANY_ADD = '/add-company';
export const COMPANY_EDIT = '/edit-company/:id';
export const createCompanyEditRoute = (id) => `/edit-company/${id}`;

export const DASHBOARD = '/dashboard';
export const DASHBOARD_ALL_FIRMS = '/dashboard/all-firms';
export const DASHBOARD_CLOSED_TRANCHE = '/dashboard/closed-tranche/:trancheId';
export const createClosedTrancheRoute = (id) =>
  `/dashboard/closed-tranche/${id}`;
export const DASHBOARD_MY_FIRM = '/dashboard/my-firm';
export const DASHBOARD_OPEN_TRANCHE = '/dashboard/open-tranche/:trancheId';
export const createOpenTrancheRoute = (id) => `/dashboard/open-tranche/${id}`;
export const DASHBOARD_SINGLE_FIRM = '/dashboard/firm/:firmId';
export const createSingleFirmRoute = (id) => `/dashboard/firm/${id}`;

export const EIS = '/eiswizard';
export const EIS_ADD = '/add-eiswizard';
export const KI_EIS_ADD = '/add-ki-fund-certificate-batch';
export const KI_EIS_EDIT = '/ki-fund-certificate-batch/:batchId';
export const EIS_EDIT = '/edit-eiswizard/:id';
export const createKiFundCertificatesBatchEditRoute = (id) =>
  `/ki-fund-certificate-batch/${id}`;
export const createEisEditRoute = (id) => `/edit-eiswizard/${id}`;

export const TAX_REPORT_AUDIT = '/tax-report-audit';

export const ERROR_404 = '/404';

export const EXITS = '/exits/list';
export const EXITS_ADD = '/exits/process-new-exit';
export const EXITS_EDIT = '/exits/edit/:id';
export const createExitEditRoute = (id) => `/exits/edit/${id}`;

export const FEE_DISCOUNT_ADD = '/add-fee-discount';
export const ONE_OFF_FEES_AND_DISCOUNTS = '/one-off-fees-and-discounts';
export const FEE_DISCOUNT_SUCCESS = '/fee-or-discount-success';
export const FEE_SUMMARY = '/fee-summary';

export const FIRM = '/firm';
export const FIRM_ADD = '/add-firm';
export const FIRM_EDIT = '/edit-firm/:id';
export const createFirmEditRoute = (id) => `/edit-firm/${id}`;

export const INCOMPLETE_REGISTER = '/incomplete-register';
export const INCOMPLETE_TRANSACTION = '/incomplete-transaction';

export const INTEREST_PAYMENTS = '/interest-payments';
export const INTEREST_PAYMENTS_ADD = '/interest-payments/create';

export const INVESTMENT = '/investment';
export const INVESTMENT_EDIT = '/edit-investment/:id';
export const createInvestmentEditRoute = (id) => `/edit-investment/${id}`;
export const createInvestmentsListByFundRoute = (id) =>
  `${INVESTMENT}?fundId=${id}`;
export const INVESTMENT_ADD = '/add-investment';

export const BULK_PAYMENT_ASSIGNMENT = '/bulk-payment-assignment';

export const INVESTOR = '/investor';
export const INVESTOR_EDIT = '/edit-investor/:id';
export const INVESTOR_ADD = '/add-investor';
export const createInvestorEditRoute = (id) => `/edit-investor/${id}`;
export const INVESTOR_UPLOAD = '/upload-investor-investment';
export const INVESTOR_VIEW = '/view-investor/:id';
export const createInvestorViewRoute = (id) => `/view-investor/${id}`;

export const INVESTOR_REPORTING = '/investor-reporting';
export const INVESTOR_REPORTING_ADD = '/add-update-investor-reporting';
export const INVESTOR_REPORTING_EDIT = '/add-update-investor-reporting/:id';
export const createInvestorReportingEditRoute = (id) =>
  `/add-update-investor-reporting/${id}`;
export const INVESTOR_REPORTING_SUCCESS = '/investor-reporting/success/:id';
export const createInvestorReportingEditSuccessRoute = (id) =>
  `/investor-reporting/success/${id}`;
export const INVESTOR_REPORTING_VIEW = '/view-investor-reporting/:id';
export const createInvestorReportingViewRoute = (id) =>
  `/view-investor-reporting/${id}`;
export const INVESTOR_REPORTING_PDF_DOWNLOAD =
  '/investor-reporting/pdf-download/:id';
export const createInvestorReportingPdfDownloadRoute = (id) =>
  `/investor-reporting/pdf-download/${id}`;

export const EXIT_STATEMENT_PDF_DOWNLOAD =
  '/exit-statement/pdf-download/:exitStatementId';
export const createExitStatementPdfDownloadRoute = (exitStatementId) =>
  `/exit-statement/pdf-download/${exitStatementId}`;

export const ORGANISATION = '/organisation';
export const ORGANISATION_ADD = '/add-organisation';
export const ORGANISATION_EDIT = '/update-organisation/:id';
export const createOrganisationEditRoute = (id: string) =>
  `/update-organisation/${id}`;
export const ORGANISATION_VIEW = '/view-organisation/:id';
export const createOrganisationViewRoute = (id: string) =>
  ORGANISATION_VIEW.replace(':id', id);
export const ORGANISATION_ADD_USER = '/add-organisation-user/:organisationId';
export const createOrganisationAddUserRoute = (id: string) =>
  ORGANISATION_ADD_USER.replace(':organisationId', id);

export const PROFILE = '/profile';

export const SUBSCRIPTION = '/subscription';

export const TAG = '/tag';
export const TAG_ADD = '/add-tag';
export const TAG_EDIT = '/edit-tag/:id';
export const createTagEditRoute = (id) => `/edit-tag/${id}`;

export const TRANCHE = '/tranche';
export const TRANCHE_ADD = '/add-tranche';
export const TRANCHE_EDIT = '/edit-tranche/:id';
export const createTrancheEditRoute = (id) => `/edit-tranche/${id}`;
export const TRANCHE_BESPOKE_LINK = '/tranche-bespoke-link';

export const UPLOAD_SHEET = '/upload-sheet';
export const UPLOAD_SHEET_ADD = '/add-update-upload-sheet';
export const UPLOAD_SHEET_VIEW = '/view-upload-sheet/:id';
export const createUploadSheetViewRoute = (id) => `/view-upload-sheet/${id}`;

export const USER = '/user';
export const USER_ADD = '/add-user';
export const USER_EDIT = '/edit-user/:id';
export const createEditUserRoute = (id: string) => `/edit-user/${id}`;

export const WITHDRAWALS = '/withdrawals';
export const WITHDRAWALS_ADD = '/withdrawals/request';

export const SHARE_SPLIT_ADD = '/add-share-split/:companyId';
export const createShareSplitAddRoute = (id) => `/add-share-split/${id}`;

export const INVESTOR_BULLETIN = '/investor-bulletin';

export const ADD_CUSTOM_UPDATE = '/add-custom-update';
export const VIEW_CUSTOM_UPDATE = '/view-custom-update/:id';
export const createCustomUpdateViewRoute = (id) => `/view-custom-update/${id}`;
export const ADD_DEPLOYMENT_UPDATE =
  '/add-deployment-update/:shareAllocationUploadId/:companyId';
export const createDeploymentUpdateAddRoute = (id, companyId) =>
  `/add-deployment-update/${id}/${companyId}`;
export const createDeploymentUpdateViewRoute = (id) =>
  `/view-deployment-update/${id}`;
export const VIEW_DEPLOYMENT_UPDATE = '/view-deployment-update/:id';

export const ADD_EXIT_STATEMENT = '/add-exit-statement/:id/:exitAmendmentId?';
export const createExitStatementAddRoute = (id, exitAmendmentId) =>
  `/add-exit-statement/${id}/${exitAmendmentId ?? ''}`;

export const UPDATE_EXIT_STATEMENT = '/update-exit-statement/:id';
export const createUpdateExitStatementRoute = (exitStatementId) =>
  `/update-exit-statement/${exitStatementId}`;

export const VIEW_EXIT_STATEMENT = '/view-exit-statement/:id';
export const createExitStatementViewRoute = (exitStatementId) =>
  `/view-exit-statement/${exitStatementId}`;

export const CHANGE_LOG = '/change-log';

export const INVESTMENT_TRANSFERS = '/investment-transfers';
export const ADD_INVESTMENT_TRANSFER =
  '/add-investment-transfer/:investorId/:step?';
export const createAddInvestmentTransferRoute = (
  investorId: string,
  step = CreateAddInvestmentTransferStep.Form,
) =>
  ADD_INVESTMENT_TRANSFER.replace(':investorId', investorId).replace(
    ':step',
    step ?? '',
  );
