import React from 'react';
import { numberToCurrencyString } from 'further-ui/utils';
import PieChart, { useStyles as usePieChartStyles } from 'components/PieChart';
import { CmtTitle, CmtSubTitle } from 'components/CmtTypography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
  },
  title: {
    fontSize: '16px',
  },
  subTitleStyle: {
    marginBottom: 0,
    marginTop: 4,
    fontSize: 12,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const CompanyCategoryPieChart = ({
  deploymentData,
  title,
  subtitle,
  height,
  outerRadius,
  innerRadius,
  numberOfLegendToSkip,
}) => {
  const classnames = useStyles();
  const pieChartClassnames = usePieChartStyles();

  return (
    <>
      <div className={classnames.heading}>
        <CmtTitle variant="h4" content={title} className={classnames.title} />
        <CmtSubTitle
          variant="h5"
          className={classnames.subTitleStyle}
          content={subtitle}
        />
      </div>
      <PieChart
        data={deploymentData?.map((item) => ({
          key: item.categoryKey,
          value: item.categoryCurrentValue,
          companiesInCategory: item.companiesInCategory,
          categoryInitialInvestment: item.categoryInitialInvestment,
        }))}
        height={height}
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        numberOfLegendToSkip={numberOfLegendToSkip}
        noDataMessage="No investments yet"
        CustomTooltip={({ payload }) => {
          const item = payload[0];
          if (!item) return null;
          return (
            <div className={pieChartClassnames.tooltipWrapper}>
              <p>
                <strong>{item.name}</strong>
              </p>
              <p>
                Current value:{' '}
                {numberToCurrencyString(item.value, {
                  hidePenniesIfInteger: true,
                })}
              </p>
              <p>
                Initial investment:{' '}
                {numberToCurrencyString(
                  item.payload?.categoryInitialInvestment,
                  { hidePenniesIfInteger: true },
                )}
              </p>
              <p>
                Companies in this category: {item.payload?.companiesInCategory}
              </p>
            </div>
          );
        }}
      />
    </>
  );
};
