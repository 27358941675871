import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Divider, CircularProgress, Box, Button } from '@material-ui/core';

import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';

import { ADVISER, createAdviserEditRoute } from 'constants/routes';
import AdviserFeeAmendments from './AdviserFeeAmendments';
import AdviserBasicDetails from './AdviserBasicDetails';
import { useAdviser } from 'hooks/data/adviser/useAdviser';
import AdviserInvestments from './AdviserInvestments';
import OrganisationDetails from './OrganisationDetails';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';

const ViewAdviser = () => {
  const { id } = useParams();
  const adviser = useAdviser({ params: { id } });

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Adviser', link: ADVISER },
    { label: 'View Adviser', link: '/', isActive: true },
  ];

  const adviserData = adviser?.fetch?.data?.adviser || {};

  const handleAdviserStatement = () => {
    adviser.exportStatement.fetch();
  };

  return (
    <React.Fragment>
      {id && adviser.fetch.isLoading ? (
        <CircularProgress />
      ) : (
        <PageContainer heading={'View adviser'} breadcrumbs={breadcrumbs}>
          <CmtCard>
            <CmtCardContent>
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                gridGap="16px"
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAdviserStatement}
                  disabled={adviser.exportStatement.isFetching}
                >
                  {adviser.exportStatement.isFetching ? (
                    <CircularProgress
                      style={{ color: 'rgba(0, 0, 0, 0.26)', marginRight: 10 }}
                      size={18}
                    />
                  ) : null}
                  Download adviser statement
                </Button>

                <Link to={createAdviserEditRoute(id)}>
                  <Button variant="outlined" color="primary" size="small">
                    Edit adviser details
                  </Button>
                </Link>
              </Box>

              <GridContainer item md={11}>
                <AdviserBasicDetails adviserData={adviserData} />
                <AdviserFeeAmendments
                  changeToInitialInvestmentFees={
                    adviserData.changeToInitialInvestmentFees
                  }
                  commissionOnInitialInvestmentFees={
                    adviserData.commissionOnInitialInvestmentFees
                  }
                  firmsInFeeAmendments={adviserData.firmsInFeeAmendments}
                />
              </GridContainer>

              <Divider style={{ margin: '2rem 0' }} />

              <GridContainer item md={11}>
                <OrganisationDetails org={adviserData.organisation} />
              </GridContainer>

              <Divider style={{ margin: '2rem 0' }} />

              <AdviserInvestments
                adviserId={id}
                adviserName={adviserData.fullName}
              />

              <Divider style={{ margin: '2rem 0' }} />

              <Notes relationId={id} noteRelation={NoteRelation.Adviser} />
            </CmtCardContent>
          </CmtCard>
        </PageContainer>
      )}
    </React.Fragment>
  );
};
export default ViewAdviser;
