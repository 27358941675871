import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { numberToCurrencyString } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import InvestmentInflowChart from './InvestmentInflowChart';
import { INVESTMENT, INVESTOR } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  csmTxt: {
    fontSize: '13px',
    color: theme.palette.text.txtLight,
    margin: '5px 0 15px',
  },
  csmLink: {
    fontSize: '13px',
    color: theme.palette.text.rootColor,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  chartValue: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  headerFlex: {
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const InvestmentsInflowSummary = ({
  investmentsInflowSummary,
  parentClasses,
}) => {
  const labels = [
    { title: 'All time', slug: 'allTime' },
    { title: 'This week', slug: 'thisWeek' },
    { title: 'This month', slug: 'thisMonth' },
    { title: 'This year', slug: 'thisYear' },
  ];
  const [selectedDateRange, setSelectedDateRange] = useState(labels[0].slug);
  const classes = useStyles();

  const dataSource = investmentsInflowSummary[selectedDateRange] || {
    inflowByDate: [],
    investmentAmount: 0,
    investors: 0,
  };

  return (
    <CmtCard>
      <CmtCardHeader title="Lifetime investment record" subTitle="All tranches">
        <AppSelectBox
          fullWidth
          style={{ minWidth: '180px' }}
          data={labels}
          label="Date range"
          valueKey="slug"
          variant="outlined"
          labelKey="title"
          value={selectedDateRange}
          onChange={(e) => setSelectedDateRange(e.target.value)}
        />
      </CmtCardHeader>

      <Divider className={parentClasses.divider} />
      <CmtCardContent>
        <GridContainer>
          <Grid item md={9} xs={12}>
            {dataSource.inflowByDate.length > 0 && (
              <InvestmentInflowChart data={dataSource.inflowByDate} />
            )}
          </Grid>
          <Grid item md={3} xs={12}>
            <div className={classes.chartBox}>
              <p className={classes.chartValue}>
                {numberToCurrencyString(dataSource.investmentAmount, {
                  hidePenniesIfInteger: true,
                })}
              </p>
              <p className={classes.csmTxt}>Total capital received</p>
              <Link className={classes.csmLink} to={INVESTMENT}>
                View all investments
              </Link>
            </div>
            <div className={classes.chartBox} style={{ marginTop: '20px' }}>
              <p className={classes.chartValue}>
                {numberToDisplayString(dataSource.investors)}
              </p>
              <p className={classes.csmTxt}>Total Investors</p>
              <Link className={classes.csmLink} to={INVESTOR}>
                View all investors
              </Link>
            </div>
          </Grid>
        </GridContainer>
      </CmtCardContent>
    </CmtCard>
  );
};
