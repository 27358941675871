import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { FundFullDeploymentTrigger } from 'further-types/fund-fully-deployed-log';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';
import Switch from 'components/Switch';
import Table from 'components/Table';
import AlertDialog from 'components/AlertDialog';
import {
  numberToCurrencyString,
  dateToLabel,
  getCompanyLabel,
} from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { useFundFullyDeployed } from 'hooks/data/fund/useFundFullyDeployed';
import { createUploadSheetViewRoute } from 'constants/routes';

type Props = {
  fundId: string;
  hasKiCertficate: boolean;
};

const AllocationViewLink = ({ shareAllocationUploadId }) =>
  shareAllocationUploadId.deletedAt ? (
    <span>{getCompanyLabel(shareAllocationUploadId?.companyId)}</span>
  ) : (
    <NavLink
      style={{ color: '#56B26C', fontWeight: 'bold' }}
      to={createUploadSheetViewRoute(shareAllocationUploadId?._id)}
    >
      {getCompanyLabel(shareAllocationUploadId?.companyId)}
    </NavLink>
  );

const columns = [
  {
    label: 'Date',
    key: 'date',
    sort: false,
    render: (row) => dateToLabel(row.date, '-'),
  },
  {
    label: 'Action',
    key: 'action',
    sort: false,
    render: (row) => {
      if (!row.fullDeploymentTrigger) return '-';

      const action = row.isFullyDeployed ? 'Final deal' : 'Final deal undone';

      if (row.fullDeploymentTrigger === FundFullDeploymentTrigger.Allocation) {
        if (!row.shareAllocationUploadId) {
          return `${action} - upload shareholding`;
        } else {
          return (
            <>
              {action} -{' '}
              <AllocationViewLink
                shareAllocationUploadId={row.shareAllocationUploadId}
              />
            </>
          );
        }
      } else if (
        row.fullDeploymentTrigger === FundFullDeploymentTrigger.FundSettings
      ) {
        return `${action} - tranche page`;
      }
    },
  },
  {
    label: 'Movement to/from cash balances',
    key: 'cashBalanceMovement',
    sort: false,
    render: (row) =>
      numberToCurrencyString(row.cashBalanceMovement, { fallback: '-' }),
  },
];

const FinalDealToggleAndLog: FC<Props> = ({ fundId, hasKiCertficate }) => {
  const { fullyDeployedLog, fullyDeployFund, undoFundDeployment } =
    useFundFullyDeployed({ fundId });

  const fullyDeployDisclosure = useDisclosure();
  const undoDeploymentDisclosure = useDisclosure();
  const mutationInProgress =
    fullyDeployFund.isLoading || undoFundDeployment.isLoading;

  if (fullyDeployedLog.isLoading) return null;

  return (
    <GridContainer>
      <FieldRow
        title="Tranche is fully deployed"
        tooltipText={
          hasKiCertficate
            ? "The fully deployed status of this tranche cannot be changed while an EIS5 certificate for this tranche has been generated. Please navigate to the S/EIS Tasklist and delete this tranche's EIS5 certificate if you wish to use this toggle."
            : null
        }
      >
        <>
          <Switch
            label="Final Deal"
            checked={fullyDeployedLog?.data?.fund?.fullyDeployed}
            onChange={
              fullyDeployedLog.data?.fund?.fullyDeployed
                ? undoDeploymentDisclosure.onOpen
                : fullyDeployDisclosure.onOpen
            }
            disabled={mutationInProgress || hasKiCertficate}
          />
          <AlertDialog
            open={fullyDeployDisclosure.isOpen}
            onClose={fullyDeployDisclosure.onClose}
            title="Fully deploy this tranche?"
            content="Any residual cash in investments will be returned to investor cash balances."
            onConfirm={async () => {
              await fullyDeployFund.mutateAsync();
              fullyDeployDisclosure.onClose();
            }}
            confirmBtnProps={{ disabled: mutationInProgress }}
          />
          <AlertDialog
            open={undoDeploymentDisclosure.isOpen}
            onClose={undoDeploymentDisclosure.onClose}
            title="Undo the full deployment of this tranche?"
            content="Upon undoing this tranches full deployment status, any investor residual cash, which has not since been withdrawn, will return to the tranche’s deployable capital."
            onConfirm={async () => {
              await undoFundDeployment.mutateAsync();
              undoDeploymentDisclosure.onClose();
            }}
            confirmBtnProps={{ disabled: mutationInProgress }}
          />
        </>
      </FieldRow>
      <FieldRow title="Final deal log">
        <Table
          columns={columns}
          tablebody={
            fullyDeployedLog?.data?.logs?.length
              ? fullyDeployedLog?.data?.logs
              : [{}]
          }
          loading={false}
          page={0}
          rowsPerPage={10}
          count={fullyDeployedLog?.data?.logs?.length ?? 0}
          pagination={false}
          selectable={false}
        />
      </FieldRow>
    </GridContainer>
  );
};

export default FinalDealToggleAndLog;
