import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import CmtAvatar from 'components/CmtAvatar';

import { getUserList, deleteUser } from '@redux/actions/User';
import { USER_ADD, createEditUserRoute } from 'constants/routes';
import { Link } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import TextField from 'components/FormElements/AppTextInput';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { CustomTheme } from 'theme/themeColors';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  addUserBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
  marginFirstName: {
    margin: '10px 0px 0px 10px',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Users', isActive: true },
];

const User = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  //@ts-ignore
  const { userData, loading } = useSelector(({ UserReducer }) => UserReducer);

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [record, setRecord] = React.useState();

  const [createPermission, updatePermission, deletePermission] =
    useGetPermissions(['create:user', 'edit:user', 'delete:user']);

  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    toFirstPage,
  } = usePagination({ id: 'users-table' });

  const { filters, handleChangeFilters } = useFilters('users-table', {
    onFiltersChange: toFirstPage,
    defaultFilters: {
      search: null,
    },
  });

  const loadUsers = () => {
    handleApiRequest(async () => {
      await dispatch(
        getUserList({
          page: page === 0 ? 1 : page,
          perPage: rowsPerPage,
          type: order,
          keyName: orderBy,
          search: filters.search,
        }),
      );
    });
  };

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = async () => {
    await handleApiRequest(async (success) => {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteUser(record._id),
      );
      if (response.status === 200) {
        success(response.data.responseMsg);
        loadUsers();
      }
    });
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    loadUsers();
  }, [page, dispatch, rowsPerPage, order, orderBy, filters]);

  const columns = [
    {
      label: 'First name',
      key: 'firstName',
      render: (elm) => (
        <div className={classes.dFlex}>
          {
            //@ts-ignore
            <CmtAvatar size={40} src={elm?.profilePic} />
          }
          <div className={classes.marginFirstName}>{elm?.firstName}</div>
        </div>
      ),
    },
    { label: 'Last name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Role type',
      key: 'roleId',
      render: (elm) => <>{elm?.roleId?.name}</>,
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <div className={classes.dFlex}>
          {updatePermission && (
            <Link to={createEditUserRoute(elm._id)}>
              <Button
                title="Edit"
                color="primary"
                className={classes.customButton}
              >
                <Edit />
              </Button>
            </Link>
          )}
          {deletePermission && (
            <Button
              title="Delete"
              className={classes.customButton}
              onClick={() => handleDelete(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
          {!updatePermission && !deletePermission ? (
            <Typography component="h4" variant="inherit">
              Permission Denied
            </Typography>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];

  return (
    <PageContainer heading="Users" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="search"
                    name="search"
                    placeholder="Search by name or email"
                    fullWidth
                    value={filters.search}
                    onChange={(event) => {
                      handleChangeFilters({ search: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {createPermission && (
                    <div className={classes.addUserBtn}>
                      <Link to={USER_ADD}>
                        <Button color="primary" variant="contained">
                          Add User
                        </Button>
                      </Link>
                    </div>
                  )}
                </Grid>
              </GridContainer>

              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  tablebody={userData?.result}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={userData?.total}
                  pagination={true}
                  loading={loading}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={openConfirmDialog}
          onClose={handleDelete}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this user?`}
          content={
            <p>
              This cannot be undone and all information relating to the user
              type will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete user',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default User;
