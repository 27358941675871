import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Edit, Delete, Visibility, Email } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { Tooltip } from 'components/Tooltip';
import { useDisclosure } from 'further-ui/hooks';
import { numberToCurrencyString } from 'further-ui/utils';
import useFilters from 'hooks/ui/useFilters';

import { deleteAdviser } from '@redux/actions/Adviser';
import {
  createAdviserViewRoute,
  createAdviserEditRoute,
  ADVISER_ADD,
} from 'constants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useAdvisers, useAdvisersExport } from 'hooks/data/adviser/useAdvisers';
import { useOrganisations } from 'hooks/data/organisation/useOrganisations';
import usePlatformInvites from 'hooks/data/adviser/useAdviserPlatformInvites';
import { numberToDisplayString } from 'utils/numbers';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { canDeleteAdviser } from 'utils/adviserPermissions';
import { CustomTheme } from 'theme/themeColors';
import downloadFile from 'utils/downloadFile';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  tableMt: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 5,
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Advisers', isActive: true },
];

const Adviser = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { firmId, isSuperAdmin } = useGetRole();
  const [createPermission, deletePermission] = useGetPermissions([
    'create:advisers',
    'delete:advisers',
  ]);

  const { success, error } = useNotification();

  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteDisclosure = useDisclosure();
  const handleApiRequest = useApiRequestHandler();

  const pagination = usePagination({
    orderBy: 'fullName',
    order: 'asc',
    id: 'advisers-list',
  });
  const { filters, handleChangeFilters } = useFilters('advisers-list', {
    onFiltersChange: pagination.toFirstPage,
  });

  const [search, setSearch] = useState(filters?.search || '');
  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    handleChangeFilters({ search: debouncedSearch });
  }, [debouncedSearch]);

  const params = {
    search: filters.search,
    organisationId: filters.organisationId?._id,
    type: pagination.order,
    keyName: pagination.orderBy,
    firmId,
  };

  const advisers = useAdvisers({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      ...params,
    },
  });
  const { exportAdviser, isExportLoading } = useAdvisersExport({
    params: { ...params },
  });

  const organisations = useOrganisations({
    firmId,
  });

  const { resendInvite } = usePlatformInvites({
    onResendInviteSuccess: () => {
      advisers.refetch();
      success('Adviser invitation sent successfully.');
    },
  });

  const handleExport = async () => {
    try {
      const data = await exportAdviser();
      if (data) {
        downloadFile(data, `Adviser-${Date.now()}.xlsx`);
      }
    } catch (exportError) {
      if (exportError) {
        error(exportError.message);
        return;
      }
    }
  };

  const columns = [
    { label: 'Name', key: 'fullName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Organisation',
      key: 'organisationName',
      sort: false,
      render: (elm) => elm?.organisationId?.name,
    },
    {
      label: 'Clients',
      key: 'investorsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm.investorsCount),
    },
    {
      label: 'Investments',
      key: 'investmentsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm.investmentsCount),
    },
    {
      label: 'Total initial value',
      key: 'initialValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.initialValue),
    },
    {
      label: 'Total current value',
      key: 'currentValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.currentValue),
    },
    {
      label: 'Realised value',
      key: 'realisedValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.realisedValue),
    },
    {
      label: 'Advice fees',
      key: 'adviceFees',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.adviceFees),
    },
    {
      label: 'Actions',
      sort: false,
      key: 'actions',
      render: (elm) => (
        <div className={classes.dFlex}>
          {isSuperAdmin ? (
            <Tooltip title="Resending adviser invites is only possible for firm admin users.">
              <span>
                <Button disabled>
                  <Email />
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Resend invitation to this adviser.">
              <Button
                title="This resends the adviser invite and enables future emails to this investor."
                className={classes.customButton}
                disabled={resendInvite.isLoading}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  resendInvite.mutate(elm._id);
                }}
              >
                <Email />
              </Button>
            </Tooltip>
          )}
          <Link to={createAdviserViewRoute(elm?._id)}>
            <Button
              title="View adviser"
              color="primary"
              className={classes.customButton}
            >
              <Visibility />
            </Button>
          </Link>
          <Link to={createAdviserEditRoute(elm?._id)}>
            <Button
              title="Edit"
              color="primary"
              className={classes.customButton}
            >
              <Edit />
            </Button>
          </Link>
          {canDeleteAdviser(isSuperAdmin, elm, firmId, deletePermission) ? (
            <IconButton
              title="Delete"
              onClick={() => deleteDisclosure.stageAction({ id: elm._id })}
            >
              <Delete htmlColor="#E8453C" />
            </IconButton>
          ) : (
            <Tooltip title="This adviser is linked with one or more investments or more than one firm, so cannot be deleted.">
              <span>
                <IconButton className={`${classes.customButton}`} disabled>
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const onDeleteAdviser = async () => {
    const adviserId = deleteDisclosure.actionProps.id;
    setIsDeleting(true);
    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch(deleteAdviser(adviserId));
      if (response) {
        showSuccess('Adviser deleted');
        deleteDisclosure.onClose();
        advisers.refetch();
      } else {
        setDisableDeleteBtn(true);
      }
    });
    setIsDeleting(false);
  };

  return (
    <PageContainer heading="Advisers" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={5}>
                  <TextField
                    type="search"
                    name="search"
                    placeholder="Search by email, name, FCA number"
                    fullWidth
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    //@ts-expect-error
                    options={organisations?.data?.organisation}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={filters.organisationId}
                    onChange={(_event, newValue) => {
                      handleChangeFilters({ organisationId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select organisation"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.actionButtonContainer}>
                    {createPermission && (
                      <Link to={ADVISER_ADD}>
                        <Button color="primary" variant="contained">
                          Add Adviser
                        </Button>
                      </Link>
                    )}
                    <Button
                      color="primary"
                      disabled={isExportLoading}
                      variant="contained"
                      className={classes.marginLeft}
                      onClick={handleExport}
                    >
                      {isExportLoading ? (
                        <CircularProgress
                          className={classes.exportSpinner}
                          size={18}
                        />
                      ) : null}
                      Export
                    </Button>
                  </div>
                </Grid>
              </GridContainer>

              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  onRequestSort={pagination.handleRequestSort}
                  order={pagination.order}
                  orderBy={pagination.orderBy}
                  tablebody={advisers.data?.adviser ?? []}
                  onPageChange={pagination.handleChangePage}
                  onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  count={advisers.data?.totalAdviser ?? 0}
                  pagination={true}
                  loading={advisers.isFetching}
                  emptyMessage="No advisers found"
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Are you sure you wish to delete this adviser?"
        content="This cannot be undone and all information relating to the adviser will be lost."
        btnLabels={{ confirm: 'Delete adviser', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: isDeleting || disableDeleteBtn,
        }}
        onConfirm={onDeleteAdviser}
      />
    </PageContainer>
  );
};
export default Adviser;
