import { FC, useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { AppSelectBox } from 'components/FormElements';
import FormLabel from 'components/FormElements/FormLabel';
import InvestorSearch from 'components/InvestorSearch';
import { FirmSelector } from 'components/FirmSelector';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDelegatedAccess } from 'hooks/data/delegatedAccess/useDelegatedAccess';
import { useNotification } from 'hooks/ui/useNotification';
import { NavLink } from 'react-router-dom';
import { INVESTOR_ADD } from 'constants/routes';
import { CustomTheme } from 'theme/themeColors';

type Props = {
  investorId: string;
  headerClassName: string;
  closePanel: () => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  investorAdd: {
    marginBottom: '2rem',
    marginTop: '1rem',
    color: '#656565',
  },
  investorAddLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const AddNewDelegatedAccessUser: FC<Props> = ({
  investorId,
  headerClassName,
  closePanel,
}) => {
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const [firmId, setFirmId] = useState<string | null>(usersFirmId);
  const [receivingAccessInvestorId, setReceivingAccessInvestorId] = useState<
    string | null
  >(null);
  const [copyIntoSystemEmails, setCopyIntoSystemEmails] = useState(false);
  const notification = useNotification();
  const classes = useStyles();

  const clearForm = () => {
    setReceivingAccessInvestorId(null);
    setFirmId(usersFirmId);
    setCopyIntoSystemEmails(false);
  };

  const { create } = useDelegatedAccess({
    onCreate: () => {
      clearForm();
      closePanel();
    },
  });

  const submit = () => {
    if (!receivingAccessInvestorId) {
      if (isSuperAdmin && !firmId) {
        notification.error('Please select an investor and a firm');
        return;
      } else {
        notification.error('Please select an investor');
        return;
      }
    }

    create.mutate({
      receivingAccessInvestorId,
      givingAccessInvestorId: investorId,
      firmId,
      copyIntoSystemEmails,
    });
  };

  return (
    <Box mt={8} mb={8} maxWidth="60rem">
      <Typography variant="h2" className={headerClassName}>
        Add a new delegated access user
      </Typography>
      <Typography className={classes.investorAdd}>
        Select an existing investor to provide delegated account access to.{' '}
        <NavLink
          target="_blank"
          className={classes.investorAddLink}
          to={INVESTOR_ADD}
        >
          Click here to create a new investor
        </NavLink>
      </Typography>
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        gridGap="2rem"
        alignItems="flex-end"
      >
        <div style={{ flex: 2 }}>
          <FormLabel title="Select investor" />
          <InvestorSearch
            onInvestorSelect={setReceivingAccessInvestorId}
            selectedInvestorId={receivingAccessInvestorId}
          />
        </div>
        {isSuperAdmin && (
          <div style={{ flex: 1 }}>
            <FormLabel title="Select firm" />
            <FirmSelector
              firmId={firmId}
              onChange={setFirmId}
              placeholder="Select firm"
              allowDeselection={false}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <FormLabel title="Copy user into emails?" />
          <AppSelectBox
            data={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            labelKey="label"
            valueKey="value"
            value={copyIntoSystemEmails}
            variant="outlined"
            onChange={(event: any) =>
              setCopyIntoSystemEmails(event.target.value)
            }
          />
        </div>
        <Button color="primary" variant="contained" onClick={submit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AddNewDelegatedAccessUser;
