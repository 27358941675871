import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  errorMessageIcon: {
    width: '80px',
    height: '80px',
    borderRadius: '100%',
    border: `${theme.palette.text.error} solid 4px`,
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(4, 0),
  },
}));

export default useStyles;
