import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogIcon: {
    color: theme.palette.text.rootColor,
    fontSize: 65,
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block',

    '&.MuiSvgIcon-root': {
      fontSize: 65,
      display: 'block',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContentText: {
    color: '#7B7B7B',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: '25px',
  },
  moveFundsDialogContentText: {
    color: '#7B7B7B',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    paddingTop: '10px',
  },
  freezeDate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&>label': {
      flex: 'none',
      marginRight: '25px',
    },
  },
  freezeLabel: {
    width: 'calc(100% + 48px)',
    top: 0,
    left: 0,
    color: theme.palette.text.white,
    backgroundColor: theme.palette.background.danger,
    padding: '6px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '25px',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-24px',
    marginRight: '-24px',
    marginTop: '-24px',
  },
}));

export default useStyles;
