import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Api } from 'further-types/adviser';
import { Api as OrganisationApi } from 'further-types/organisation';
import { useOrganisationLinkedSubscriptions } from 'hooks/data/organisation/useOrganisationLinkedSubscriptions';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { usePagination } from 'hooks/ui/usePagination';
import { createInvestmentEditRoute } from 'constants/routes';
import SearchField from 'components/SearchField';

const useStyles = makeStyles(() => ({
  title: {
    color: '#656565',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2.5rem',
    marginBottom: '1.5rem',
  },
}));

type Props = {
  organisation: OrganisationApi.Organisation;
};

const LinkedInvestments: FC<Props> = ({ organisation }) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const pagination = usePagination({
    id: `organisation-linked-subscriptions-${organisation._id}}`,
  });

  const { data, isFetching } = useOrganisationLinkedSubscriptions({
    organisationId: organisation._id,
    params: {
      search,
      page: pagination.page,
      pageSize: pagination.rowsPerPage,
      orderBy: pagination.orderBy,
      order: pagination.order,
    },
  });

  const columns = [
    {
      label: 'Investor',
      key: 'fullName',
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm: Api.AdviserSubscription) => dateToLabel(elm.investmentDate),
    },
    {
      label: 'Adviser name',
      key: 'adviserName',
      render: (elm: Api.AdviserSubscription) => `${elm?.adviserId?.fullName}`,
      sort: false,
    },
    {
      label: 'Advice fee',
      key: 'adviserFee',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(elm.adviserFee ?? 0),
    },
    {
      label: 'Initial investment',
      key: 'investmentAmount',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(elm.investmentAmount),
    },
    {
      label: 'Unrealised return',
      key: 'investmentBalancePropertyCache.unrealisedValue.total',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.unrealisedValue.total,
        ),
    },
    {
      label: 'Realised return',
      key: 'investmentBalancePropertyCache.realisedValue.total',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.realisedValue.total,
        ),
    },
    {
      label: 'Total current value',
      key: 'investmentBalancePropertyCache.investmentValue',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.investmentValue,
        ),
    },
    {
      label: 'Actions',
      sort: false,
      render: (elm: Api.AdviserSubscription) => (
        <Link to={createInvestmentEditRoute(elm?._id)}>
          <IconButton title="Edit investment" color="primary">
            <Edit />
          </IconButton>
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className={classes.flex}>
        <h2 className={classes.title}>Linked investments</h2>
        <SearchField
          onSearch={(value) => {
            setSearch(value);
            pagination.toFirstPage();
          }}
          autoSearchOnDebounce
          allowEmptyQuery
          variant="outlined"
          name="investorName"
          id="investorName"
          placeholder="Search by investor name"
          width={'20rem'}
          fullWidth
        />
      </div>

      <Table
        columns={columns}
        tablebody={data?.subscriptions ?? []}
        loading={isFetching}
        onRequestSort={pagination.handleRequestSort}
        order={pagination.order}
        orderBy={pagination.orderBy}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        count={data?.totals.investments ?? 0}
        pagination={true}
        rowsPerPage={pagination.rowsPerPage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        emptyMessage="No investments found"
      />
    </>
  );
};

export default LinkedInvestments;
