import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  fullWidth: {
    maxWidth: '100%',
  },
  container: {
    padding: theme.spacing(8, 10),
  },
  tableHeader: {
    padding: 12,
    color: theme.palette.text.tableText,
    backgroundColor: theme.palette.background.header,
  },
  summaryCashBalanceRow: {
    color: theme.palette.text.secondary,
    '& td': {
      padding: '18px 6px 15px',
    },
  },
  tableFooter: {
    color: theme.palette.text.tableText,
    backgroundColor: theme.palette.background.header,
    '& td': {
      padding: '18px 6px 15px',
    },
  },
  investorSearchRow: {
    gap: theme.spacing(4),
  },
  investorSearch: {
    gap: theme.spacing(4),
  },
}));

export default useStyles;
