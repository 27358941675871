import React from 'react';
import { dateToLabel } from 'further-ui/utils';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';

const Summary = ({ data, summaryData }) => {
  return (
    <GridContainer>
      <FieldRow title="Allocation">{summaryData.allocationName}</FieldRow>
      <FieldRow title="Company trading name">
        {summaryData.companyName}
      </FieldRow>
      <FieldRow title="Company legal name">{summaryData.legalName}</FieldRow>
      <FieldRow title="Investment type">{`${data?.investmentType} investment`}</FieldRow>
      <FieldRow title="Unique investment reference">{data.uir}</FieldRow>
      <FieldRow title="Description of shares">{data.descriptionShare}</FieldRow>
      <FieldRow title="Knowledge-intensive company">
        {data.knowledgeIntensive ? 'Yes' : 'No'}
      </FieldRow>
      <FieldRow title="Nominal value of each share">
        {data.nominalValueOfEachShare}
      </FieldRow>
      <FieldRow title="Date of share issue">
        {dateToLabel(data.dateOfShareIssue)}
      </FieldRow>
      <FieldRow title="Termination date of these shares">
        {dateToLabel(data.terminationDateOfShare)}
      </FieldRow>
      <FieldRow title="Authorisation date / EIS2 date (KI funds only)">
        {dateToLabel(data.hmrcAuthorisationDate)}
      </FieldRow>
      <FieldRow title="Address 1">{data.address1}</FieldRow>
      <FieldRow title="Address 2">{data.address2}</FieldRow>
      <FieldRow title="City">{data.city}</FieldRow>
      <FieldRow title="Postcode">{data.postcode}</FieldRow>
      <FieldRow title="Country">{data.country}</FieldRow>
      <FieldRow title="Name of company representative">
        {data.nameOfCompanyRepresentative}
      </FieldRow>
      <FieldRow title="Capacity in which signed">
        {data.capicityInWhichSigned}
      </FieldRow>
      <FieldRow title="Signature date">
        {dateToLabel(data.signatureDate)}
      </FieldRow>
    </GridContainer>
  );
};
export default Summary;
