import { Link } from 'react-router-dom';
import { makeStyles, Button, IconButton } from '@material-ui/core';
import { Edit, Visibility, Delete } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import {
  createOrganisationEditRoute,
  createOrganisationViewRoute,
} from 'constants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',

    color: theme.palette.text.green,
  },
  dFlex: {
    display: 'flex',
  },
}));

type TableActionsProps = {
  row: any;
  deleteDisclosure: {
    stageAction: (props: { id: string }) => void;
  };
};

const TableActions: React.FC<TableActionsProps> = ({
  row,
  deleteDisclosure,
}) => {
  const classes = useStyles();
  const [editPermission, deletePermission] = useGetPermissions([
    'edit:organisations',
    'delete:organisations',
  ]);

  return (
    <div className={classes.dFlex}>
      <Link to={createOrganisationViewRoute(row?._id)}>
        <Button
          title="View organisation"
          color="primary"
          className={classes.customButton}
        >
          <Visibility />
        </Button>
      </Link>
      {editPermission && (
        <Link to={createOrganisationEditRoute(row?._id)}>
          <Button title="Edit" color="primary" className={classes.customButton}>
            <Edit />
          </Button>
        </Link>
      )}

      {deletePermission &&
        (row?.deleteForbidden ? (
          <Tooltip title="This organisation is linked with one or more advisers or organisations, so it cannot be deleted.">
            <span>
              <span>
                <IconButton className={`${classes.customButton}`} disabled>
                  <Delete />
                </IconButton>
              </span>
            </span>
          </Tooltip>
        ) : (
          <IconButton
            title="Delete"
            onClick={() => deleteDisclosure.stageAction({ id: row._id })}
          >
            <Delete htmlColor="#E8453C" />
          </IconButton>
        ))}
    </div>
  );
};

export default TableActions;
