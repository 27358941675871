import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function TranchePromotionInformation({
  handleCancel,
  handleClickTranchePromotion,
  id,
  onChangeTranchePromotion,
  setTranchePromotion,
  tranchePromotion,
  disabled = false,
  isSyndicate = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer item md={11}>
      <FieldRow
        title="Offering document"
        tooltipText="Please upload your core fund marketing document. This may be a prospectus, information memorandum or key fundraising presentation."
      >
        <DropzoneComponent
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
          }}
          onDropFile={(files) =>
            onChangeTranchePromotion(files, 'prospectusFile')
          }
          files={
            tranchePromotion?.prospectusFile
              ? [
                  {
                    preview: tranchePromotion?.prospectusFilePreview,
                    fileName: tranchePromotion?.prospectusFile,
                  },
                ]
              : []
          }
          onRemove={() =>
            setTranchePromotion({
              ...tranchePromotion,
              prospectusFile: null,
              prospectusFilePreview: null,
            })
          }
        />
      </FieldRow>
      <FieldRow
        title="Key information document"
        tooltipText="Please upload your Key Information Document (KID) if you have been required to produce one."
      >
        <DropzoneComponent
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
          }}
          onDropFile={(files) => onChangeTranchePromotion(files, 'keyInfoFile')}
          files={
            tranchePromotion?.keyInfoFile
              ? [
                  {
                    preview: tranchePromotion?.keyInfoFilePreview,
                    fileName: tranchePromotion?.keyInfoFile,
                  },
                ]
              : []
          }
          onRemove={() =>
            setTranchePromotion({
              ...tranchePromotion,
              keyInfoFile: null,
              keyInfoFilePreview: null,
            })
          }
        />
      </FieldRow>
      <FieldRow
        title="Legal agreement"
        tooltipText="Please upload your investment management agreement, limited partnership agreement or, if you have a prospectus, your application form."
      >
        <DropzoneComponent
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
          }}
          onDropFile={(files) =>
            onChangeTranchePromotion(files, 'applicationFormFile')
          }
          files={
            tranchePromotion?.applicationFormFile
              ? [
                  {
                    preview: tranchePromotion?.applicationFormFilePreview,
                    fileName: tranchePromotion?.applicationFormFile,
                  },
                ]
              : []
          }
          onRemove={() =>
            setTranchePromotion({
              ...tranchePromotion,
              applicationFormFile: null,
              applicationFormFilePreview: null,
            })
          }
        />
      </FieldRow>

      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.marginLeftBtn}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={
              (!isSyndicate && !tranchePromotion?.applicationFormFile) ||
              disabled
            }
            onClick={handleClickTranchePromotion}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
