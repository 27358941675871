import React from 'react';
import { Divider } from '@material-ui/core';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import HoverPointer from 'components/HoverPointer';
import { createInvestmentEditRoute } from 'constants/routes';
import { Link } from 'react-router-dom';

export const RecentInvestmentsTable = ({ rows, trancheId, classes }) => {
  const columns = [
    { sort: false, label: 'Investor', key: 'investorName' },
    {
      sort: false,
      label: 'Investment date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      sort: false,
      label: 'Investment amount',
      key: 'investmentAmount',
      render: (elm) => (
        <HoverPointer
          to={createInvestmentEditRoute(elm.investmentId)}
          hoverText="View details"
        >
          {numberToCurrencyString(elm?.investmentAmount, {
            hidePenniesIfInteger: true,
          })}
        </HoverPointer>
      ),
    },
  ];

  return (
    <CmtCard>
      <CmtCardHeader title="Recent investments">
        <Link
          className={classes.viewAll}
          to={`/investment?fundId=${trancheId}`}
        >
          View all
        </Link>
      </CmtCardHeader>
      <Divider className={classes.divider} />
      <CmtCardContent>
        <Table
          columns={columns}
          tablebody={rows}
          pagination={false}
          emptyMessage="No investments yet"
          variant="nohover"
        />
      </CmtCardContent>
    </CmtCard>
  );
};
