import { FC, useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import PaymentsReconciliationTable from '../Investment/EditInvestment/PaymentsReconciliationTable';
import { PaymentExpectationApi } from 'further-types/payment';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import { AppTextInput } from 'components/FormElements';
import { useInvestmentUpdateAPI } from 'hooks/data/investment/useInvestments';
import { usePayments } from 'hooks/data/investment/usePayments';
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';

type Props = {
  payment: PaymentExpectationApi.OutstandingPayment;
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '1rem',
    padding: '1.5rem',
    borderRadius: '0.25rem',
  },
  gridContainer: {
    marginBottom: '1rem',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontWeight: 400,
    fontSize: '14px',
    marginBottom: '1rem',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  value: {
    fontWeight: 600,
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tooltipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    marginLeft: '0.5rem',
  },
  investmentIdButton: {
    marginLeft: '0.25rem',
  },
  paymentsLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const PaymentExpectationRow: FC<Props> = ({ payment }) => {
  const classes = useStyles();
  const [initialExternalInvestmentId, setInitialExternalInvestmentId] =
    useState(payment.investment?.externalInvestmentId);
  const [externalInvestmentId, setExternalInvestmentId] = useState(
    payment.investment?.externalInvestmentId,
  );

  const update = useInvestmentUpdateAPI(payment.investment?._id);
  const payments = usePayments(payment.investment?._id, payment._id);

  const onInvestmentIdSave = () => {
    update.mutate({ externalInvestmentId });
  };

  useEffect(() => {
    if (update.isSuccess) setInitialExternalInvestmentId(externalInvestmentId);
  }, [update.isSuccess]);

  return (
    <div className={classes.container}>
      <GridContainer className={classes.gridContainer}>
        <Grid item xs={2} className={classes.gridItem}>
          <Typography className={classes.label}>Name</Typography>
          <div className={classes.valueContainer}>
            <Typography className={classes.value}>
              {payment.investor?.fullName}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography className={classes.label}>Email</Typography>
          <div className={classes.valueContainer}>
            <Typography className={classes.value}>
              {payment.investor?.email}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <Typography className={classes.label}>Amount</Typography>
          <div className={classes.valueContainer}>
            <Typography className={classes.value}>
              {numberToCurrencyString(payment.amount)}
              <Tooltip
                leaveDelay={250}
                interactive
                arrow
                title={`This figure is made up of ${numberToCurrencyString(
                  payment.investment?.investmentAmount,
                )} total commitment and ${numberToCurrencyString(
                  payment.investment?.adviserFee ?? 0,
                )} adviser fee.`}
                placement="top"
              >
                <InfoOutlined className={classes.tooltipIcon} />
              </Tooltip>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <Typography className={classes.label}>Investment date</Typography>
          <div className={classes.valueContainer}>
            <Typography className={classes.value}>
              {dateToLabel(payment.investment?.investmentDate)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography className={classes.label}>Investment ID</Typography>
          <div className={classes.valueContainer}>
            <AppTextInput
              value={externalInvestmentId}
              onChange={(e) => {
                setExternalInvestmentId(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onInvestmentIdSave}
              className={classes.investmentIdButton}
              disabled={initialExternalInvestmentId === externalInvestmentId}
            >
              Save
            </Button>
          </div>
        </Grid>
      </GridContainer>

      {!payments.isLoading ? (
        <PaymentsReconciliationTable
          key={payment._id}
          investmentId={payment.investment?._id}
          firmId={payment.investment?.firmId}
          payments={payments.data ?? []}
        />
      ) : (
        <div className={classes.paymentsLoading}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};

export default PaymentExpectationRow;
