import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',

      '& .MuiDialogContent-root': {
        paddingTop: 0,
      },
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  switch: {
    flexGrow: 1,
  },
  switchInner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(4, 6),
  },
}));

export default useStyles;
