import Table from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';
import { useAdviserSubscriptions } from 'hooks/data/adviser/useAdviserSubscriptions';
import { Api } from 'further-types/adviser';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { createInvestmentEditRoute } from 'constants/routes';
import { Link } from 'react-router-dom';
import useFilters from 'hooks/ui/useFilters';
import SearchField from 'components/SearchField';
import { EIS3Download } from 'components/SVGIcons';
import {
  useDownloadByAdviserId,
  useDownloadByInvestmentId,
} from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';

type Props = {
  adviserId: string;
  adviserName?: string;
};

type Filters = {
  search: string;
};

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  title: {
    margin: 0,
  },
  allEisButton: {
    marginLeft: 'auto',
    marginRight: '0.5rem',
  },
  searchBox: {
    width: '40%',
  },
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  dFlex: {
    display: 'flex',
  },
}));

const AdviserInvestments = ({ adviserId, adviserName }: Props) => {
  const classes = useStyles();
  const downloadByInvestmentId = useDownloadByInvestmentId();
  const downloadByAdviserId = useDownloadByAdviserId();

  const handleInvestmentCertificatesDownload = (
    investment: Api.AdviserSubscription,
  ) => {
    downloadByInvestmentId.download({
      investmentId: investment._id,
      fullName: investment.investorId.fullName,
      trancheName: `${investment.firmId.firmName}-${investment.fundId.fundName}`,
    });
  };

  const columns = [
    {
      label: 'Investor',
      key: 'fullName',
    },
    {
      label: 'Tranche',
      key: 'fundName',
      sort: false,
      render: (elm: Api.AdviserSubscription) => elm.fundId?.fundName,
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm: Api.AdviserSubscription) => dateToLabel(elm.investmentDate),
    },
    {
      label: 'Advice fee',
      key: 'adviserFee',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(elm.adviserFee),
    },
    {
      label: 'Initial investment',
      key: 'investmentAmount',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(elm.investmentAmount),
    },
    {
      label: 'Unrealised return',
      key: 'investmentBalancePropertyCache.unrealisedValue.total',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.unrealisedValue.total,
        ),
    },
    {
      label: 'Realised return',
      key: 'investmentBalancePropertyCache.realisedValue.total',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.realisedValue.total,
        ),
    },
    {
      label: 'Total current value',
      key: 'investmentBalancePropertyCache.investmentValue',
      render: (elm: Api.AdviserSubscription) =>
        numberToCurrencyString(
          elm.investmentBalancePropertyCache?.investmentValue,
        ),
    },
    {
      label: 'Actions',
      key: 'actions',
      render: (elm: Api.AdviserSubscription) => (
        <div className={classes.dFlex}>
          <Link to={createInvestmentEditRoute(elm._id)}>
            <IconButton color="primary">
              <Edit />
            </IconButton>
          </Link>
          <Button
            title="Download all EIS certificates for this investment"
            className={classes.customButton}
            onClick={() => handleInvestmentCertificatesDownload(elm)}
          >
            <EIS3Download />
          </Button>
        </div>
      ),
    },
  ];

  const pagination = usePagination({
    id: `adviserInvestments-${adviserId}`,
    order: 'desc',
    orderBy: 'investmentDate',
  });
  const { filters, handleChangeFilters } = useFilters<Filters>(
    `adviserInvestments-${adviserId}`,
    {
      onFiltersChange: () => pagination.toFirstPage(),
    },
  );
  const { data, isFetching } = useAdviserSubscriptions({
    adviserId,
    params: {
      page: pagination.page,
      pageSize: pagination.rowsPerPage,
      search: filters.search,
      order: pagination.order,
      orderBy: pagination.orderBy,
    },
  });

  return (
    <>
      <div className={classes.flex}>
        <h2 className={classes.title}>Linked investments</h2>
        {data?.subscriptions.length ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.allEisButton}
            onClick={() =>
              downloadByAdviserId.download({ adviserId, adviserName })
            }
            disabled={downloadByAdviserId.isLoading}
          >
            {downloadByAdviserId.isLoading ? (
              <CircularProgress
                style={{ color: 'rgba(0, 0, 0, 0.26)', marginRight: 10 }}
                size={18}
              />
            ) : null}
            Download all EIS certificates
          </Button>
        ) : null}

        <SearchField
          onSearch={(search) => handleChangeFilters({ search })}
          variant="outlined"
          name="investorName"
          id="investorName"
          placeholder="Search by investor name"
          allowEmptyQuery
          autoSearchOnDebounce
          width={'20rem'}
          fullWidth
        />
      </div>
      <Table
        columns={columns}
        tablebody={data?.subscriptions ?? []}
        count={data?.totals?.investments ?? 0}
        pagination
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        loading={isFetching}
        emptyMessage={"This adviser doesn't have any linked investments yet."}
        onRequestSort={pagination.handleRequestSort}
        order={pagination.order}
        orderBy={pagination.orderBy}
      />
    </>
  );
};

export default AdviserInvestments;
