import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
  Box,
  Button,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import FieldRow from 'components/FormElements/FieldRow';
import { createInvestorViewRoute } from 'constants/routes';
import AppTextInput from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import AdviserSelect from './AdviserSelect';
import InvestmentsFreeze from './InvestmentFreeze';
import { CompareArrows } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { sumBy } from 'lodash';
import TransferLabel from './TransferLabel';
import FundsReceivedStatusLabel from './FundsReceivedStatusLabel';
import { useDownloadByInvestmentId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { Api } from 'further-types/investment';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  endField: {
    display: 'flex',
    height: 32,
  },
  divider: {
    margin: '20px 0',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

export type EditableInvestmentDetails = {
  totalCommitment?: number;
  adviser?: {
    _id: string;
  };
  adviserFee?: number;
  externalInvestmentId?: string;
};

interface Props {
  shareholdings: Array<any>;
  investmentDetails: Api.GetInvestmentDetailsResponse;
  paymentExpectations: Array<Api.InvestmentPaymentExpectation>;
  editableDetails: EditableInvestmentDetails;
  setEditableDetails: (details: EditableInvestmentDetails) => void;
  fees: Array<any>;
}

const InvestmentDetails: FC<Props> = ({
  shareholdings,
  investmentDetails,
  paymentExpectations,
  editableDetails,
  setEditableDetails,
  fees,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const freezeDisclosure = useDisclosure();
  const downloadEisCerts = useDownloadByInvestmentId();

  const handleDownload = async () =>
    downloadEisCerts.download({
      investmentId: id,
      fullName,
      trancheName: `${firmName}-${fundName}`,
    });

  const {
    investmentDate,
    totalCommitment,
    investmentAmount,
    adviser,
    advisedClientCertifiedAt,
    commissionFee,
    isAllocationExists,
    hasFrozenFundsMoved,
    transferredInvestments = [],
    investmentBalance,
    sourceInvestmentId,
  } = investmentDetails;
  const investorId = investmentDetails.investor?._id;
  const fullName = investmentDetails.investor?.fullName;
  const firmName = investmentDetails.firm?.firmName;
  const fundName = investmentDetails.fund?.fundName;
  const frozenOn = investmentDetails.freezeStartDate;
  const availableFunds =
    investmentBalance?.deploymentSummary?.heldByFundAwaitingInvestment ?? 0;
  const fundFullyDeployed = investmentDetails.fund?.fullyDeployed;

  const isFrozen = frozenOn && new Date() >= new Date(frozenOn);

  const recentShareholdingDate =
    (shareholdings ?? [])
      .map((shareholding) => new Date(shareholding.date))
      .sort((date1, date2) => Number(date2) - Number(date1))[0] ?? null;

  const totalTransferredAmount = sumBy(
    transferredInvestments,
    'investmentAmount',
  );
  const transferTooltipText = `This investment was originally ${numberToCurrencyString(
    totalTransferredAmount + (investmentAmount ?? 0),
  )}. ${numberToCurrencyString(
    totalTransferredAmount,
  )} has been transferred as set out on this page.`;

  return (
    <>
      <InvestmentsFreeze
        investmentId={id}
        frozenOn={frozenOn}
        freezeDisclosure={freezeDisclosure}
        fees={fees}
        hasFrozenFundsMoved={!!hasFrozenFundsMoved}
        availableFunds={availableFunds}
        recentShareholdingDate={recentShareholdingDate}
      />
      {transferredInvestments.map((transferredInvestment) => (
        <TransferLabel
          key={transferredInvestment._id}
          investment={transferredInvestment}
        />
      ))}
      <div className={classes.headerContainer}>
        <Typography variant="h2" className={classes.heading}>
          Investor details
        </Typography>
        <div className="flex">
          {!isFrozen ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={freezeDisclosure.onOpen}
            >
              Freeze investment
            </Button>
          ) : null}
          {!!isAllocationExists && (
            <Button
              className="mr-2"
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleDownload}
            >
              Download all EIS certificates
            </Button>
          )}

          <Link to={createInvestorViewRoute(investorId)}>
            <Button size="small" variant="outlined" color="primary">
              View investor
            </Button>
          </Link>
        </div>
      </div>

      <GridContainer>
        <FieldRow title="Investor name" centerTitle>
          {fullName}
        </FieldRow>
        <FieldRow title="Firm" centerTitle>
          {firmName}
        </FieldRow>
        <FieldRow title="Fund" centerTitle>
          {fundName}
        </FieldRow>
        <FieldRow title="Investment date" centerTitle>
          {dateToLabel(investmentDate)}
        </FieldRow>
      </GridContainer>

      <Divider className={classes.divider} />

      <GridContainer>
        <FieldRow
          title="Total commitment"
          centerTitle
          tooltipText={
            fundFullyDeployed
              ? 'The total commitment cannot be amended as the fund is now fully deployed.'
              : undefined
          }
        >
          {totalTransferredAmount > 0 ? (
            <Box alignItems="center" display="flex" gridGap={4}>
              {numberToCurrencyString(totalCommitment)}
              <Tooltip title={transferTooltipText}>
                <CompareArrows />
              </Tooltip>
            </Box>
          ) : (
            <NumberFormat
              required
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              customInput={AppTextInput}
              name="totalCommitment"
              placeholder=""
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              disabled={fundFullyDeployed}
              onValueChange={({ value }) =>
                setEditableDetails({
                  ...editableDetails,
                  totalCommitment: value ? Number(value) : undefined,
                })
              }
              onBlur={() => {
                if (!editableDetails.totalCommitment) {
                  setEditableDetails({
                    ...editableDetails,
                    totalCommitment: 0,
                  });
                }
              }}
              style={{ maxWidth: 300 }}
              value={editableDetails.totalCommitment ?? undefined}
              variant="outlined"
            />
          )}
        </FieldRow>
        <FieldRow title="Funds received" centerTitle>
          <div className={classes.flex}>
            {numberToCurrencyString(
              investmentBalance?.deploymentSummary?.fundsReceived,
            )}
            <FundsReceivedStatusLabel
              paymentExpectations={paymentExpectations}
              fundsReceived={
                investmentBalance?.deploymentSummary?.fundsReceived
              }
              isTransferorInvestment={transferredInvestments.length > 0}
              isTransfereeInvestment={!!sourceInvestmentId}
            />
          </div>
        </FieldRow>
        <FieldRow
          title="Advice fee"
          centerTitle
          tooltipText={
            fundFullyDeployed
              ? 'The advice fee cannot be amended as the fund is now fully deployed.'
              : undefined
          }
        >
          <NumberFormat
            required
            prefix="£"
            decimalScale={2}
            fixedDecimalScale
            customInput={AppTextInput}
            name="adviserFee"
            placeholder=""
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator={true}
            disabled={fundFullyDeployed}
            onValueChange={({ value }) =>
              setEditableDetails({
                ...editableDetails,
                adviserFee: value ? Number(value) : undefined,
              })
            }
            onBlur={() => {
              if (!editableDetails.adviserFee) {
                setEditableDetails({
                  ...editableDetails,
                  adviserFee: 0,
                });
              }
            }}
            style={{ maxWidth: 300 }}
            value={editableDetails.adviserFee ?? undefined}
            variant="outlined"
          />
        </FieldRow>
        <FieldRow title="Commission" centerTitle>
          {numberToCurrencyString(commissionFee || 0)}
        </FieldRow>
      </GridContainer>

      <Divider className={classes.divider} />

      <GridContainer>
        <FieldRow title="Adviser" centerTitle>
          <AdviserSelect
            adviser={editableDetails.adviser}
            style={{ maxWidth: 300 }}
            onChange={(_event, adviser) =>
              setEditableDetails({
                ...editableDetails,
                adviser,
              })
            }
          />
        </FieldRow>
        <FieldRow title="Advised client suitability assessment" centerTitle>
          {advisedClientCertifiedAt ? (
            <span>
              Confirmed by {adviser?.fullName} -{' '}
              {dateToLabel(advisedClientCertifiedAt, '-', true)}
            </span>
          ) : (
            'N/A - Direct investment'
          )}
        </FieldRow>

        <FieldRow title="External investment ID" centerTitle>
          <AppTextInput
            name="externalInvestmentId"
            variant="outlined"
            style={{ maxWidth: 300 }}
            value={editableDetails.externalInvestmentId}
            onChange={(event) =>
              setEditableDetails({
                ...editableDetails,
                externalInvestmentId: event.target.value,
              })
            }
          />
        </FieldRow>
      </GridContainer>
      <div className={classes.endField}></div>
    </>
  );
};

export default InvestmentDetails;
