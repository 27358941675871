import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  summaryRow: {
    backgroundColor: '#FBFBFA',
    marginTop: 4,
  },
  summaryValue: {
    color: '#282525',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 10px 10px 6px',
  },
  splitIcon: {
    fontSize: 18,
    marginLeft: 4,
  },
  inactiveCell: {
    color: theme.palette.text.disabled,
  },
  amendmentCell: {
    backgroundColor: '#FBFBFA',
  },
  feeForecastsButton: {
    marginBottom: 10,
  },
  tooltipLink: {
    color: theme.palette.text.rootColor,
  },
}));

export default useStyles;
