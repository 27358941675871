import React from 'react';
import { Link } from 'react-router-dom';
import { EIS_ADD } from 'constants/routes';
import { Divider } from '@material-ui/core';
import { FlashOn } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import CmtCard from 'components/CmtCard';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import Box from 'components/Box';

export const EisAllotments = ({ eisAllotments, classes }) => (
  <CmtCard>
    <CmtCardHeader
      title="S/EIS Certificates"
      icon={<FlashOn className={classes.green} />}
      className={classes.compactCardHeader}
    >
      <Link className={classes.viewAll} to={EIS_ADD}>
        Create S/EIS Certificates
      </Link>
    </CmtCardHeader>
    <Divider className={classes.divider} />
    <CmtCardContent>
      <GridContainer>
        <Grid item md={6} xs={12}>
          <Box
            title={eisAllotments.awaitingEisWizard}
            subtitle="Allocations awaiting S/EIS processing"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            title={eisAllotments.totalEisWizard}
            subtitle="Allocations with S/EIS processed"
          />
        </Grid>
      </GridContainer>
    </CmtCardContent>
  </CmtCard>
);
