import {
  GET_SUBSCRIPTION_LIST,
  RESET_SUBSCRIPTION_LIST,
} from 'constants/actionTypes';

const INIT_STATE = {
  subscritionData: { result: [] },
  loading: true,
};

const Subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST: {
      return { ...state, subscritionData: action.payload, loading: false };
    }
    case RESET_SUBSCRIPTION_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default Subscription;
