import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import NavMenuItem from './NavMenuItem';
import NavCollapse from './NavCollapse';
import { CustomTheme } from 'theme/themeColors';
import { useGetAllPermissionsList } from 'hooks/ui/useGetPermissions';
import { useSidebarThemeContext } from 'contexts/SidebarThemeContext';

const useStyles = makeStyles((theme: CustomTheme) => ({
  navHeader: {
    position: 'relative',
    padding: '35px 16px 8px 32px',
    textTransform: 'uppercase',
    fontWeight: Number(theme.typography.fontWeightRegular.valueOf),
    fontSize: 10,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  navSection: {
    position: 'relative',
    fontWeight: Number(theme.typography.fontWeightRegular.valueOf),
    '&:not(:first-child) .Cmt-navHeader': {
      borderTop: (props: { sidebarTheme: { borderColor: string } }) =>
        `solid 1px ${props.sidebarTheme.borderColor}`,
      marginTop: 25,
    },
    '&:not(:last-child)': {
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        borderBottom: (props) => `solid 1px ${props.sidebarTheme.borderColor}`,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
}));

const NavSection = (props) => {
  //@ts-ignore
  const { user } = useSelector(({ auth }) => auth);
  const { name, children = [] } = props;
  const isExpandable = children && children.length > 0;
  const { sidebarTheme } = useSidebarThemeContext();
  const classes = useStyles({ sidebarTheme });
  const permissionsList = useGetAllPermissionsList().filter((permission) =>
    permission.includes('read'),
  );

  const MenuCollapse = (
    <List component="div" className={clsx(classes.navHeader, 'Cmt-navHeader')}>
      {name}
    </List>
  );

  const renderMenu = (item, index) => {
    const roleSlug = user?.user?.roleId?.roleSlug;
    const isPermittedForRole =
      item.onlyShownForRoles && !!item.onlyShownForRoles.length
        ? item.onlyShownForRoles.includes(roleSlug)
        : true;

    if (
      (!item?.permission || permissionsList.includes(item?.permission)) &&
      isPermittedForRole
    ) {
      switch (item.type) {
        case 'section':
          return <NavSection {...item} key={index} />;
        case 'collapse':
          return <NavCollapse {...item} key={index} />;
        default:
          return <NavMenuItem {...item} key={index} />;
      }
    }
  };

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => {
        return renderMenu(item, index);
      })}
    </List>
  ) : null;

  return (
    <div className={classes.navSection}>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );
};

export default NavSection;
