import React from 'react';
import CardHeader from 'components/CardHeader';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { makeStyles, Button } from '@material-ui/core';
import { useLoadMoreAndHideContent } from 'hooks/ui/useLoadMoreContent';

const useStyles = makeStyles((theme) => ({
  summaryRow: {
    backgroundColor: '#FBFBFA',
    marginTop: 4,
  },
  summaryValue: {
    color: '#282525',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 60,
    padding: '10px 24px 10px 0',
  },
  loadMoreContent: {
    paddingTop: '2px',
    width: '90%',
  },
}));

const columns = [
  {
    label: 'Date',
    key: 'date',
    render: (elm) => dateToLabel(elm.date),
  },
  {
    label: 'Interest payment',
    key: 'amount',
    render: (elm) => numberToCurrencyString(elm.amount),
  },
];

const InterestPayments = ({ interestPayments = [] }) => {
  const classes = useStyles();

  const totalInterestPayments = interestPayments.reduce((acc, curr) => {
    let amount = curr.amount;

    return acc + amount;
  }, 0);
  const { onToggle, displayedContent } =
    useLoadMoreAndHideContent(interestPayments);

  return (
    <>
      <CardHeader title="Interest received" />
      <Table
        columns={columns}
        tablebody={displayedContent}
        emptyMessage="No interest payments found"
        variant="nohover"
        TableFooter={
          <tfoot>
            {interestPayments.length > 10 ? (
              <tr className={classes.summaryRow}>
                <td colSpan={2} align="center">
                  <Button color="primary" onClick={onToggle}>
                    {displayedContent.length > 10
                      ? 'Collapse interest payments table'
                      : ' Click here to show full table contents'}
                  </Button>
                </td>
              </tr>
            ) : null}

            <tr className={classes.summaryRow}>
              <td className={classes.summaryValue} align="right">
                Total interest payments
              </td>
              <td className={classes.summaryValue}>
                {numberToCurrencyString(totalInterestPayments)}
              </td>
            </tr>
          </tfoot>
        }
      />
    </>
  );
};

export default InterestPayments;
