import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { Box, TextField, Tooltip, Typography } from '@material-ui/core';
import useStyles from './styles';
import { sum, sumBy } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import {
  InvestmentDetails as InvestmentDetailsType,
  ShareHolding,
} from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import { numberToDisplayString } from 'utils/numbers';

type InvestmentsDetailsProps = {
  investment: InvestmentDetailsType;
};

const staticColumns = [
  {
    label: 'Company',
    render: (shareholding: ShareHolding) => shareholding.company.tradingName,
  },
  {
    label: 'Investment date',
    render: (shareholding: ShareHolding) => dateToLabel(shareholding.date),
  },
  {
    label: 'Initial share price',
    render: (shareholding: ShareHolding) =>
      numberToCurrencyString(shareholding.company.initialPrice),
  },
  {
    label: 'Shares held',
    render: (shareholding: ShareHolding) =>
      numberToDisplayString(shareholding.noOfShare),
  },
  {
    label: 'Initial investment',
    render: (shareholding: ShareHolding) =>
      numberToCurrencyString(shareholding.subscriptionAmount),
  },
  {
    label: 'Current share price',
    render: (shareholding: ShareHolding) =>
      numberToCurrencyString(shareholding.currentSharePrice),
  },
  {
    label: 'Current share value',
    render: (shareholding: ShareHolding) =>
      numberToCurrencyString(shareholding.currentShareValue),
  },
];

const InvestmentDetails: React.FC<InvestmentsDetailsProps> = ({
  investment,
}) => {
  const classes = useStyles();
  const { control, watch } = useFormContext();

  const columns = [
    ...staticColumns,
    {
      label: 'Shares to transfer',
      render: (shareholding: ShareHolding) => (
        <Controller
          name={`sharesToTransfer[${investment.investmentId}][${shareholding.id}]`}
          defaultValue={0}
          control={control}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={error?.message ?? ''}
            >
              <TextField
                inputRef={ref}
                type="number"
                variant="outlined"
                error={!!error}
                {...rest}
              />
            </Tooltip>
          )}
        />
      ),
    },
    {
      label: 'Value to transfer',
      render: (shareholding: ShareHolding) => {
        const sharesToTranfer = watch(
          `sharesToTransfer[${investment.investmentId}][${shareholding.id}]`,
        );
        return numberToCurrencyString(
          shareholding.company.initialPrice * (sharesToTranfer ?? 0),
        );
      },
    },
  ];

  const liveShareholdings = investment.shareholdings.filter(
    (shareholding) => shareholding.noOfShare > 0,
  );

  const getSharesToTransferValue = () => {
    return sum(
      liveShareholdings.map(
        (shareholding) =>
          (shareholding.company?.initialPrice ?? 0) *
          (watch(
            `sharesToTransfer[${investment.investmentId}][${shareholding.id}]`,
          ) ?? 0),
      ),
    );
  };

  if (!investment.availableForTransfer) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gridGap={20}
        alignItems="center"
        py={8}
        className={classes.transferNotAvailableMessage}
      >
        <Typography variant="h1" align="center">
          This investment is still active
        </Typography>
        <Box maxWidth={550} display="flex" justifyContent="center">
          <Typography variant="body1" align="center">
            To transfer any holdings from this investment, please first freeze
            the investment and withdraw all this investment's uninvested
            capital.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Table
      columns={columns}
      tablebody={liveShareholdings}
      TableFooter={
        <tfoot>
          <tr className={classes.tableFooter}>
            <td colSpan={4}>
              <Typography
                variant="h6"
                component="div"
                align="right"
                className={classes.totalValueLabel}
              >
                Total initial value
              </Typography>
            </td>
            <td>
              <Typography variant="h6" component="div">
                {numberToCurrencyString(
                  sumBy(liveShareholdings, (shareholding) =>
                    Number(shareholding.subscriptionAmount),
                  ),
                )}
              </Typography>
            </td>
            <td>
              <Typography
                variant="h6"
                component="div"
                align="right"
                className={classes.totalValueLabel}
              >
                Total current value
              </Typography>
            </td>
            <td>
              <Typography variant="h6" component="div">
                {numberToCurrencyString(
                  sumBy(liveShareholdings, 'currentShareValue'),
                )}
              </Typography>
            </td>
            <td>
              <Typography
                variant="h6"
                component="div"
                align="right"
                className={classes.totalValueLabel}
              >
                Initial value to transfer
              </Typography>
            </td>
            <td>
              <Typography variant="h6" component="div">
                {numberToCurrencyString(getSharesToTransferValue())}
              </Typography>
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default InvestmentDetails;
