import { Divider } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';
import { pluralize } from 'utils/pluralize';

const Summary = ({
  data,
  classes,
  selectedTranchesOrCompanies,
  isTrancheSelected,
}) => {
  return (
    <>
      <GridContainer item md={11}>
        <FieldRow title={'Update type'}>Custom update</FieldRow>

        <FieldRow
          title={
            data.sendToAllUsers
              ? 'Recipients'
              : isTrancheSelected
              ? 'Tranches'
              : 'Companies'
          }
        >
          {data.sendToAllUsers
            ? 'Sent to all registered users'
            : selectedTranchesOrCompanies?.join(', ') || '-'}
        </FieldRow>
        <FieldRow title="Number of recipients">
          {data?.numberOfRecipients || '-'}
        </FieldRow>
      </GridContainer>
      <Divider className={classes.divider} />
      <GridContainer item md={11}>
        <FieldRow title={'Subject line'}>{data?.subject || '-'}</FieldRow>

        <FieldRow title="Body title">{data?.title || '-'}</FieldRow>
        <FieldRow title="Body text">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.emailBody,
            }}
          />
        </FieldRow>

        {data.attachments?.length > 0 && (
          <FieldRow
            title={`${pluralize('Attachment', data?.attachments?.length)}`}
          >
            {data?.attachments?.map((attachment, index) => {
              return (
                <div key={index} className="mb-1">
                  <a
                    key={index}
                    href={attachment?.preview || attachment?.url}
                    rel="noreferrer"
                    target={'_blank'}
                    className={classes.attachmentLink}
                  >
                    {attachment?.title || 'Attachment'}
                  </a>
                </div>
              );
            })}
          </FieldRow>
        )}
      </GridContainer>
    </>
  );
};

export default Summary;
