import { useLayoutContext } from 'contexts/LayoutContext';
import SidebarContent from './SidebarContent';
import CmtDrawer from 'components/CmtDrawer';

const DrawerContent = ({ children, classes }) => {
  const { isSidebarOpen, setSidebarOpen } = useLayoutContext();
  return (
    <CmtDrawer
      variant="temporary"
      open={isSidebarOpen}
      onClose={() => setSidebarOpen(false)}
      classes={{
        paper: 'Cmt-drawer-sidebar',
      }}
    >
      <SidebarContent classes={classes}>{children}</SidebarContent>
    </CmtDrawer>
  );
};

export default DrawerContent;
