import React, { useEffect } from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import Switch from 'components/Switch';
import TextField from 'components/FormElements/AppTextInput';
import CkEditor from 'components/CkEditor';
import GridContainer from 'components/GridContainer';
import MultiFirmTrancheSelector from 'components/MultiFirmTrancheSelector';
import MultiFirmCompanySelector from 'components/MultiFirmCompanySelector';
import AppCheckbox from 'components/FormElements/AppCheckBox';
import { AppSelectBox } from 'components/FormElements';
import { DeliveryType } from 'further-types/custom-updates';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { useNotification } from 'hooks/ui/useNotification';
import Dropzone from 'components/Dropzone';
import { useGetRole } from 'hooks/ui/useGetRole';
import getFirmsInTranches from 'helpers/firm/get-firms-in-tranches';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';

const AddCustomUpdateDetails = ({
  classes,
  data,
  errorText,
  setData,
  mappedTranche,
}) => {
  const onChange = (event) => {
    const { value, name } = event.target;
    data[name] = value;
    setData({ ...data });
  };
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();
  const { isSuperAdmin } = useGetRole();
  const { data: managers } = useReportingAccountManagers(
    data?.selectedFirmIds?.[0],
  );

  async function uploadMedia(file) {
    try {
      const { filePath } = await uploadFile(
        file,
        UploadType.CustomUpdateAttachments,
      );
      return filePath;
    } catch (_) {
      error(`Something went wrong - the file couldn't be uploaded`);
    }
  }

  const handleChangeAttachment = async (files) => {
    await Promise.all(
      files.map(async (file) => {
        if (file.path) {
          const filePath = await uploadMedia(file);
          if (!filePath) return;

          setData({
            ...data,
            attachments: [
              ...data.attachments,
              {
                title: file?.name,
                url: filePath,
                preview: file?.preview,
              },
            ],
          });
        }
      }),
    );
  };

  const handleRemoveAttachment = (fileIdx) => {
    setData({
      ...data,
      attachments: data.attachments.filter(
        (_attachment, idx) => idx !== fileIdx,
      ),
    });
  };

  const handleUpdateSendToAllUsers = (event) => {
    const { selectedFirmIds, selectedFirmNames } = getFirmsInTranches(
      mappedTranche.map((tranche) => tranche._id),
      mappedTranche,
    );

    setData({
      ...data,
      sendToAllUsers: event.target.checked,
      trancheId: event.target.checked
        ? mappedTranche.map((tranche) => tranche._id)
        : [],
      selectedFirmIds: event.target.checked ? selectedFirmIds : [],
      selectedFirmNames: event.target.checked ? selectedFirmNames : [],
    });
  };

  useEffect(() => {
    if (data.selectedFirmIds.length !== 1) {
      setData({ ...data, accountManager: [] });
    }
  }, [data.selectedFirmIds]);

  return (
    <GridContainer item md={11}>
      <FieldRow title="Select company">
        <MultiFirmCompanySelector
          handleCompanyMultiSelectChanges={(value) => {
            setData({ ...data, ...value, companyId: value.selectedCompanyIds });
          }}
          error={errorText?.companyId}
          disabled={data?.trancheId?.length || data?.sendToAllUsers}
          value={data?.companyId}
        />
      </FieldRow>

      <FieldRow title="Or select tranche(s)">
        <MultiFirmTrancheSelector
          handleChangeSelectedTranches={(value) => {
            setData({ ...data, ...value, trancheId: value.selectedTrancheIds });
          }}
          trancheFilters={{ fundStatus: [0, 1] }}
          disabled={data?.companyId?.length || data?.sendToAllUsers}
          value={data?.trancheId}
        />
      </FieldRow>

      {!isSuperAdmin && (
        <FieldRow title="Or send to all registered users" centerTitle>
          <Switch
            inputProps={{ 'aria-label': 'Switch' }}
            checked={data.sendToAllUsers}
            onChange={handleUpdateSendToAllUsers}
          />
        </FieldRow>
      )}

      <FieldRow title="Send to" centerTitle>
        <AppCheckbox
          label="Direct investors"
          key="includeDirectInvestors"
          checked={!!data.sendToDirectInvestors}
          onChange={(event) => {
            setData({
              ...data,
              sendToDirectInvestors: event.target.checked,
            });
          }}
          error={errorText?.sendTo}
          helperText={errorText?.sendTo}
        />
        <AppCheckbox
          label="Advised investors"
          key="includeAdvisedInvestors"
          checked={!!data.sendToAdvisedInvestors}
          onChange={(event) => {
            setData({
              ...data,
              sendToAdvisedInvestors: event.target.checked,
            });
          }}
        />
        <AppCheckbox
          label="Advisers"
          key="includeAdvisers"
          checked={!!data.sendToAdvisers}
          onChange={(event) => {
            setData({
              ...data,
              sendToAdvisers: event.target.checked,
            });
          }}
        />
        <AppCheckbox
          label="Organisations"
          key="includeOrganisations"
          checked={!!data.sendToOrganisations}
          onChange={(event) => {
            setData({
              ...data,
              sendToOrganisations: event.target.checked,
            });
          }}
        />
      </FieldRow>

      <FieldRow title="Delivery method" centerTitle>
        <AppSelectBox
          data={[
            { label: 'Email only', value: DeliveryType.Email },
            { label: 'Display in portal only', value: DeliveryType.Portal },
            {
              label: 'Email and display in portal',
              value: DeliveryType.EmailAndPortal,
            },
          ]}
          valueKey="value"
          labelKey="label"
          disabled={
            !(
              data.sendToDirectInvestors ||
              data.sendToAdvisedInvestors ||
              data.sendToAdvisers ||
              data.sendToOrganisations
            )
          }
          value={data.deliveryToInvestors}
          onChange={(event) => {
            setData({
              ...data,
              deliveryToInvestors: event.target.value,
            });
          }}
        />
      </FieldRow>

      <FieldRow title="Subject line*" centerTitle>
        <TextField
          name="subject"
          placeholder="e.g. An update on Company X’s performance"
          required
          fullWidth
          error={errorText?.subject}
          helperText={errorText?.subject}
          value={data.subject}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Body title*" centerTitle>
        <TextField
          name="title"
          placeholder="e.g. Company X - an update"
          required
          fullWidth
          value={data.title}
          error={errorText?.title}
          helperText={errorText?.title}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Body text*">
        Dear $investorfirstname,
        <CkEditor
          name="emailBody"
          placeholder="e.g. An update on Company X’s performance"
          onChange={(event, editor) => {
            const value = editor.getData();
            setData({
              ...data,
              emailBody: value,
            });
          }}
          value={data.emailBody}
        />
        <p className={classes.errorCk}>{errorText?.emailBody}</p>
      </FieldRow>

      <FieldRow title="Select sign-off manager*" topTitle>
        <AppSelectBox
          required
          data={managers?.accountManagers}
          valueKey="_id"
          labelKey="name"
          renderValue={(value) => (
            <>{value.name || 'Select sign-off manager'}</>
          )}
          variant="outlined"
          disabled={data.selectedFirmIds?.length !== 1}
          value={data.accountManager}
          error={!!errorText?.accountManager}
          helperText={errorText?.accountManager}
          onChange={(event) => {
            const value = event.target.value;
            setData({
              ...data,
              accountManager: managers?.accountManagers.find(
                (manager) => manager._id === value,
              ),
            });
          }}
        />
      </FieldRow>

      <FieldRow title="Manager's role*" topTitle>
        <TextField
          name="role"
          placeholder="e.g. Partner, Fund name"
          required
          disabled={data.selectedFirmIds?.length !== 1}
          value={data.role}
          error={errorText?.role}
          helperText={errorText?.role}
          onChange={onChange}
        />
      </FieldRow>

      <FieldRow title="Upload attachment" topTitle>
        <Dropzone
          files={
            data.attachments.map((file) => ({
              fileName: file.title,
              preview: file.preview || file.url,
            })) ?? []
          }
          onDropFile={handleChangeAttachment}
          onRemove={handleRemoveAttachment}
          maxFiles={10}
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
          }}
        />
      </FieldRow>
    </GridContainer>
  );
};

export default AddCustomUpdateDetails;
