import React from 'react';
import { Divider } from '@material-ui/core';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import CmtAvatar from 'components/CmtAvatar';
import { makeStyles } from '@material-ui/core';
import { usePagination } from 'hooks/ui/usePagination';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dcont: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.75rem',
  },
}));

export const TrancheCompaniesTable = ({
  rows,
  trancheId,
  trancheName,
  parentClasses,
}) => {
  const classes = useStyles();
  const pagination = usePagination();

  const columns = [
    {
      label: 'Company',
      key: 'tradingName',
      render: (elm) => (
        <div className={classes.dcont}>
          <CmtAvatar size={30} src={elm?.companyLogo} />
          {elm?.tradingName}
        </div>
      ),
    },
    {
      label: 'Investment date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      label: 'Investment amount',
      key: 'sumSubscriptionAmount',
      render: (elm) =>
        numberToCurrencyString(elm?.sumSubscriptionAmount, {
          hidePenniesIfInteger: true,
        }),
    },
  ];

  return (
    <CmtCard className={parentClasses.flexGrow}>
      <CmtCardHeader title={`Companies in ${trancheName}`}>
        <Link
          className={parentClasses.viewAll}
          to={`/company?fundId=${trancheId}`}
        >
          View all
        </Link>
      </CmtCardHeader>
      <Divider className={parentClasses.divider} />
      <CmtCardContent className={parentClasses.scrollableTable}>
        <Table
          columns={columns}
          tablebody={rows.sort(pagination.genericSorter)}
          pagination={false}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          emptyMessage="No companies yet"
          variant="nohover"
        />
      </CmtCardContent>
    </CmtCard>
  );
};
