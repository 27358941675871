import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchCompany({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const { data } = await api.get(`investee/list/${params.companyId}`);

    return data.data;
  } catch (error) {
    throw error;
  }
}

async function fetchCompanies({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(`investee/list`, { params });

    return data.data;
  } catch (error) {
    throw error;
  }
}

export function useCompanies({ params }) {
  const company = useQuery({
    queryKey: ['company', { params: { companyId: params.companyId } }],
    queryFn: fetchCompany,
    enabled: !!params?.companyId,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const companies = useQuery({
    queryKey: ['companies', { ...params }],
    queryFn: fetchCompanies,
    enabled: !params?.companyId,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  return {
    company,
    companies,
  };
}
