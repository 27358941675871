import React, { useEffect, useState, memo, useMemo } from 'react';
import { useCompanies } from 'hooks/data/company/useCompanies';
import { sortBy, isEqual } from 'lodash';
import { getCompanyLabel } from 'further-ui/utils';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';

const CompanyListSelect = ({
  handleChangeCallback,
  firmId,
  value,
  includeShareClassInLabel = true,
  includeFirmNameInLabel = true,
  ...rest
}) => {
  const [selected, setSelected] = useState('');
  const { companies } = useCompanies({ params: { isVct: false } });

  useEffect(() => {
    if (value && !isEqual(value, selected)) {
      setSelected(value);
    }
    if (!value && !!selected) {
      setSelected('');
    }
  }, [value]);

  // Call the parent callback when the selected value changes
  useEffect(() => {
    handleChangeCallback(selected);
  }, [selected]);

  // memoize the transformed list
  const companiesWithLabels = useMemo(() => {
    const list = companies.data?.result
      .filter((company) => {
        if (firmId) return company?.firmId?._id === firmId;
        return true;
      })
      .map((company = {}) => {
        return {
          ...company,
          label: getCompanyLabel(company, {
            includeShareClass: includeShareClassInLabel,
            includeFirmName: includeFirmNameInLabel,
          }),
        };
      });

    return sortBy(list, (item) => item.label.toLowerCase());
  }, [companies.data?.result, firmId]);

  return (
    <Autocomplete
      name="companyId"
      disabled={!companiesWithLabels.length}
      options={companiesWithLabels}
      getOptionLabel={(option) => option.label || ''}
      filterSelectedOptions
      value={selected}
      onChange={(_event, selectedCompany) => {
        setSelected(selectedCompany);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select company"
        />
      )}
      {...rest}
    />
  );
};

export default memo(CompanyListSelect);
