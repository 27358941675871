import React from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { makeStyles } from '@material-ui/core';
import { startCase, sumBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  summaryRow: {
    backgroundColor: '#FBFBFA',
    marginTop: 4,
  },
  summaryValue: {
    color: '#282525',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 60,
    padding: '10px 24px 10px 0',
  },
}));

const Dividend = ({ dividends = [], firmName, fundName }) => {
  const classes = useStyles();
  const columns = [
    {
      label: 'Date',
      key: 'date',
      render: (elm) => dateToLabel(elm.date),
    },
    {
      label: 'Type',
      key: 'type',
      render: (elm) => startCase(elm.type.toLowerCase()),
    },
    {
      label: 'Company',
      key: 'company',
      render: (elm) => {
        return elm?.company?.isVCT
          ? `${firmName} ${fundName}`
          : elm?.company?.tradingName;
      },
    },
    {
      label: 'Per share',
      key: 'perShares',
      render: (elm) => numberToCurrencyString(elm.perShares),
    },
    {
      label: 'Shares',
      key: 'shares',
    },
    {
      label: 'Total',
      key: 'amount',
      render: (elm) => numberToCurrencyString(elm.amount),
    },
  ];

  const totalDividends = sumBy(dividends, 'amount');

  return (
    <Table
      columns={columns}
      tablebody={dividends}
      emptyMessage="No current dividends found"
      variant="nohover"
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} align="right" colSpan={5}>
              Total dividends
            </td>
            <td className={classes.summaryValue}>
              {numberToCurrencyString(totalDividends)}
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default Dividend;
