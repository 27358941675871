import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './Auth';
import User from './User';
import Subscription from './Subscription';

const Reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    UserReducer: User,
    SubscriptionReducer: Subscription,
  });

export default Reducers;
