import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  input: {
    marginRight: theme.spacing(7),
  },
  button: {
    marginLeft: theme.spacing(4),
    padding: `8px 16px 5px !important`,
  },
  icon: {
    position: 'absolute',
    right: '10px',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.text.rootColor,
    },
  },
}));

export default useStyles;
