import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchDashboard({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(
      `dashboard/open-tranche/${params.trancheId}`,
    );

    return data.data;
  } catch (error) {
    throw error;
  }
}

export function useOpenTrancheDashboard({ params }) {
  return useQuery({
    queryKey: ['openTrancheDashboard', params],
    queryFn: fetchDashboard,
    enabled: !!params?.trancheId,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}
