import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { omit } from 'lodash';
import moment from 'moment';
import FieldRow from 'components/FormElements/FieldRow';
import AppDatePicker from 'components/FormElements/AppDatePicker';

import GridContainer from 'components/GridContainer';
import { getExcelFile } from '@redux/actions/Investment';
import CompanyListSelect from 'components/CompanyListSelect';
import MultiFirmTrancheSelector from 'components/MultiFirmTrancheSelector';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#999999',
  },
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
  buttonRow: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: '0.5rem',
    marginTop: '0',
  },
}));

/**
 * @callback CallbackWithFormState
 * @param {{selectedTrancheIds: [string], investmentDate: string}} formState The new form state
 */

/**
 * @param {{callback: CallbackWithFormState, skipCallback: bool}} props
 */
const CreateSheetForm = ({
  callback,
  skipCallback = null,
  hideButtons = false,
}) => {
  const classes = useStyles();
  const [excelFileGenerating, setExcelFileGenerating] = useState(false);
  const [formState, setFormState] = useState({
    selectedFirmNames: [],
    selectedFirmIds: [],
    selectedTrancheIds: [],
    selectedCompany: '',
    investmentDate: moment(new Date(), 'DD/MM/YYYY', true),
  });
  const dispatch = useDispatch();
  const notification = useNotification();
  const handleApiRequest = useApiRequestHandler();

  /**
   * This function is called when the user selects one or more tranches from the multiselect dropdown.
   * It takes the tranche ids as an argument and updates the formState with the new tranche ids.
   * @param trancheIds - the selected tranche ids
   * @returns {Promise<void>}
   */
  const handleChangeSelectedTranches = (values) => {
    setFormState((formState) => ({
      ...formState,
      ...values,
    }));
  };

  /**
   * This function is called when the user clicks the download button.
   */
  const handleDownloadButtonClick = async () => {
    setExcelFileGenerating(true);
    const body = {
      ...omit(formState, ['selectedCompany']),
      selectedCompanyId: formState.selectedCompany?._id,
      investmentDate: formState.investmentDate.format(
        'YYYY-MM-DDT00:00:00+00:00',
      ),
    };

    await handleApiRequest(async () => {
      const response = await dispatch(getExcelFile(body, notification));
      setExcelFileGenerating(false);

      if (response.status === 200) {
        callback(body);
      }
    }, 'Something went wrong');
  };

  return (
    <>
      <FieldRow key="tranches" title="Tranche(s)*">
        <MultiFirmTrancheSelector
          trancheFilters={{ fundStatus: 0, fullyDeployed: false }}
          handleChangeSelectedTranches={handleChangeSelectedTranches}
        />
      </FieldRow>
      <FieldRow key="companies" title="Company*">
        <CompanyListSelect
          firmId={
            formState.selectedFirmIds.length && formState.selectedFirmIds[0]
          }
          handleChangeCallback={(selected) => {
            setFormState((formState) => ({
              ...formState,
              selectedCompany: selected,
            }));
          }}
        />
      </FieldRow>
      <FieldRow key="date" title="Date of investment*">
        <AppDatePicker
          placeholder="Select date of investment"
          name="investmentDate"
          value={formState.investmentDate}
          onChange={(date) =>
            setFormState((formState) => ({
              ...formState,
              investmentDate: date,
            }))
          }
          disableFuture={true}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FieldRow>
      {!hideButtons && (
        <FieldRow key="actions">
          {skipCallback && (
            <GridContainer className={classes.buttonRow}>
              <Button variant="text" color="primary" onClick={skipCallback}>
                Skip
              </Button>
              {excelFileGenerating && <CircularProgress size={20} />}
              <Button
                variant="contained"
                color="primary"
                disabled={
                  excelFileGenerating ||
                  formState.selectedTrancheIds.length === 0 ||
                  !formState.investmentDate?.isValid() ||
                  !formState.selectedCompany ||
                  formState.selectedFirmIds.length > 1
                }
                onClick={handleDownloadButtonClick}
              >
                Create
              </Button>
            </GridContainer>
          )}
        </FieldRow>
      )}
    </>
  );
};

export default memo(CreateSheetForm);
