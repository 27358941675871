import { FC, useState, useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';
import GridContainer from 'components/GridContainer';
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';

import CompanyDetails from './CompanyDetails';
import EisFormData from './EisFormData';
import FormActionButtons from './FormActionButtons';
import Summary from './Summary';
import { Api } from 'further-types/eis-wizard';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { EIS_EDIT, EIS } from 'constants/routes';

import FullPageSuccess from 'components/FullPageSuccess';
import { useNotification } from 'hooks/ui/useNotification';
import { useEisWizard } from 'hooks/data/eisWizard/useEisWizard';
import { useDownloadByEisWizardId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';

const steps = [
  'Select company and tranche(s)',
  'Input form data',
  'Summary',
  'Success',
];

const useStyles = makeStyles(() => ({
  stepper: {
    background: 'transparent',
    marginBottom: '2rem',
    paddingLeft: 0,
  },
  cardContentPadding: {
    padding: '2rem',
  },
  circularProgressRoot: {
    gridGap: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AddUpdateEisWizard: FC = () => {
  const history = useHistory();

  const isEditPage = useRouteMatch(EIS_EDIT)?.isExact;
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { error } = useNotification();

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'S/EIS Tasklist', link: '/eiswizard' },
    {
      label: isEditPage
        ? 'Update S/EIS Certificates'
        : 'Create S/EIS Certificates',
      link: '/',
      isActive: true,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState<Api.MutateEisWizardRequest>({
    companyId: searchParams.get('presetCompanyId') || '',
    shareAllocationUploadId:
      searchParams.get('presetShareAllocationUploadId') || '',
    signatureType: '0',
    signatureDate: null,
    dateOfShareIssue: null,
    terminationDateOfShare: null,
    hmrcAuthorisationDate: null,
    notifyInvestors: false,
  });
  const [summaryData, setSummaryData] = useState<any>({});
  const [errorText, setErrorText] = useState({});
  const classes = useStyles();
  const downloadCertificates = useDownloadByEisWizardId();

  const onMutationSuccess = (eiswizardId: string) => {
    setSummaryData({
      ...summaryData,
      eiswizardId,
    });
    setActiveStep(steps.length - 1);
  };

  const { eisWizard, createEisWizard, updateEisWizard } = useEisWizard(
    onMutationSuccess,
    id,
  );

  const mutationLoading =
    createEisWizard.isLoading || updateEisWizard.isLoading;

  const validate = () => {
    const errors: Record<string, string> = {};
    let isError = false;
    if (!data.investmentType) {
      errors.investmentType = 'Please select investment type.';
      isError = true;
    }
    if (!data.uir) {
      errors.uir = 'Please enter unique investment reference';
      isError = true;
    } else if (!/^[a-zA-Z0-9-~` ]+$/i.test(data.uir)) {
      errors.uir = 'Only alphanumeric are allowed.';
      isError = true;
    }
    if (!data.descriptionShare) {
      errors.descriptionShare = 'Please enter description of shares.';
      isError = true;
    } else if (data.descriptionShare.length >= 40) {
      errors.descriptionShare = 'Only 40 characters are allowed.';
      isError = true;
    }
    if (!data.nominalValueOfEachShare) {
      errors.nominalValueOfEachShare =
        'Please enter nominal value of each share.';
      isError = true;
    }
    if (!data.terminationDateOfShare) {
      errors.terminationDateOfShare =
        'Please select termination date of share.';
      isError = true;
    }
    if (!data.dateOfShareIssue) {
      errors.dateOfShareIssue = 'Please select date of share issue.';
      isError = true;
    }
    if (
      data.knowledgeIntensive === undefined ||
      data.knowledgeIntensive === null ||
      data.knowledgeIntensive === ''
    ) {
      errors.knowledgeIntensive = 'Please select an option';
      isError = true;
    }
    if (!data.address1) {
      errors.address1 = 'Please enter address 1.';
      isError = true;
    }
    if (!data.city) {
      errors.city = 'Please enter city.';
      isError = true;
    }
    if (!data.country) {
      errors.country = 'Please enter country.';
      isError = true;
    }
    if (!data.postcode) {
      errors.postcode = 'Please enter postcode.';
      isError = true;
    }
    if (!data.nameOfCompanyRepresentative) {
      errors.nameOfCompanyRepresentative =
        'Please enter name of company representative.';
      isError = true;
    }
    if (!data.capicityInWhichSigned) {
      errors.capicityInWhichSigned = 'Please enter capacity in which signed.';
      isError = true;
    }
    if (!data.signatureType && data.signatureType !== '0') {
      errors.signatureType = 'Please select an option.';
      isError = true;
    }
    if (!data.signatureDate) {
      errors.signatureDate = 'Please select signature date.';
      isError = true;
    }
    setErrorText(errors);

    return {
      errors,
      isError,
    };
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.push(EIS);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      const { isError } = validate();
      if (!isError) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      const payload = { ...data };
      if (data.dateOfShareIssue) {
        payload.dateOfShareIssue = moment(data.dateOfShareIssue).format(
          'DD/MM/YYYY',
        );
      }
      if (data.terminationDateOfShare) {
        payload.terminationDateOfShare = moment(
          data.terminationDateOfShare,
        ).format('DD/MM/YYYY');
      }
      if (data.hmrcAuthorisationDate) {
        payload.hmrcAuthorisationDate = moment(
          data.hmrcAuthorisationDate,
        ).format('DD/MM/YYYY');
      }
      if (data.signatureDate) {
        payload.signatureDate = moment(data.signatureDate).format('DD/MM/YYYY');
      }

      if (id) {
        updateEisWizard.mutate(payload);
      } else {
        createEisWizard.mutate(payload);
      }
    }
  };

  const handleCreateWithoutCertificates = async (investmentType: string) => {
    setData({ ...data, investmentType });
    setSummaryData({
      ...summaryData,
      investmentType,
      createdWithoutCertificates: true,
    });
    createEisWizard.mutate({
      shareAllocationUploadId: data.shareAllocationUploadId,
      companyId: data.companyId,
      investmentType,
      createdWithoutCertificates: true,
    });
  };

  useEffect(() => {
    if (isEditPage && id && eisWizard.data) {
      const item = eisWizard.data?.data;
      setData({
        companyId: item.companyId,
        shareAllocationUploadId: item.shareAllocationUploadId,
        descriptionShare: item.descriptionShare,
        uir: item.uir,
        knowledgeIntensive: item.knowledgeIntensive,
        signatureType: item.signatureType?.toString(),
        postcode: item.postcode,
        address1: item.address1,
        address2: item.address2,
        city: item.city,
        country: item.country,
        capicityInWhichSigned: item.capicityInWhichSigned,
        nominalValueOfEachShare: item.nominalValueOfEachShare,
        dateOfShareIssue: moment(item.dateOfShareIssue).format('YYYY-MM-DD'),
        terminationDateOfShare: moment(item.terminationDateOfShare).format(
          'YYYY-MM-DD',
        ),
        hmrcAuthorisationDate: item.hmrcAuthorisationDate
          ? moment(item.hmrcAuthorisationDate).format('YYYY-MM-DD')
          : null,
        signatureDate: moment(item.signatureDate).format('YYYY-MM-DD'),
        nameOfCompanyRepresentative: item.nameOfCompanyRepresentative,
        investmentType: item.investmentType,
        createdWithoutCertificates: item.createdWithoutCertificates,
      });
    }
  }, [isEditPage, id, eisWizard.data]);

  const investmentTypeLabel = `${data.investmentType} certificates`;

  const handleDownload = async () => {
    if (!summaryData.eiswizardId) {
      return error('Cannot download - the certificates have not been made');
    }
    downloadCertificates.download({
      eisWizardId: summaryData.eiswizardId,
      allocationName: summaryData.allocationName,
      companyName: summaryData.companyName,
      investmentTypeLabel,
    });
  };

  const heading = isEditPage
    ? 'S/EIS Certificates: Update certificates'
    : 'S/EIS Certificates: Create certificates';

  if (activeStep === steps.length - 1)
    return (
      <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title={`Congratulations, you have created new ${investmentTypeLabel}`}
          additionalText={`Your investors' ${investmentTypeLabel} have been uploaded to their investor portals.`}
          proceedLink={EIS}
          proceedText="Back to S/EIS Certificates table"
          secondaryCallback={
            summaryData.createdWithoutCertificates ? undefined : handleDownload
          }
          secondaryText={
            summaryData.createdWithoutCertificates
              ? undefined
              : downloadCertificates.isLoading
              ? 'Downloading...'
              : 'Download these certificates'
          }
        />
      </PageContainer>
    );

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <GridContainer>
            <Grid item sm={12} md={12} lg={9}>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {mutationLoading && (
                <div className={classes.circularProgressRoot}>
                  <CircularProgress />
                  <p>
                    This may take a few minutes. Please don't close the page.
                  </p>
                </div>
              )}

              {!mutationLoading && activeStep === 0 && (
                <CompanyDetails
                  errorText={errorText}
                  data={data}
                  setData={setData}
                  setSummaryData={setSummaryData}
                />
              )}

              {!mutationLoading && activeStep === 1 && (
                <EisFormData
                  errorText={errorText}
                  data={data}
                  setData={setData}
                />
              )}

              {!mutationLoading && activeStep === 2 && (
                <Summary data={data} summaryData={summaryData} />
              )}

              {!mutationLoading && activeStep !== 3 && (
                <FormActionButtons
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleCreateWithoutCertificates={
                    handleCreateWithoutCertificates
                  }
                  data={data}
                  setData={setData}
                  isEditPage={isEditPage}
                  isFinalStep={activeStep === steps.length - 2}
                />
              )}
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddUpdateEisWizard;
