import React from 'react';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import {
  Area,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  chartTitle: {
    color: '#282525',
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const InvestmentsByDate = ({ data }) => {
  const classnames = useStyles();

  return (
    <CmtCard>
      <CmtCardContent>
        <Typography className={classnames.chartTitle}>
          Lifetime tranche investment record
        </Typography>
        <ResponsiveContainer width="100%" height={270}>
          <ComposedChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 3 }}
          >
            <XAxis dataKey="date" hide />
            <YAxis
              hide
              type="number"
              dataKey={'investmentAmountCumulative'}
              domain={[0, (dataMax) => dataMax * 1.3]}
            />

            <Tooltip
              labelStyle={{ color: 'black' }}
              cursor={false}
              formatter={(value) =>
                numberToCurrencyString(value, { hidePenniesIfInteger: true })
              }
              labelFormatter={(value) => dateToLabel(value)}
            />
            <defs>
              <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="25%" stopColor="#56b26c" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              name="Total invested"
              dataKey="investmentAmountCumulative"
              strokeWidth={3}
              stroke="#56b26c"
              fill="url(#areaGradient)"
            />
            <Line
              type="monotone"
              name="Invested on this day"
              dataKey="investmentAmount"
              dot={false}
              strokeWidth={3}
              stroke="#DC9E5A"
              fill="#DC9E5A"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CmtCardContent>
    </CmtCard>
  );
};

export default InvestmentsByDate;
