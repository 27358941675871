import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { resetUser } from '@redux/actions/User';
import { resetSubcription } from '@redux/actions/Subscription';

const useLogout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const handleLogout = () => {
    dispatch(resetUser());
    dispatch(resetSubcription());

    localStorage.clear();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return { handleLogout };
};

export default useLogout;
