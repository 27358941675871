import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import { Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import TextField from 'components/FormElements/AppTextInput';

import {
  getSubscription,
  deleteSubscription,
} from '@redux/actions/Subscription';
import AlertDialog from 'components/AlertDialog';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import { CustomTheme } from 'theme/themeColors';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { dateToLabel } from 'further-ui/utils';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
    textAlign: 'left',
  },
  tableMt: {
    marginTop: 10,
  },
}));

const Subscription = () => {
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Subscription', isActive: true },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const { subscritionData, loading } = useSelector(
    //@ts-ignore
    ({ SubscriptionReducer }) => SubscriptionReducer,
  );

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [record, setRecord] = React.useState();
  const [deletePermission] = useGetPermissions(['delete:subscription']);

  const pagination = usePagination({ id: 'subscriptions-table' });
  const { filters, handleChangeFilters } = useFilters('subscriptions-table', {
    defaultFilters: {
      search: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const getSubscriptions = useCallback(() => {
    handleApiRequest(async () => {
      dispatch(
        getSubscription({
          page: pagination.page,
          perPage: pagination.rowsPerPage,
          type: pagination.order,
          keyName: pagination.orderBy,
          search: filters.search,
        }),
      );
    });
  }, [pagination, dispatch, filters]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };
  const handleConfirmDelete = async () => {
    await handleApiRequest(async (success) => {
      const response = await dispatch<{
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteSubscription(record._id),
      );
      if (response.data) {
        getSubscriptions();
        success(response.data.responseMsg);
      }
    });

    setOpenConfirmDialog(false);
  };

  const columns = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Date',
      key: 'createdAt',
      render: (elm) => dateToLabel(elm.createdAt),
    },
    {
      label: 'Time',
      key: 'createdAt',
      render: (elm) => (
        <>
          {elm.createdAt
            ? moment.utc(elm.createdAt).format('HH:mm:ss')
            : 'no found'}
        </>
      ),
    },
    {
      label: 'Action',
      key: '',
      sort: false,
      render: (elm) => (
        <div className={classes.dFlex}>
          {deletePermission && (
            <Button
              title="Delete"
              className={classes.customButton}
              onClick={() => handleDelete(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <PageContainer heading="Email Subscribers" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="search"
                    name="search"
                    placeholder="Search by email"
                    fullWidth
                    value={filters.search}
                    onChange={(event) => {
                      handleChangeFilters({ search: event.target.value });
                    }}
                  />
                </Grid>
              </GridContainer>
              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  onRequestSort={pagination.handleRequestSort}
                  order={pagination.order}
                  orderBy={pagination.orderBy}
                  tablebody={subscritionData?.result}
                  onPageChange={pagination.handleChangePage}
                  onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  count={subscritionData?.total}
                  pagination={true}
                  loading={loading}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={openConfirmDialog}
          onClose={handleDelete}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this subscription?`}
          content={
            <p>This cannot be undone and all information will be lost.</p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete subscription',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default Subscription;
