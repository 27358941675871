import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PageContainer from 'components/PageContainer';
import { getQuestionList } from '@redux/actions/Investor';
import { WITHDRAWALS_ADD } from 'constants/routes';
import InvestorDetails from './InvestorDetails';
import InvestorCommunication from './InvestorCommunication';
import TaxAndBankDetails from './TaxAndBankDetails';
import InvestorAppropriateness from './InvestorAppropriateness';
import InvestorCertification from './InvestorCertification';
import KycResults from './KycResults';
import CoolOffStatus from './CoolOffStatus';
import Investments from './Investments';
import InvestorUpdates from './InvestorUpdates';
import DelegatedAccessUsers from './DelegatedAccessUsers';
import CashBalanceTable from './CashBalanceTable';
import InvestorChangeLog from './InvestorChangeLog';
// pdf functions
import { downloadAppropriatenessPdf } from 'helpers/investor/downloadAppropriatenessPdf';
import { downloadCashBalance } from 'helpers/investor/downloadCashBalance';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';
import Chip from 'components/Chip';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles(() => ({
  marginCard: {
    marginTop: 10,
  },
  reviewBtn: {
    margin: '0 auto',
  },
  reviewBtnlft: {
    marginLeft: '10px',
  },
  optc: {
    minWidth: '104px',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '40px 0 30px',
  },
  heading: {
    color: '#656565',
    fontSize: 22,
  },
  headingMb: {
    marginBottom: 18,
  },
}));

const InvestorDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Investor', link: '/investor' },
    { label: 'View Investor', link: '/', isActive: true },
  ];

  const [refetch, setRefetch] = useState(false);
  const [question, setQuestion] = useState([]);

  const { fetch } = useInvestor(id);
  const investorDetail = fetch.data;
  const { bankDetails } = investorDetail || {};

  useEffect(() => {
    const getQuestion = async () => {
      let resp = await dispatch(getQuestionList());
      if (resp.status === 200) {
        setQuestion(resp.data.data);
      }
    };
    getQuestion();
  }, [dispatch]);

  const showDelegatedAccessUsers =
    investorDetail?.isEmailVerify &&
    !investorDetail?.isAccountTemporary &&
    !investorDetail?.isDisabled;

  if (fetch.isLoading) return <CircularProgress size={24} />;

  if (!investorDetail) return <Alert severity="info">Investor not found</Alert>;

  return (
    <GridContainer>
      <PageContainer
        heading="Investor: View Investor"
        breadcrumbs={breadcrumbs}
        subheaderComponent={
          investorDetail.isAccountTemporary && (
            <Tooltip title="This is a temporary account for a new investor that has been invited to make an advised investment. When the investor completes their investment, this account will become a live investor account.">
              <div>
                <Chip variant="success">Pending advised investor</Chip>
              </div>
            </Tooltip>
          )
        }
      >
        <CmtCard>
          <CmtCardContent>
            <Grid item xs={12}>
              <InvestorDetails investorDetail={investorDetail} id={id} />
              <Divider className={classes.divider} />
              <InvestorCommunication investorDetail={investorDetail} id={id} />
              <Divider className={classes.divider} />
              <TaxAndBankDetails
                bankDetails={bankDetails ?? []}
                investorDetails={investorDetail}
              />
              <Typography
                variant="h2"
                className={`${classes.heading} ${classes.headingMb}`}
              >
                Investor appropriateness and suitability
              </Typography>
              <InvestorCertification investorDetail={investorDetail} />

              <InvestorAppropriateness
                downloadQuestion={() =>
                  downloadAppropriatenessPdf(investorDetail, question)
                }
                id={id}
                investorDetail={investorDetail}
                refetchInvestor={() => setRefetch(!refetch)}
              />
              <CoolOffStatus id={id} />
              <Divider className={classes.divider} />
              <KycResults
                investorId={id}
                advisersList={investorDetail?.adviserDetail ?? []}
                fullName={investorDetail?.fullName}
                identityVerificationCertificates={
                  investorDetail?.identityVerificationCertificate
                }
                accountType={investorDetail?.accountType}
              />
              <Divider className={classes.divider} />
              <Investments investorId={id} />
              {investorDetail.cashBalances?.length > 0
                ? investorDetail.cashBalances.map((cashBalance, index) => (
                    <Fragment key={index}>
                      <div
                        className={`mb-6 mt-6 ${classes.title} ${classes.flexSpaceBetween}`}
                      >
                        <Typography variant="h2" className={classes.heading}>
                          Cash Balance - {cashBalance.firm?.firmName}
                        </Typography>
                        <div>
                          <Link to={`${WITHDRAWALS_ADD}?investorId=${id}`}>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Process withdrawal
                            </Button>
                          </Link>
                          {cashBalance.ledger.length ? (
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.reviewBtnlft}
                              onClick={() =>
                                downloadCashBalance(cashBalance, investorDetail)
                              }
                            >
                              Export
                            </Button>
                          ) : null}
                        </div>
                      </div>
                      <div className={classes.marginCard}>
                        <CashBalanceTable cashBalance={cashBalance} />
                      </div>
                    </Fragment>
                  ))
                : null}
              <div className={classes.divider}></div>
              <Typography
                variant="h2"
                className={`${classes.heading} ${classes.headingMb}`}
              >
                Investor updates
              </Typography>
              <InvestorUpdates
                investorId={id}
                investorDetail={investorDetail}
              />
              {showDelegatedAccessUsers && (
                <DelegatedAccessUsers
                  investorId={id}
                  headerClassName={`${classes.heading} ${classes.headingMb}`}
                />
              )}
              <InvestorChangeLog
                investorId={id}
                headerClassName={`${classes.heading} ${classes.headingMb}`}
              />
            </Grid>
            <Divider className={classes.divider} />
            <Notes relationId={id} noteRelation={NoteRelation.Investor} />
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </GridContainer>
  );
};
export default InvestorDetail;
