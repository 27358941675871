import { Controller, useFormContext } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import Switch from 'components/Switch';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import Dropzone from 'components/Dropzone';

const GeneralInformation = ({
  control,
  errors,
  firm,
  classes,
  handleFileUpload,
}) => {
  const [showExcelFilesPasswordField, setShowExcelFilesPasswordField] =
    useState(false);
  const { setValue } = useFormContext();

  const [summaryAttachmentFilePreviews, setSummaryAttachmentFilePreviews] =
    useState(firm.data?.summaryAttachments?.map(({ fileName }) => fileName));

  return (
    <>
      <FieldRow title="Firm name*" centerTitle>
        <Controller
          name="firmName"
          defaultValue={firm.data?.firmName}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. Acme Ventures"
              fullWidth
            />
          )}
        />
        <p className={classes.errorText}>{errors.firmName?.message}</p>
      </FieldRow>

      <FieldRow title="Key contact name*" centerTitle>
        <Controller
          name="keyContactName"
          defaultValue={firm.data?.keyContactName}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Who is your firm's main user of Further?"
              fullWidth
            />
          )}
        />
        <p className={classes.errorText}>{errors.keyContactName?.message}</p>
      </FieldRow>

      <FieldRow title="Key contact email address*" centerTitle>
        <Controller
          name="keyContactEmail"
          defaultValue={firm.data?.keyContactEmail}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Please enter their email address"
              fullWidth
            />
          )}
        />
        <p className={classes.errorText}>{errors.keyContactEmail?.message}</p>
      </FieldRow>

      <FieldRow title="Secondary contact name" centerTitle>
        <Controller
          name="secondaryContactName"
          defaultValue={firm.data?.secondaryContactName ?? undefined}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Who is your firm's secondary user of Further?"
              fullWidth
            />
          )}
        />
        <p className={classes.errorText}>
          {errors.secondaryContactName?.message}
        </p>
      </FieldRow>

      <FieldRow title="Secondary contact email address" centerTitle>
        <Controller
          name="secondaryContactEmail"
          defaultValue={firm.data?.secondaryContactEmail ?? undefined}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Please enter their email address"
              fullWidth
            />
          )}
        />
        <p className={classes.errorText}>
          {errors.secondaryContactEmail?.message}
        </p>
      </FieldRow>

      <FieldRow
        title="Summary email attachment (PDF only)"
        informationText="Any file added here will be attached to your daily investment summary emails. This attachment option can be used for daily AML sign off letters or other required confirmation files"
      >
        <Controller
          name="summaryAttachments"
          defaultValue={firm.data?.summaryAttachments}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Dropzone
              {...rest}
              maxFiles={10}
              onDropFile={async (files) => {
                await handleFileUpload(files, (fileName, filePath) => {
                  if (!value) {
                    setValue('summaryAttachments', [{ fileName, filePath }]);
                    setSummaryAttachmentFilePreviews([fileName]);
                  } else {
                    setValue('summaryAttachments', [
                      ...value,
                      { fileName, filePath },
                    ]);

                    setSummaryAttachmentFilePreviews([
                      ...summaryAttachmentFilePreviews,
                      fileName,
                    ]);
                  }
                });
              }}
              accept={{ 'application/pdf': ['.pdf'] }}
              files={
                summaryAttachmentFilePreviews
                  ? summaryAttachmentFilePreviews?.map((fileName) => ({
                      preview: fileName,
                      fileName,
                    }))
                  : []
              }
              onRemove={(index) => {
                const newFiles = value.filter((_, i) => i !== index);
                const newFilePreview = summaryAttachmentFilePreviews.filter(
                  (_, i) => i !== index,
                );
                setValue('summaryAttachments', newFiles);
                setSummaryAttachmentFilePreviews(newFilePreview);
              }}
            />
          )}
        />
        <p className={classes.errorText}>
          {errors.summaryAttachments?.message}
        </p>
      </FieldRow>

      <FieldRow
        title="System email recipient"
        informationText="Separate emails by a semi-colon"
      >
        <Controller
          name="systemEmailRecipient"
          defaultValue={firm.data?.systemEmailRecipient ?? undefined}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter the email addresses you wish to be copied in to system emails, such as investment confirmations sent to investors. Due to a potential large volume of emails, we recommend personal email addresses are not used. Please separate each email with a semi-colon. e.g. 'a@a.com;b@b.com'"
              fullWidth
              multiline
              minRows={4}
            />
          )}
        />
        <p className={classes.errorText}>
          {errors.systemEmailRecipient?.message}
        </p>
      </FieldRow>

      <FieldRow title="Password protect spreadsheets sent in emails?">
        <Controller
          name="excelFilesPassword"
          defaultValue={firm.data?.excelFilesPassword ?? undefined}
          control={control}
          render={({ field: { ref, value, ...rest } }) => (
            <Box display="flex" gridGap={16} flexDirection="column">
              <div>
                <span className={classes.switchLabel}>No</span>
                <Switch
                  checked={showExcelFilesPasswordField || !!value}
                  onChange={(_, checked) => {
                    setShowExcelFilesPasswordField(checked);
                    if (!checked) {
                      setValue('excelFilesPassword', null);
                    }
                  }}
                />
                <span className={classes.switchLabel}>Yes</span>
              </div>
              {showExcelFilesPasswordField || value ? (
                //@ts-ignore JSX element class does not support attributes because it does not have a 'props' property
                <AppPasswordInput
                  {...rest}
                  inputRef={ref}
                  value={value}
                  fullWidth
                />
              ) : null}
            </Box>
          )}
        />
        <p className={classes.errorText}>
          {errors.excelFilesPassword?.message}
        </p>
      </FieldRow>
    </>
  );
};

export default GeneralInformation;
