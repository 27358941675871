import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from 'components/CmtImage';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  '@media screen and (max-width: 520px)': {
    micon: {
      width: '102px',
    },
  },
});
const Logo = ({ color, ...props }) => {
  const logoUrl = '/images/logo.png';
  const classes = useStyles();

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage
            src={logoUrl}
            alt="logo"
            className={classes.micon}
            style={{ maxWidth: '100px' }}
          />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage
            src={logoUrl}
            alt="logo"
            className={classes.micon}
            style={{ maxWidth: '100px' }}
          />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
