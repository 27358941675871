import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CardHeader from 'components/CardHeader';
import { useCompanies } from 'hooks/data/company/useCompanies';
import { getCurrentSharePrice } from 'helpers/company/getCurrentSharePrice';
import { numberToCurrencyString } from 'further-ui/utils';
import { useMutateShareSplit } from 'hooks/data/company/useShareSplits';
import { createCompanyEditRoute } from 'constants/routes';
import DetailsStep from './DetailsStep';
import SummaryStep from './SummaryStep';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';

const steps = {
  DETAILS: 0,
  SUMMARY: 1,
};

const useStyles = makeStyles(() => ({
  buttonAlignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

const AddShareSplit = () => {
  const history = useHistory();
  const handleApiRequest = useApiRequestHandler();
  const { companyId } = useParams();
  const [step, setStep] = useState(steps.DETAILS);
  const [shareSplitFactor, setShareSplitFactor] = useState(null);
  const [shareSplitDate, setShareSplitDate] = useState(null);
  const classes = useStyles();
  const { company } = useCompanies({
    params: { companyId },
  });
  const { createShareSplit } = useMutateShareSplit({ params: { companyId } });

  useEffect(() => {
    setStep(steps.DETAILS);
  }, [companyId]);

  const currentSharePrice = getCurrentSharePrice(company.data);
  const currentSharePriceDisplay = numberToCurrencyString(currentSharePrice, {
    unlimitedDp: true,
  });
  // This prevents an issue with JS where it cannot accurately represent some
  // decimal numbers. For example, 2.9 / 100 = 0.028999999999999998. In this
  // case we can assume that a share price will never need to be more than 13
  // decimal places, so anything above that is a maths error that can be rounded out.
  const newSharePrice = Number(
    (currentSharePrice / Number(shareSplitFactor)).toFixed(13),
  );
  const newSharePriceDisplay = numberToCurrencyString(newSharePrice, {
    unlimitedDp: true,
  });

  const onSubmit = async () => {
    const data = {
      companyId,
      date: moment(shareSplitDate).format('DD/MM/YYYY'),
      shareSplitFactor,
      sharePricePreSplit: currentSharePrice,
      sharePricePostSplit: newSharePrice,
    };

    await handleApiRequest(async (success) => {
      if (createShareSplit.isLoading) return;
      const response = await createShareSplit.mutateAsync({ data });
      success(response?.responseMsg);
      history.push(createCompanyEditRoute(companyId));
    });
  };

  return (
    <PageContainer heading="Update company: Split shares">
      <CmtCard>
        <CmtCardContent>
          <CardHeader title="Split shares" />
          {step === steps.DETAILS && (
            <DetailsStep
              company={company}
              shareSplitDate={shareSplitDate}
              setShareSplitDate={setShareSplitDate}
              shareSplitFactor={shareSplitFactor}
              setShareSplitFactor={setShareSplitFactor}
              steps={steps}
              setStep={setStep}
              currentSharePriceDisplay={currentSharePriceDisplay}
              classes={classes}
            />
          )}

          {step === steps.SUMMARY && (
            <SummaryStep
              company={company}
              shareSplitDate={shareSplitDate}
              shareSplitFactor={shareSplitFactor}
              currentSharePriceDisplay={currentSharePriceDisplay}
              newSharePriceDisplay={newSharePriceDisplay}
              onSubmit={onSubmit}
              onBack={() => setStep(steps.DETAILS)}
              classes={classes}
            />
          )}
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddShareSplit;
