import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { Grid, Button, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';

import { getPartiallyInvestment } from '@redux/actions/Investment';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import { usePagination } from 'hooks/ui/usePagination';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  exportBtn: {
    textAlign: 'right',
  },
  customWidth: {
    minWidth: 150,
    backgroundColor: '#f3f1ef',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperChild: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    minWidth: '150px',
  },
  marginTable: {
    marginTop: 20,
  },

  dialogRootUnitCost: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '400px',
      maxWidth: '400px',
    },
  },
  '@media screen and (max-width: 767px)': {
    dialogRootUnitCost: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: '95%',
        maxWidth: '95%',
      },
    },
  },
}));

const exitedAtToLabel = (cart: { step?: number; adviserId?: string }) => {
  const { step, adviserId } = cart;

  const steps = {
    1: 'Appropriateness (1/5)',
    2: 'KYC (2/5)',
    3: 'Bank and tax details (3/5)',
    4: 'Investment selection (4/5)',
    5: 'Manual payment (5/5)',
    6: 'Open Banking (5/5)',
  };

  return step ? steps[step] ?? '' : adviserId ? 'Invited, no action taken' : '';
};

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Incomplete Investments', isActive: true },
];
// sorting not working
const columns = [
  {
    label: 'Investor name',
    key: 'fullName',
  },
  {
    label: 'Email',
    key: 'email',
    render: (elm) => elm?.investorId?.email,
  },
  {
    label: 'Firm',
    key: 'firmId',
    render: (elm) => elm?.firmId?.firmName,
  },
  {
    label: 'Tranche',
    key: 'fundId',
    render: (elm) => elm?.fundId?.fundName,
  },
  {
    label: 'Date',
    key: 'createdAt',
    render: (elm) => dateToLabel(elm?.createdAt),
  },
  {
    label: 'Exited',
    key: 'step',
    render: (elm) => exitedAtToLabel(elm),
  },
  {
    label: 'Advised',
    key: 'adviserId',
    render: (elm) => (elm?.adviserId ? 'Yes' : 'No'),
  },
  {
    label: 'Investment',
    key: 'investmentAmount',
    render: (elm) => numberToCurrencyString(elm?.investmentAmount),
  },
];

const useFundId = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).get('fundId'), [search]);
};

const Investment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const fundId = useFundId();
  const [partiallyInvestment, setPartiallyInvestment] = useState({
    result: [],
    totalInvestment: null,
    total: 0,
  });
  const { firmId } = useGetRole();
  const {
    tranches: { data: tranchesList },
    //@ts-ignore
  } = useFunds({ firmId });

  const [loading, setLoading] = useState(true);

  const {
    orderBy,
    order,
    page,
    rowsPerPage,
    toFirstPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination({
    id: 'incomplete-investments-table',
    orderBy: 'createdAt',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters(
    'incomplete-investments-table',
    {
      defaultFilters: {
        firmId,
        fundId,
        investorName: null,
      },
      onFiltersChange: toFirstPage,
    },
  );

  useEffect(() => {
    const getList = async () => {
      const response = await dispatch(
        getPartiallyInvestment({
          page,
          firmId: filters.firmId,
          fundId: filters.fundId,
          perPage: rowsPerPage,
          type: order,
          keyName: orderBy,
          investorName: filters.investorName,
        }),
      );
      if (response) {
        //@ts-ignore
        setPartiallyInvestment(response);
      }
      setLoading(false);
    };
    handleApiRequest(getList);
  }, [dispatch, page, rowsPerPage, order, orderBy, filters]);

  const exportExcelSheet = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Incomplete investments');
    worksheet.columns = [
      { header: 'Investor name', key: 'fullName' },
      { header: 'Email', key: 'email' },
      { header: 'Firm', key: 'firmName' },
      { header: 'Tranche', key: 'fundName' },
      { header: 'Total Investment Amount', key: 'investmentAmount' },
      { header: 'Exited', key: 'step' },
      { header: 'Investment Date', key: 'InvestmentDate' },
    ];
    partiallyInvestment?.totalInvestment?.forEach((item) => {
      worksheet.addRow({
        fullName: item?.fullName,
        email: item.investorId?.email,
        firmName: item.firmId?.firmName,
        fundName: item?.fundId?.fundName,
        investmentAmount: numberToCurrencyString(item.investmentAmount),
        step: exitedAtToLabel(item),
        InvestmentDate: dateToLabel(item.createdAt),
      });
    });
    workbook.xlsx.writeBuffer().then((buffer: Buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Incomplete investments.xlsx',
      );
    });
  };

  return (
    <PageContainer heading="Incomplete Investments" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={3}>
                  <TextField
                    type="search"
                    name="searchInvestorsByName"
                    value={filters.investorName}
                    placeholder="Search by investor name"
                    onChange={(event) => {
                      handleChangeFilters({
                        investorName: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    //@ts-ignore
                    id="fundId"
                    value={tranchesList?.find(
                      ({ _id }) => _id === filters.fundId,
                    )}
                    options={tranchesList ?? []}
                    getOptionLabel={(option) =>
                      `${option?.firmId?.firmName} ${option?.fundName}`
                    }
                    onChange={(_, newValue) => {
                      handleChangeFilters({ fundId: newValue?._id });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select tranche"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.exportBtn}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!partiallyInvestment?.totalInvestment?.length}
                    onClick={exportExcelSheet}
                  >
                    Export
                  </Button>
                </Grid>
              </GridContainer>
              <div className={classes.marginTable}>
                <Table
                  columns={columns}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  tablebody={partiallyInvestment?.result}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={partiallyInvestment?.total ?? 0}
                  pagination={true}
                  loading={loading}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Investment;
