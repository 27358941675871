import { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import globalStyles from 'theme/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { userInformation } from '@redux/actions/Auth';
import VerticalLayout from './VerticalLayout';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  globalStyles();

  useEffect(() => {
    dispatch(userInformation(JSON.parse(localStorage.getItem('user'))));
    setLayoutLoader(false);
  }, []);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  return <VerticalLayout children={children} />;
};

export default AppLayout;
