import React from 'react';
import clsx from 'clsx';

import { useMediaQuery, useTheme } from '@material-ui/core';

import useStyles from './styles';
import { useSidebarThemeContext } from 'contexts/SidebarThemeContext';
import { useLayoutContext } from 'contexts/LayoutContext';
import SidebarInner from './SidebarInner';
import SidebarHeader from './SidebarHeader';
import DrawerContent from './DrawerContent';
import SidebarContent from './DrawerContent/SidebarContent';

const Sidebar = ({ actionBar }) => {
  const {
    drawerBreakPoint,
    sidebarWidth,
    miniSidebarWidth,
    actionSidebarWidth,
    sidebarType,
  } = useLayoutContext();

  const { sidebarTheme } = useSidebarThemeContext();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(drawerBreakPoint));

  const hasDrawer = sidebarType === 'drawer' || matches;

  const classes = useStyles({
    sidebarWidth,
    miniSidebarWidth,
    actionSidebarWidth,
    sidebarTheme,
  });

  if (hasDrawer) {
    return (
      <React.Fragment>
        {actionBar}
        <DrawerContent classes={classes}>
          <SidebarHeader />
          <SidebarInner />
        </DrawerContent>
      </React.Fragment>
    );
  } else if (actionBar) {
    return (
      <div
        className={clsx(
          classes.actionSidebarWrapper,
          'Cmt-actionSidebarWrapper',
        )}
      >
        {actionBar}
        <SidebarContent classes={classes}>
          <SidebarHeader />
          <SidebarInner />
        </SidebarContent>
      </div>
    );
  } else {
    return (
      <SidebarContent classes={classes}>
        <SidebarHeader />
        <SidebarInner />
      </SidebarContent>
    );
  }
};

export default Sidebar;
