import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';
import useStyles from './styles';
import { useHistory } from 'react-router';
import ErrorIcon from '@material-ui/icons/Error';

type ErrorProps = {
  message: string;
};

const Error: React.FC<ErrorProps> = ({ message }) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gridGap={theme.spacing(4)}
    >
      <div className={classes.errorMessageIcon}>
        <ErrorIcon fontSize="large" htmlColor={theme.palette.text.error} />
      </div>
      <Box maxWidth="600px">
        <Typography variant="h6" color="textSecondary" align="center">
          {message}
        </Typography>
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => history.goBack()}
      >
        Go back
      </Button>
    </Box>
  );
};

export default Error;
