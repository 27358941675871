import { FC } from 'react';
import { useParams } from 'react-router';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { CircularProgress } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';

import { useFetchOrganisation } from 'hooks/data/organisation/useOrganisation';
import OrganisationDetails from './OrganisationDetails';
import LinkedAdvisers from './LinkedAdvisers';
import LinkedInvestments from './LinkedInvestments';
import OrganisationUsers from './OrganisationUsers';

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Organisation', link: '/organisation' },
  {
    label: 'View Organisation',
    link: '/',
    isActive: true,
  },
];

const ViewOrganisation: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isFetching } = useFetchOrganisation({ id });

  return (
    <>
      <PageContainer
        heading="Organisation: View organisation"
        breadcrumbs={breadcrumbs}
      >
        <CmtCard>
          <CmtCardContent>
            {isFetching ? (
              <GridContainer
                justifyContent="center"
                style={{ marginTop: '2rem' }}
              >
                <CircularProgress />
              </GridContainer>
            ) : (
              <OrganisationDetails organisation={data?.organisation} />
            )}
            {data?.organisation && (
              <>
                <OrganisationUsers organisationId={data.organisation?._id} />
                <LinkedAdvisers organisation={data.organisation} />
                <LinkedInvestments organisation={data.organisation} />
              </>
            )}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </>
  );
};
export default ViewOrganisation;
