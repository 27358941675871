import CmtCard from 'components/CmtCard';
import PageContainer from 'components/PageContainer';
import useInvestmentTransfers from 'hooks/data/investmentTransfer/useInvestmentTransfers';
import { Box, Grid, IconButton } from '@material-ui/core';

import Error from '../Common/Error';
import useStyles from './styles';
import Table from 'components/Table';
import { Api } from 'further-types/investmentTransfer';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { CloudDownload, Delete } from '@material-ui/icons';
import useInvestmentTransfer from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import Filters from './Filters';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { createInvestorViewRoute } from 'constants/routes';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import AlertDialog from 'components/AlertDialog';

const headingText = 'Investors: Transfer record';

type InvestmentTransfer = Api.GetInvestmentTransfersResponse['result'][number];

const getColumns = (
  onDownload: (investmentTransferId: string) => void,
  onDelete: (investmentTransferId: string) => void,
  isDownloading: boolean = false,
) => [
  {
    label: 'Transferred from',
    key: 'sourceInvestorFullName',
    render: (row: InvestmentTransfer) => (
      <Link to={createInvestorViewRoute(row.sourceInvestor.id)}>
        {row.sourceInvestor.fullName}
      </Link>
    ),
  },
  {
    label: 'Transferred to',
    key: 'recipientFullName',
    render: (row: InvestmentTransfer) => (
      <Link to={createInvestorViewRoute(row.recipient.id)}>
        {row.recipient.fullName}
      </Link>
    ),
  },
  {
    label: 'Date of transfer',
    key: 'createdAt',
    render: (row: InvestmentTransfer) => {
      return dateToLabel(row.createdAt);
    },
  },
  {
    label: 'Shares transferred',
    key: 'sharesTransferred',
    sort: false,
  },
  {
    label: 'Cash transferred',
    sort: false,
    render: (row: InvestmentTransfer) => {
      return numberToCurrencyString(row.cashTransferred);
    },
  },
  {
    label: 'Actions',
    sort: false,
    render: (row: InvestmentTransfer) => (
      <Box>
        <IconButton
          title="Download"
          disabled={isDownloading}
          onClick={() => onDownload(row.id)}
        >
          <CloudDownload color="primary" />
        </IconButton>
        <IconButton title="Download" onClick={() => onDelete(row.id)}>
          <Delete color="error" />
        </IconButton>
      </Box>
    ),
  },
];

const InvestmentTransferList: React.FC = () => {
  const classes = useStyles();
  const exceptionHandler = useApiExceptionHandler();
  const [isBulkExportInProgress, setIsBulkExportInProgress] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [selectedTransferId, setSelectedTransferId] = useState<string>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedInvestmentTransfersIds, setSelectedInvestmentTransfersIds] =
    useState<Array<string>>([]);

  const [isDownloading, setIsDownloading] = useState(false);

  const { exportInvestmentTransfer, deleteInvestmentTransfer } =
    useInvestmentTransfer();
  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination({
    id: 'investment-transfers',
    orderBy: 'createdAt',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('investment-transfers', {
    defaultFilters: {
      search: null,
    },
  });
  const {
    investmentTransfers: { data, isFetching, isLoading, error },
    exportInvestmentTransfers,
  } = useInvestmentTransfers({
    page,
    rowsPerPage,
    order,
    orderBy,
    ...filters,
  });

  if (error) {
    return (
      <PageContainer heading={headingText}>
        <CmtCard className={classes.container}>
          <Error message="Error when fetching investment transfers." />
        </CmtCard>
      </PageContainer>
    );
  }

  const handleDownload = async (investmentTransferId: string) => {
    try {
      setIsDownloading(true);
      await exportInvestmentTransfer(investmentTransferId);
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      exceptionHandler(e, 'Error when exporting investment transfer', true);
    }
  };

  const handleBulkExport = async () => {
    try {
      setIsBulkExportInProgress(true);
      await exportInvestmentTransfers(selectedInvestmentTransfersIds);
    } catch (e) {
      exceptionHandler(e, 'Error when exporting investment transfers', true);
    } finally {
      setIsBulkExportInProgress(false);
    }
  };

  const handleDeleteButtonClick = (investmentTransferId: string) => {
    setSelectedTransferId(investmentTransferId);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    setShowDeleteConfirmation(false);
    try {
      const result = await deleteInvestmentTransfer({
        investmentTransferId: selectedTransferId,
      });
      if (!result.data.success) {
        setShowDeleteError(true);
      }
    } catch (e) {
      exceptionHandler(e);
    }
  };

  const setSelectedTransferIds = (
    selectedInvestmentTransfers: Array<InvestmentTransfer>,
  ) => {
    setSelectedInvestmentTransfersIds(
      selectedInvestmentTransfers.map(({ id }) => id),
    );
  };

  return (
    <PageContainer heading={headingText}>
      <CmtCard className={classes.container}>
        <Grid container spacing={6} direction="column">
          <Grid item xs>
            <Filters
              filters={filters}
              onFiltersChange={handleChangeFilters}
              onBulkExport={handleBulkExport}
              showBulkExportButton={!!selectedInvestmentTransfersIds?.length}
              isBulkExportInProgress={isBulkExportInProgress}
            />
          </Grid>
          <Grid item xs>
            <Table
              loading={isFetching || isLoading || !data}
              columns={getColumns(
                handleDownload,
                handleDeleteButtonClick,
                isDownloading,
              )}
              tablebody={data?.result ?? []}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSelectionChange={setSelectedTransferIds}
              page={page}
              rowsPerPage={rowsPerPage}
              count={data?.total ?? 0}
              pagination={true}
              variant="nohover"
              selectable
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              emptyMessage={'No transfers found'}
            />
          </Grid>
        </Grid>
      </CmtCard>

      <AlertDialog
        title="Can't delete transfer."
        content="As subsequent system transactions have taken place with the transferee investment or investor, this transfer cannot be deleted. For assistance, please contact support@joinfurther.com."
        open={showDeleteError}
        onClose={() => setShowDeleteError(false)}
        btnLabels={{ confirm: false, cancel: 'OK' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <AlertDialog
        title="Delete transfer"
        content="Are you sure you want to delete this transfer?"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDelete}
        btnLabels={{ confirm: 'Yes', cancel: 'No' }}
      />
    </PageContainer>
  );
};

export default InvestmentTransferList;
