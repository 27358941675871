import React from 'react';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { fundStatusToLabel, fundStatusMap } from 'constants/fundProperties';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import Box from 'components/Box';
import { alpha, Divider, Button, Grid, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.background.rootColor, 0.15),
    color: theme.palette.text.rootColor,
    padding: '5px 12px 3px',
    borderRadius: 30,
    lineHeight: '1.5',
    fontWeight: '600',
  },
  viewBtn: {
    padding: '0 !important',
    marginTop: '10px',
    textTransform: 'uppercase',
    fontSize: '12px',
    margin: 'auto',
    display: 'block',
    color: theme.palette.text.rootColor,
  },
}));

export const TrancheSnapshot = ({
  trancheSnapshot,
  trancheDetails,
  trancheId,
  classes: parentClasses,
}) => {
  const classes = useStyles();
  const isClosedTranche = trancheDetails?.fundStatus === fundStatusMap.closed;

  return (
    <CmtCard>
      <CmtCardHeader title="Tranche snapshot">
        {trancheDetails?.fundStatus > -1 && (
          <span className={classes.badgeRoot}>
            {fundStatusToLabel[trancheDetails?.fundStatus]}
          </span>
        )}
      </CmtCardHeader>
      <Divider className={parentClasses.divider} />
      <CmtCardContent>
        <GridContainer>
          <Grid item xs={6}>
            <Box
              title={numberToCurrencyString(
                trancheSnapshot.sumInvestmentAmount,
                { hidePenniesIfInteger: true },
              )}
              subtitle="Investments"
            >
              <Link to={`/investment?fundId=${trancheId}`}>
                <Button className={classes.viewBtn}>View</Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box title={trancheSnapshot.sumInvestors} subtitle="Investors">
              <Link to={`/investor?fundId=${trancheId}`}>
                <Button className={classes.viewBtn}>View</Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              title={dateToLabel(trancheSnapshot.firstInvestmentDate)}
              subtitle="First investment date"
            ></Box>
          </Grid>
          <Grid item xs={6}>
            {isClosedTranche ? (
              <Box
                title={dateToLabel(trancheDetails?.closedDate)}
                subtitle="Tranche close date"
              ></Box>
            ) : (
              <Box
                title={dateToLabel(trancheDetails?.finishDate)}
                subtitle="Scheduled close date"
              ></Box>
            )}
          </Grid>
        </GridContainer>
      </CmtCardContent>
    </CmtCard>
  );
};
