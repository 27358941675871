import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchDashboard({ queryKey }) {
  const [, params] = queryKey;

  const url = params.firmId
    ? `dashboard/firms/?firmId=${params.firmId}`
    : `dashboard/firms`;

  try {
    const { data } = await api.get(url);

    return data.data;
  } catch (error) {
    throw error;
  }
}

export function useFirmDashboard({ params }) {
  return useQuery({
    queryKey: ['firmDashboard', params],
    queryFn: fetchDashboard,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}
