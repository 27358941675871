import React, { cloneElement, isValidElement } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useLogout from 'hooks/ui/useLogout';
import { useSidebarThemeContext } from 'contexts/SidebarThemeContext';

const useStyles = makeStyles((theme) => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  activeClass: {
    color: '#56B26C !important',
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: (props) => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      // color: props => props.sidebarTheme.textDarkColor,

      color: theme.palette.text.rootColor,

      backgroundColor: (props) => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        // color: props => props.sidebarTheme.textDarkColor,
        color: theme.palette.text.rootColor,
      },
    },
    '&.active': {
      // color: props => props.sidebarTheme.textActiveColor,
      // backgroundColor: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: (props) => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: (props) => props.sidebarTheme.textActiveColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    paddingTop: '5px',
    fontWeight: '600',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
  cursor: {
    cursor: 'pointer',
  },
}));

const NavMenuItem = (props) => {
  const { name, icon, link = '', isLogout } = props;
  const history = useHistory();
  const { sidebarTheme } = useSidebarThemeContext();
  const classes = useStyles({ sidebarTheme });
  const { handleLogout } = useLogout();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }
    return null;
  };

  let activeFlag = false;
  if (
    history.location.pathname === link ||
    `${history.location.pathname}${history.location.search}` === link
  ) {
    activeFlag = true;
  }

  return (
    <List
      component="div"
      className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}
    >
      {isLogout ? (
        <div
          onClick={handleLogout}
          className={clsx(
            classes.navMenuLink,
            classes.cursor,
            'Cmt-nav-menu-link',
          )}
        >
          {renderIcon()}
          <span className={clsx(classes.navText)}>{name}</span>
        </div>
      ) : link ? (
        <NavLink
          className={clsx(
            classes.navMenuLink,
            activeFlag ? classes.activeClass : '',
            'Cmt-nav-menu-link',
          )}
          to={link}
        >
          {renderIcon()}
          <span className={clsx(classes.navText)}>{name}</span>
        </NavLink>
      ) : null}
    </List>
  );
};

export default NavMenuItem;
