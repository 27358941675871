import { Grid } from '@material-ui/core';
import SearchField from 'components/SearchField';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { makeStyles } from '@material-ui/core/styles';

type FiltersType = {
  search?: string;
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  filters: FiltersType;
  onFiltersChange: (filters: FiltersType) => void;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
}));

const BulkPaymentAssignmentFilters: React.FC<Props> = ({
  filters,
  onFiltersChange,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      spacing={6}
      className={classes.container}
    >
      <Grid item xs md={4}>
        <SearchField
          variant="outlined"
          placeholder="Search by email, investment ID or investor name"
          allowEmptyQuery
          autoSearchOnDebounce
          onSearch={(search: string) => onFiltersChange({ search })}
          fullWidth
        />
      </Grid>
      <Grid item xs md={4}>
        <MuiDateRangePicker
          dateRange={{ startDate: filters.startDate, endDate: filters.endDate }}
          onDateRangeChange={({ startDate, endDate }) =>
            onFiltersChange({ startDate, endDate })
          }
          TextFieldProps={{ placeholder: 'Filter by investment date' }}
        />
      </Grid>
    </Grid>
  );
};

export default BulkPaymentAssignmentFilters;
