import clsx from 'clsx';
import { useSidebarThemeContext } from 'contexts/SidebarThemeContext';

const SidebarContent = ({ children, classes }) => {
  const { backgroundStyle, overlayStyle } = useSidebarThemeContext();

  return (
    <div className={clsx(classes.appSidebar, 'Cmt-sidebar')}>
      <div
        className={clsx(classes.appSidebarContent, 'Cmt-sidebar-content')}
        style={backgroundStyle}
      >
        {children}
        {overlayStyle && (
          <div
            className={clsx(classes.overlayRoot, 'Cmt-Drawer-overlay')}
            style={overlayStyle}
          />
        )}
      </div>
    </div>
  );
};

export default SidebarContent;
