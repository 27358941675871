import { Grid, Button, makeStyles, CircularProgress } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { Tooltip } from 'components/Tooltip';
import { useTags } from 'hooks/data/tag/useTags';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    height: '100%',
    paddingBottom: 2,
  },
  actionButton: {
    marginRight: 8,
  },
  tooltipIcon: {
    fontSize: '18px',
    cursor: 'pointer',
    transform: 'translateY(3px)',
    marginTop: '0.4rem',
    marginLeft: '-1rem',
  },
}));

const TranchesFilters = ({
  filters,
  onFiltersChange,
  onExportClick,
  isExportLoading,
}) => {
  const classes = useStyles();

  const { tags } = useTags({ name: 'EIS' });

  return (
    <GridContainer>
      <Grid item md={3} xs={12}>
        <TextField
          required
          name="fundName"
          placeholder="Search by tranche name"
          value={filters?.fundName}
          fullWidth
          onChange={(event) => {
            onFiltersChange({
              fundName: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        {
          <AppAutocomplete
            //@ts-ignore
            name="tagId"
            options={tags?.data ?? []}
            getOptionLabel={(option) => option?.name}
            filterSelectedOptions
            value={filters?.tagId}
            onChange={(_, newValue) => {
              onFiltersChange({
                tagId: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Fund type"
              />
            )}
          />
        }
      </Grid>
      <Grid item md={4} xs={12}>
        <GridContainer>
          <Grid item md={10} xs={12}>
            <AppDatePicker
              placeholder="Select snapshot date"
              name="snapshotDate"
              value={filters.snapshotDate}
              onChange={(date) => {
                onFiltersChange({
                  snapshotDate: moment(date).endOf('day').toDate(),
                });
              }}
              disableFuture={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Tooltip
              title={
                <>
                  Use the date picker to view this table on any date in the
                  past. <em>Invested capital</em>, <em>Uninvested capital</em>{' '}
                  and <em>Current value</em> will change to reflect their
                  historic values as at the selected date.
                </>
              }
              placement="top"
            >
              <InfoOutlined className={classes.tooltipIcon} />
            </Tooltip>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item md={2} xs={12}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={onExportClick}
            disabled={isExportLoading}
          >
            {isExportLoading && (
              <CircularProgress
                style={{
                  color: 'rgba(0, 0, 0, 0.26)',
                  marginRight: 10,
                }}
                size={18}
              />
            )}
            Export
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
};

export default TranchesFilters;
