import Header from './Header';
import SideBar from './Sidebar';

import defaultContext from 'contexts/AppContext/defaultContext';
import LayoutContextProvider from 'contexts/LayoutContext';
import SidebarThemeContextProvider from 'contexts/SidebarThemeContext';
import MainContainer from './MainContainer';
import CmtContent from './Content';

const layoutOptions = {
  headerType: defaultContext.headerType,
  footerType: 'fixed',
  sidebarType: defaultContext.sidebarType,
  isSidebarFixed: defaultContext.isSidebarFixed,
  isSidebarOpen: false,
  showTourOpt: true,
  showFooterOpt: true,
  miniSidebarWidth: 80,
  layoutStyle: defaultContext.layoutType,
  drawerBreakPoint: defaultContext.drawerBreakPoint,
  sidebarWidth: defaultContext.sidebarWidth,
};

const VerticalDefault = ({ children }) => {
  return (
    <LayoutContextProvider {...layoutOptions}>
      <SidebarThemeContextProvider>
        <MainContainer
          header={<Header />}
          sidebar={<SideBar />}
          footer={null}
          className="verticalDefaultLayout"
        >
          <CmtContent>{children}</CmtContent>
        </MainContainer>
      </SidebarThemeContextProvider>
    </LayoutContextProvider>
  );
};

export default VerticalDefault;
