import { THEME_TYPES } from 'constants/themeOptions';
import { Theme } from '@material-ui/core';

export const semiDarkTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#56B26C',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#56B26C',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
      rootColor: '#56B26C',
      header: '#FBFBFA',
      danger: '#D71C1C',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
      rootColor: '#56B26C',
      tableText: '#212121',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
      white: '#fff',
      rootColor: '#56B26C',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
};
export const darkTheme = {
  palette: {
    type: THEME_TYPES.DARK,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#56B26C',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#56B26C',
      menuHoverBgColor: 'rgb(0, 0, 0, 0.8)',
      menuActiveBgColor: 'rgb(0, 0, 0, 0.5)',
    },
    common: {
      dark: '#fff',
    },
    background: {
      paper: '#121212',
      default: '#2e2e2e',
      rootColor: '#56B26C',
      header: '#FBFBFA',
      danger: '#D71C1C',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.3)',
      white: '#fff',
      rootColor: '#56B26C',
      tableText: '#212121',
    },
    borderColor: {
      main: 'rgba(255, 255, 255, 0.06)',
      dark: 'rgba(255, 255, 255, 0.12)',
      white: '#fff',
      rootColor: '#56B26C',
    },
    lightBtn: {
      bgColor: '#535151',
      textColor: 'rgba(255, 255, 255, 0.38)',
    },
    popupColor: {
      main: '#363636',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#535050',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#2e2e2e',
      },
    },
  },
};
export const lightTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#56B26C',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#56B26C',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F4F1EF',
      rootColor: '#56B26C',
      header: '#FBFBFA',
      danger: '#D71C1C',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
      red: '#ee0432',
      green: '#56B26C',
      rootColor: '#56B26C',
      error: '#E00930',
      tableText: '#212121',
      txtLight: 'rgba(0, 0, 0, 0.60)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
      white: '#fff',
      rootColor: '#56B26C',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
        minHeight: 37,
        padding: '1px 12px 0 !important',
      },
      input: {
        padding: '0 !important',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#56B26C',
      },
    },
    MuiInputBase: {
      input: {
        letterSpacing: '0',
        fontSize: 14,
      },
    },
    MuiFormControlLabel: {
      label: {
        letterSpacing: '0',
        fontSize: 14,
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#56B26C',
        },
        '&$active': {
          color: '#56B26C',
        },
      },
      active: {},
      completed: {},
    },
  },
};
export type CustomTheme = typeof lightTheme & Theme;
