import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  mailto: {
    color: theme.palette.text.green,
    fontWeight: 'bold',
  },
}));

type Props = {
  disabled?: boolean;
  downloadOnly?: boolean;
  hasError?: boolean;
  isLoading: boolean;
  onExport: () => void;
};

const ExportButton: React.FC<Props> = ({
  disabled,
  downloadOnly,
  hasError,
  isLoading,
  onExport,
}) => {
  const classes = useStyles();

  if (!downloadOnly && hasError) {
    return (
      <Alert severity="error">
        We were unable to update the status of the withdrawal requests in the
        system. They will still show as requests.{' '}
        <strong>
          If a file downloaded, please don't sent this file to your custodian.
        </strong>{' '}
        Instead, please refresh the page and try again, or contact{' '}
        <a className={classes.mailto} href="mailto:support@joinfurther.com">
          support@joinfurther.com
        </a>{' '}
        for assistance.
      </Alert>
    );
  }

  return downloadOnly ? (
    <Button
      color="primary"
      disabled={disabled || isLoading}
      variant="contained"
      onClick={onExport}
    >
      General export
    </Button>
  ) : (
    <Button
      onClick={onExport}
      disabled={disabled || isLoading}
      color="primary"
      variant="contained"
    >
      {isLoading ? (
        <>
          <CircularProgress
            size={12}
            style={{ color: 'white', marginRight: '1rem' }}
          />
          <span>Download export</span>
        </>
      ) : (
        'Download export'
      )}
    </Button>
  );
};

export default ExportButton;
