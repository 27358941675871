import React, { PropsWithChildren } from 'react';
import { CardContent, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: () => ({
    padding: 24,
  }),
}));

interface CmtCardContentInterface {
  className?: string;
  style?: object;
}

const CmtCardContent: React.FC<PropsWithChildren<CmtCardContentInterface>> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <CardContent
      className={clsx(classes.root, 'Cmt-card-content', className)}
      {...rest}
    >
      {children}
    </CardContent>
  );
};

export default CmtCardContent;
