import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './AppWrapper';
import AppContextProvider from './contexts/AppContext';
import Routes from './routes';
import axiosUtil from './services/axios';
import { Auth0Provider } from './providers/auth0Provider';

export const store = configureStore();
export const queryClient = new QueryClient();

axiosUtil.initalise(store, history);

const App: React.FunctionComponent = () => (
  <SnackbarProvider
    autoHideDuration={10000}
    maxSnack={5}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    preventDuplicate={true}
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* @ts-ignore */}
        <ConnectedRouter history={history}>
          <Auth0Provider history={history}>
            <AppContextProvider>
              <AppWrapper>
                <Switch>
                  <Routes />
                </Switch>
              </AppWrapper>
            </AppContextProvider>
          </Auth0Provider>
        </ConnectedRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </SnackbarProvider>
);

export default App;
