import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  fullWidth: {
    maxWidth: '100%',
  },
  container: {
    padding: theme.spacing(8, 10),
    '& a': {
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.rootColor,
      },
    },
  },
}));

export default useStyles;
