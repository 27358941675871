import { useState } from 'react';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import Grid from '@material-ui/core/Grid';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import NumberFormat from 'react-number-format';
import { Button, makeStyles } from '@material-ui/core';
import { createEncodedLink } from 'helpers/trancheLink/trancheLink';
import { useLocation } from 'react-router-dom';
import FullPageSuccess from 'components/FullPageSuccess';
import { useFunds } from 'hooks/data/fund/useFunds';
import { Autocomplete } from '@material-ui/lab';
import CardHeader from 'components/CardHeader';
import { CustomTheme } from 'theme/themeColors';
import { fundStatusMap } from 'constants/fundProperties';

const useStyles = makeStyles((theme: CustomTheme) => ({
  introCopy: {
    color: '#656565',
    marginBottom: 20,
    marginTop: -10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 12,
    width: '100%',
  },
  link: {
    color: theme.palette.text.green,
  },
}));

export const TrancheLink: React.FC = () => {
  const classes = useStyles();
  const [link, setLink] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState(null);

  const { state } = useLocation<{ trancheId: string }>();

  const id = state?.trancheId;
  const [selectedTrancheId, setSelectedTrancheId] = useState(id);

  const {
    tranches: { data },
    //@ts-ignore
  } = useFunds({ fundStatus: fundStatusMap.open });

  const tranchesList =
    data
      ?.map((tranche) => {
        return {
          value: tranche._id,
          label: `${tranche.firmId.firmName} ${tranche.fundName}`,
        };
      })
      ?.sort((item) => item.label?.toLowerCase()) ?? [];

  const [errors, setErrors] = useState<{
    investmentAmount: boolean;
    tranche: boolean;
  }>({ investmentAmount: false, tranche: false });

  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(link);
  };

  const onSubmit = () => {
    if (!selectedTrancheId || !investmentAmount) {
      setErrors({
        ...errors,
        tranche: !selectedTrancheId,
        investmentAmount: !investmentAmount,
      });
      return;
    }
    const tranche = data?.find((tranche) => tranche?._id === selectedTrancheId);
    setLink(createEncodedLink(tranche, investmentAmount));
  };

  const heading = 'Tranches: Bespoke investment link';
  if (link) {
    const additionalText = (
      <div>
        Your link is available here:{' '}
        <a href={link} className={classes.link}>
          {link}
        </a>
      </div>
    );

    return (
      <PageContainer heading={heading}>
        <FullPageSuccess
          title={'Your link has been successfully created'}
          additionalText={additionalText}
          proceedLink={null}
          proceedCallback={copyLinkToClipboard}
          proceedText="Copy link"
          secondaryCallback={() => {
            setLink(null);
            setInvestmentAmount(null);
          }}
          secondaryText="Create another link"
        ></FullPageSuccess>
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={heading}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <CardHeader title="Create a bespoke investment link" />
              <p className={classes.introCopy}>
                Use this section to create a bespoke investment link for any
                amount into your chosen tranche.
              </p>
              <GridContainer item md={11}>
                <FieldRow title="Tranche" centerTitle>
                  <Autocomplete
                    disableClearable
                    options={tranchesList}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      tranchesList?.find(
                        (item) => item.value === selectedTrancheId,
                      ) ?? null
                    }
                    onChange={(_event, selectedTranche) =>
                      setSelectedTrancheId(selectedTranche.value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Tranche"
                      />
                    )}
                  />
                </FieldRow>

                <FieldRow title="Investment Amount" centerTitle>
                  <NumberFormat
                    required
                    placeholder="e.g. £5,000"
                    value={investmentAmount}
                    name={'investmentAmount'}
                    customInput={TextField}
                    prefix="£"
                    type="text"
                    error={errors.investmentAmount}
                    onChange={(event) =>
                      setInvestmentAmount(event.target.value)
                    }
                    thousandSeparator
                  />
                </FieldRow>

                <div className={classes.buttonContainer}>
                  <Button
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                  >
                    Create link
                  </Button>
                </div>
              </GridContainer>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};
