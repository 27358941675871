import React, { useState, useEffect, useRef } from 'react';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { Button, MenuItem, Menu, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useNotification } from 'hooks/ui/useNotification';
import { round } from 'lodash';

const useStyles = makeStyles(() => ({
  menuItem: {
    letterSpacing: '0px',
    fontSize: '0.875rem',
  },
}));

const greyOutColumn = {
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e6e6e6' },
    bgColor: { argb: 'FF0000FF' },
  },
};
const defaultColumnStyle = {
  fill: {
    type: 'pattern',
    pattern: 'none',
  },
};

const format2dp = {
  numFmt: '£#,##0.00',
};

const noOfSharesFormat = {
  numFmt: '#,##0',
};

const wide = {
  width: 20,
};

const extraWide = {
  width: 30,
};

const commonColumnsStart = [
  {
    header: 'System shareholding ID',
    key: 'shareholdingId',
    style: { ...greyOutColumn },
    ...extraWide,
  },
  {
    header: 'External investment ID',
    key: 'externalInvestmentId',
    style: { ...greyOutColumn },
    ...wide,
  },
  {
    header: 'Investor name',
    key: 'investorName',
    style: { ...greyOutColumn },
    ...wide,
  },
  { header: 'Tranche', key: 'tranche', style: { ...greyOutColumn }, ...wide },
  {
    header: 'Count of shares',
    key: 'noOfShares',
    style: { ...greyOutColumn, ...noOfSharesFormat },
    ...wide,
  },
];

const commonColumnsEnd = [
  {
    header: 'Net return',
    key: 'totalToBeReturned',
    style: { ...greyOutColumn, ...format2dp },
  },
  {
    header: 'Share price',
    key: 'taxableValueSharePrice',
    style: { ...format2dp },
    ...wide,
  },
  {
    header: 'Net taxable value',
    key: 'taxableValue',
    style: { ...greyOutColumn, ...format2dp },
    ...wide,
  },
];

const createExitcolumns = [
  ...commonColumnsStart,
  {
    header: 'Shares allocated for sale',
    key: 'sharesAllocatedForSale',
    style: { ...defaultColumnStyle },
    ...wide,
  },
  {
    header: 'Total Received value',
    key: 'totalSaleValue',
    style: { ...format2dp },
    ...wide,
  },
  {
    header: 'Performance fee',
    key: 'performanceFee',
    style: { ...format2dp },
  },
  {
    header: 'Accrued fees charged',
    key: 'accruedFeesCharged',
    style: { ...format2dp },
  },
  ...commonColumnsEnd,
];

const editedExitColumns = [
  ...commonColumnsStart,
  {
    header: 'Previous accrued fees charged',
    key: 'previousAccruedFeesCharged',
    style: { ...greyOutColumn, ...format2dp },
  },
  {
    header: 'Previous performance fees charged',
    key: 'previousPerformanceFeesCharged',
    style: { ...greyOutColumn, ...format2dp },
  },
  {
    header: 'Previously received value',
    key: 'previousGrossReceivedValue',
    style: { ...greyOutColumn, ...format2dp },
  },
  {
    header: 'Shares allocated for sale',
    key: 'sharesAllocatedForSale',
    style: { ...greyOutColumn },
  },
  {
    header: 'Total Received value',
    key: 'totalSaleValue',
    style: { ...format2dp, ...greyOutColumn },
    ...wide,
  },
  {
    header: 'Additional received value',
    key: 'additionalGrossReceivedValue',
    style: { ...format2dp, ...defaultColumnStyle },
    ...wide,
  },
  {
    header: 'Additional performance fee',
    key: 'performanceFee',
    style: { ...format2dp },
    ...wide,
  },
  {
    header: 'Additional accrued fees',
    key: 'accruedFeesCharged',
    style: { ...format2dp },
    ...wide,
  },
  ...commonColumnsEnd,
];

const AmendmentsUpload = ({
  shareholdings = [],
  setShareholdings,
  onAmendmentsFileUpload,
  disableTable,
  isBeingEdited,
}) => {
  const [amendmentsMenuAnchorEl, setAmendmentsMenuAnchorEl] = useState(null);
  const [amendmentsFile, setAmendmentsFile] = useState(null);
  const inputFileRef = useRef(null);
  const classes = useStyles();
  const notification = useNotification();
  const columns = isBeingEdited ? editedExitColumns : createExitcolumns;

  const handleMenuOpen = (event) => {
    setAmendmentsMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAmendmentsMenuAnchorEl(null);
  };

  const validateUpdateValues = (updateValues) => {
    const updateValuesShareholdingIds = Array.from(updateValues.keys());
    const tableShareholdingIds = shareholdings.map((sh) => sh.shareholdingId);
    if (
      updateValuesShareholdingIds.length !== tableShareholdingIds.length ||
      !updateValuesShareholdingIds.every((id) =>
        tableShareholdingIds.includes(id),
      )
    ) {
      return false;
    }
    return true;
  };

  const downloadAmendmentsTemplate = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Exit-${Date.now()}`);

    worksheet.mergeCells('A1', 'P2');

    // Column Headers
    worksheet.getRow(3).values = columns.map((col) => col.header);

    worksheet.columns = columns;

    // Instructions - applied 'after' to overwrite the duplicate column headers above
    worksheet.getCell('A1').value =
      'To use this file, please edit the fields in the white columns and reupload this file. If formulas are used, please remove them before reuploading.';
    worksheet.getRow(1).getCell(1).font = {
      bold: true,
      italic: true,
      color: { argb: '000' },
      size: 11,
    };
    worksheet.getRow(1).getCell(1).alignment = {
      vertical: 'middle',
    };

    // Data
    shareholdings.forEach((sh, i) => {
      const totalToBeReturnedFormula = isBeingEdited
        ? `ROUND(J${i + 4}-L${i + 4}-M${i + 4}-F${i + 4}-G${i + 4}, 2)`
        : `ROUND(G${i + 4}-H${i + 4}-I${i + 4}, 2)`;

      const taxableValueFormula = isBeingEdited
        ? `ROUND((I${i + 4}*O${i + 4})-L${i + 4}-M${i + 4}-F${i + 4}-G${
            i + 4
          }, 2)`
        : `ROUND((F${i + 4}*K${i + 4})-H${i + 4}-I${i + 4}, 2)`;

      worksheet.addRows([
        {
          shareholdingId: sh.shareholdingId,
          externalInvestmentId: sh.externalInvestmentId,
          investorName: sh.investorName,
          tranche: sh.tranche?.fundName,
          noOfShares: sh.noOfShares,
          previousAccruedFeesCharged: sh.previousAccruedFeesCharged,
          previousPerformanceFeesCharged: sh.previousPerformanceFeesCharged,
          previousGrossReceivedValue: sh.previousGrossReceivedValue,
          sharesAllocatedForSale: sh.sharesAllocatedForSale,
          totalSaleValue: isBeingEdited
            ? { formula: `ROUND(H${i + 4}+K${i + 4}, 2)` }
            : sh.totalSaleValue,
          additionalGrossReceivedValue:
            sh.totalSaleValue - sh.previousGrossReceivedValue,
          performanceFee: sh.performanceFee,
          accruedFeesCharged: sh.accruedFeesCharged,
          totalToBeReturned: {
            formula: totalToBeReturnedFormula,
          },
          taxableValueSharePrice: sh.taxableValueSharePrice,
          taxableValue: {
            formula: taxableValueFormula,
          },
        },
      ]);
    });

    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `Exits-amendments-file-${Date.now()}.xlsx`,
      );
    });
  };

  const onFileInputChange = (e) => {
    onAmendmentsFileUpload();
    setAmendmentsFile(e.target.files[0]);
    // reset the input so if the same file is upload again, this onChange is triggered
    inputFileRef.current.value = '';
  };

  useEffect(() => {
    if (!amendmentsFile) return;

    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    const updateValues = new Map();

    reader.readAsArrayBuffer(amendmentsFile);
    reader.onload = () => {
      const buffer = reader.result;
      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet) => {
          sheet.eachRow((row, rowIndex) => {
            // Ignore Introduction and column headers
            if (rowIndex <= 3) return;

            const shareholdingIdI = columns.findIndex(
              (col) => col.key === 'shareholdingId',
            );
            const sharesAllocatedForSaleI = columns.findIndex(
              (col) => col.key === 'sharesAllocatedForSale',
            );
            const totalSaleValueI = columns.findIndex(
              (col) => col.key === 'totalSaleValue',
            );
            const performanceFeeI = columns.findIndex(
              (col) => col.key === 'performanceFee',
            );
            const accruedFeesI = columns.findIndex(
              (col) => col.key === 'accruedFeesCharged',
            );
            const taxableValueSharePriceI = columns.findIndex(
              (col) => col.key === 'taxableValueSharePrice',
            );

            const shareholdingId = row.getCell(shareholdingIdI + 1).value;
            const sharesAllocatedForSale = row.getCell(
              sharesAllocatedForSaleI + 1,
            ).value;
            const totalSaleValue = row.getCell(totalSaleValueI + 1).value;
            const performanceFee = row.getCell(performanceFeeI + 1).value;
            const accruedFees = row.getCell(accruedFeesI + 1).value;
            const taxableValueSharePrice = row.getCell(
              taxableValueSharePriceI + 1,
            ).value;

            const updateObject = {
              totalSaleValue: round(
                totalSaleValue?.result || totalSaleValue || 0,
                2,
              ),
              performanceFee: round(
                performanceFee?.result || performanceFee || 0,
                2,
              ),
              accruedFeesCharged: round(
                accruedFees?.result || accruedFees || 0,
                2,
              ),
              taxableValueSharePrice:
                taxableValueSharePrice?.result || taxableValueSharePrice || 0,
            };
            if (!isBeingEdited) {
              updateObject.sharesAllocatedForSale =
                sharesAllocatedForSale?.result || sharesAllocatedForSale || 0;
            }
            updateValues.set(
              shareholdingId.result || shareholdingId,
              updateObject,
            );
          });
        });

        if (updateValues.size) {
          if (validateUpdateValues(updateValues)) {
            const newShareholdings = shareholdings.map((sh) => {
              if (updateValues.has(sh.shareholdingId)) {
                const updates = updateValues.get(sh.shareholdingId);
                let totalToBeReturned =
                  updates.totalSaleValue -
                  updates.accruedFeesCharged -
                  updates.performanceFee;
                const grossTaxableValue =
                  (isBeingEdited
                    ? sh.sharesAllocatedForSale
                    : updates.sharesAllocatedForSale) *
                  updates.taxableValueSharePrice;
                let netTaxableValue =
                  grossTaxableValue -
                  updates.accruedFeesCharged -
                  updates.performanceFee;

                let additionalGrossReceivedValue;
                if (isBeingEdited) {
                  totalToBeReturned =
                    totalToBeReturned -
                    (sh.previousAccruedFeesCharged || 0) -
                    (sh.previousPerformanceFeesCharged || 0);
                  netTaxableValue =
                    netTaxableValue -
                    (sh.previousAccruedFeesCharged || 0) -
                    (sh.previousPerformanceFeesCharged || 0);
                  additionalGrossReceivedValue =
                    updates.totalSaleValue - sh.previousGrossReceivedValue;
                }

                return {
                  ...sh,
                  ...updates,
                  totalToBeReturned: Math.abs(round(totalToBeReturned, 2)),
                  grossTaxableValue: Math.abs(round(grossTaxableValue, 2)),
                  netTaxableValue: Math.abs(round(netTaxableValue, 2)),
                  additionalGrossReceivedValue: round(
                    additionalGrossReceivedValue,
                    2,
                  ),
                };
              } else {
                return sh;
              }
            });

            setShareholdings(newShareholdings);
          } else {
            notification.error(
              'This spreadsheet is invalid. Please ensure it is the correct spreadsheet with no additional data and all required cells filled.',
              { autoHideDuration: 12000 },
            );
          }
        }
      });
    };
  }, [amendmentsFile]);

  const onInputClick = () => {
    setAmendmentsFile(null);
    inputFileRef.current.click();
  };

  return (
    <>
      <Button
        aria-controls="amendments-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        variant="outlined"
        color="primary"
      >
        Amendments
      </Button>
      <Menu
        id="amendments-menu"
        anchorEl={amendmentsMenuAnchorEl}
        keepMounted
        open={Boolean(amendmentsMenuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem className={classes.menuItem} onClick={onInputClick}>
          <input
            style={{ display: 'none' }}
            ref={inputFileRef}
            type="file"
            onChange={onFileInputChange}
          />
          Upload amendments file
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={downloadAmendmentsTemplate}
        >
          Download amendments template
        </MenuItem>
      </Menu>
      {disableTable && (
        <Alert severity="info">
          To make further amendments, please upload a new file
        </Alert>
      )}
    </>
  );
};

export default AmendmentsUpload;
