import React from 'react';
import { useDispatch } from 'react-redux';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import FieldRow from 'components/FormElements/FieldRow';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { numberToCurrencyString, getCompanyLabel } from 'further-ui/utils';
import { AddCircleOutline, Done } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { createShareSplitAddRoute } from 'constants/routes';
import TextField from 'components/FormElements/AppTextInput';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import clsx from 'clsx';
import CkEditor from 'components/CkEditor';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import DropzoneComponent from 'components/Dropzone';
import AdditionalMetric from './AdditionalMetric';
import { InvestorReportingMediaType } from 'constants/investorReporting';
import { getCompanyLastUpdate } from '@redux/actions/InvestorReporting';
import { useNotification } from 'hooks/ui/useNotification';
import useFileUpload from 'hooks/ui/useFileUpload';
import {
  Controller,
  useController,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import useStyles from './styles';
import { UploadType } from 'further-types/files';

const CompanyUpdate = ({
  single,
  duplicateEntries,
  investorReportId,
  index,
  control,
  setValue,
  isViewPage,
  enableLoadLastUpdate,
  firmId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useNotification();
  const { uploadFile } = useFileUpload();

  const {
    fields: additionalMetrics,
    remove: removeMetric,
    append: appendMetric,
  } = useFieldArray({
    control,
    name: `companyInformation[${index}][additionalMetrics]`,
  });
  const {
    fields: media,
    remove: removeMedia,
    append: appendMedia,
  } = useFieldArray({
    control,
    name: `companyInformation[${index}][media]`,
  });
  const { field: mediaType } = useController({
    control,
    name: `companyInformation[${index}][mediaType]`,
  });
  const {
    fields: attachments,
    remove: removeAttachment,
    append: appendAttachement,
  } = useFieldArray({
    control,
    name: `companyInformation[${index}][attachments]`,
  });
  const copyFromCompanies = useWatch({
    control,
    name: `companyInformation[${index}][copyFromCompanies]`,
  });
  const isCopyingFromAnotherCompany = copyFromCompanies?.length;

  const getLastCompanyDetail = async (companyId) => {
    const response = await dispatch(
      getCompanyLastUpdate(companyId, investorReportId, firmId),
    );
    if (response.status === 200) {
      const { lastUpdate } = response.data.data;

      if (lastUpdate) {
        const {
          updateSummary,
          media = [],
          attachments = [],
          mediaType,
          additionalTitle,
          additionalValue,
          additionalMetrics,
          copyFromCompanies = [],
        } = lastUpdate;

        setValue(`companyInformation[${index}][updateSummary]`, updateSummary);
        setValue(`companyInformation[${index}][media]`, media);
        setValue(`companyInformation[${index}][attachments]`, attachments);
        setValue(`companyInformation[${index}][mediaType]`, mediaType);
        setValue(
          `companyInformation[${index}][additionalTitle]`,
          additionalTitle,
        );
        setValue(
          `companyInformation[${index}][additionalValue]`,
          additionalValue,
        );
        setValue(
          `companyInformation[${index}][additionalMetrics]`,
          additionalMetrics,
        );
        setValue(
          `companyInformation[${index}][copyFromCompanies]`,
          copyFromCompanies,
        );
      }
    }
  };

  const handleRemoveAttachment = (deleteIndex) => {
    if (isCopyingFromAnotherCompany) return;
    removeAttachment(deleteIndex);
  };

  const handleAddMetric = () => {
    if (isCopyingFromAnotherCompany) return;
    appendMetric({ key: '', value: '' });
  };

  const handleRemoveMetric = (metricIndex) => {
    if (isCopyingFromAnotherCompany) return;
    removeMetric(metricIndex);
  };

  async function uploadMedia(file) {
    try {
      const { filePath } = await uploadFile(
        file,
        UploadType.ReportingAttachments,
      );
      return filePath;
    } catch (_) {
      error(`Something went wrong - the file couldn't be uploaded`);
    }
  }

  const handleRemoveMedia = (deleteIndex) => {
    if (isCopyingFromAnotherCompany) return;
    removeMedia(deleteIndex);
  };

  const handleChangeMedia = async (files) => {
    if (isCopyingFromAnotherCompany) return;

    const mediaVideoIndex = media.findIndex(
      (item) => item.key === InvestorReportingMediaType.VIDEO,
    );

    if (mediaVideoIndex > -1) {
      removeMedia(mediaVideoIndex);
    }

    await Promise.all(
      files.map(async (file) => {
        if (file.path) {
          const filePath = await uploadMedia(file);
          appendMedia({
            title: file?.name,
            url: filePath,
            key: InvestorReportingMediaType.SLIDESHOW,
            preview: file?.preview,
          });
        }
      }),
    );
  };

  const handleChangeVideo = async (value) => {
    if (isCopyingFromAnotherCompany) return;

    const url = value.replace('watch?v=', 'embed/');

    setValue(`companyInformation[${index}][media]`, [
      {
        title: value,
        url,
        key: InvestorReportingMediaType.VIDEO,
      },
    ]);
  };

  const handleChangeAttachment = async (files) => {
    if (isCopyingFromAnotherCompany) return;

    await Promise.all(
      files.map(async (file) => {
        if (file.path) {
          const filePath = await uploadMedia(file);
          appendAttachement({
            title: file?.name,
            url: filePath,
            preview: file?.preview,
          });
        }
      }),
    );
  };

  const handleCopyFromCompanies = (event) => {
    const sourceCompany = duplicateEntries[event.target.value];

    if (sourceCompany) {
      if (!copyFromCompanies.includes(sourceCompany.companyId)) {
        setValue(`companyInformation[${index}][copyFromCompanies]`, [
          sourceCompany.companyId,
        ]);
        setValue(
          `companyInformation[${index}][copiedFromAnotherCompany]`,
          true,
        );
      }
    } else {
      setValue(`companyInformation[${index}][copyFromCompanies]`, []);
      setValue(`companyInformation[${index}][copiedFromAnotherCompany]`, false);
    }
  };

  const handleResetCompaniesCopyFrom = () => {
    if (isCopyingFromAnotherCompany) {
      setValue(`companyInformation[${index}][copyFromCompanies]`, []);
      setValue(`companyInformation[${index}][copiedFromAnotherCompany]`, false);
    }
  };

  return (
    <GridContainer style={{ marginTop: '0.5rem' }}>
      <Grid item xs={12} md={11}>
        <GridContainer>
          <Grid item md={7}>
            <GridContainer>
              <FieldRow title="Company">
                <div className={classes.tradingNameRow}>
                  {getCompanyLabel(single) || '.'}
                </div>
              </FieldRow>
              <FieldRow title="Current share price">
                {numberToCurrencyString(single.currentSharePrice?.[0]?.amount, {
                  unlimitedDp: true,
                })}
              </FieldRow>
              {enableLoadLastUpdate && (
                <Button
                  disabled={
                    isCopyingFromAnotherCompany ||
                    isViewPage ||
                    !single.lastUpdate
                  }
                  variant="outlined"
                  size="small"
                  style={{ margin: '12px' }}
                  onClick={() =>
                    getLastCompanyDetail(
                      investorReportId ? single.companyId : single._id,
                    )
                  }
                  data-testid={`load-last-update`}
                >
                  Load last update text
                </Button>
              )}
            </GridContainer>
          </Grid>
          <Grid item md={5} xs={12}>
            <Callout />
          </Grid>
        </GridContainer>
        <GridContainer>
          <FieldRow
            title="Updated share price"
            informationText="Leave blank if no change"
            tooltipText={
              <span>
                If there has been a share split since the company's last share
                price update, please process this in the share split function
                for this company{' '}
                <NavLink
                  style={{ color: '#56B26C' }}
                  target="_blank"
                  to={createShareSplitAddRoute(
                    investorReportId ? single.companyId : single._id,
                  )}
                >
                  here
                </NavLink>{' '}
                before submitting this investor update.
              </span>
            }
          >
            <Controller
              name={`companyInformation[${index}][updatedSharePrice]`}
              control={control}
              render={({ field: { ref, ...rest }, formState: { errors } }) => {
                return (
                  <TextField
                    inputRef={ref}
                    {...rest}
                    gbpStartAdornment
                    placeholder="Leave blank if no change"
                    fullWidth
                    data-testid={`updatedSharePrice`}
                    disabled={isViewPage}
                    error={
                      !!errors?.companyInformation?.[index]?.updatedSharePrice
                    }
                    helperText={
                      errors?.companyInformation?.[index]?.updatedSharePrice
                        ?.message
                    }
                  />
                );
              }}
            />
          </FieldRow>

          {duplicateEntries?.length ? (
            <FieldRow title="Copy from">
              <AppAutocomplete
                variant={'outlined'}
                options={duplicateEntries}
                value={duplicateEntries.find(
                  ({ companyId }) => copyFromCompanies?.[0] === companyId,
                )}
                getOptionLabel={getCompanyLabel}
                onChange={handleCopyFromCompanies}
                onReset={handleResetCompaniesCopyFrom}
                data-testid={`copyFrom`}
                disabled={isViewPage}
              />
            </FieldRow>
          ) : null}

          {!isCopyingFromAnotherCompany && (
            <>
              {additionalMetrics?.map((metric, metricIndex) => (
                <AdditionalMetric
                  key={`metric_${metricIndex}`}
                  metric={metric}
                  handleRemove={handleRemoveMetric}
                  metricIndex={metricIndex}
                  index={index}
                  disabled={isViewPage}
                  control={control}
                />
              ))}
              <Grid item xs={12}>
                <div className={classes.addMetricBtnGroup}>
                  <Button
                    disabled={isViewPage}
                    startIcon={<AddCircleOutline />}
                    className={clsx(classes.addMetricBtn)}
                    onClick={handleAddMetric}
                    variant="text"
                    color="primary"
                  >
                    Add additional metric
                    <span className={classes.additionalMetricsExtra}>
                      (e.g. ARR growth)
                    </span>
                  </Button>
                </div>
              </Grid>
              <FieldRow
                title="Company description"
                informationText="Any changes to this will also edit the company's main description (shown on the summary page of each investor’s portal)"
              >
                <Controller
                  name={`companyInformation[${index}][description]`}
                  control={control}
                  render={({
                    field: { name, value },
                    formState: { errors },
                  }) => (
                    <>
                      <CkEditor
                        name={name}
                        disabled={isViewPage}
                        onChange={(_, editor) => {
                          setValue(
                            `companyInformation[${index}][description]`,
                            editor.getData(),
                          );
                        }}
                        value={value}
                      />
                      <p
                        className={classes.errorCk}
                        id={`companyInformation[${index}][description]`}
                      >
                        {
                          errors?.companyInformation?.[index]?.description
                            ?.message
                        }
                      </p>
                    </>
                  )}
                />
              </FieldRow>
              <FieldRow title="Company update">
                <Controller
                  name={`companyInformation[${index}][updateSummary]`}
                  control={control}
                  render={({ field: { name, value } }) => (
                    <CkEditor
                      placeholder="Want to give a specific update about this company? Use this field to let your investors know what has been happening."
                      name={name}
                      onChange={(_, editor) => {
                        setValue(
                          `companyInformation[${index}][updateSummary]`,
                          editor.getData(),
                        );
                      }}
                      value={value}
                      disabled={isViewPage}
                    />
                  )}
                />
              </FieldRow>
              <FieldRow title="Add media">
                <AppRadioButton
                  key="link"
                  name="mediaType"
                  label="Add video"
                  value={InvestorReportingMediaType.VIDEO}
                  checked={mediaType.value === InvestorReportingMediaType.VIDEO}
                  onChange={mediaType.onChange}
                  disabled={isViewPage}
                  data-testid={`mediaType-${InvestorReportingMediaType.VIDEO}`}
                />
                <AppRadioButton
                  key="Sideshow"
                  name="mediaType"
                  label="Slideshow"
                  value={InvestorReportingMediaType.SLIDESHOW}
                  checked={
                    mediaType.value === InvestorReportingMediaType.SLIDESHOW
                  }
                  onChange={mediaType.onChange}
                  disabled={isViewPage}
                  data-testid={`mediaType-${InvestorReportingMediaType.SLIDESHOW}`}
                />
                {mediaType.value === InvestorReportingMediaType.VIDEO ? (
                  <Controller
                    name={`companyInformation[${index}][media]`}
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => (
                      <TextField
                        inputRef={ref}
                        {...rest}
                        value={
                          value?.[0]?.key === InvestorReportingMediaType.VIDEO
                            ? value?.[0]?.url
                            : ''
                        }
                        onChange={(e) => {
                          handleChangeVideo(e.target.value);
                        }}
                        fullWidth
                        placeholder="Enter video link (YouTube only)"
                        disabled={isViewPage}
                        data-testid={`media`}
                      />
                    )}
                  />
                ) : (
                  <DropzoneComponent
                    disabled={isViewPage}
                    files={
                      media
                        ?.filter(
                          (item) =>
                            item.key === InvestorReportingMediaType.SLIDESHOW,
                        )
                        .map((file) => ({
                          fileName: file.title,
                          preview: file.preview || file.url,
                        })) ?? []
                    }
                    onDropFile={handleChangeMedia}
                    onRemove={handleRemoveMedia}
                    maxFiles={10}
                  />
                )}
              </FieldRow>

              <FieldRow title="Additional field title">
                <Controller
                  name={`companyInformation[${index}][additionalTitle]`}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      inputRef={ref}
                      {...rest}
                      disabled={isViewPage}
                      placeholder="E.g. Can you help?"
                      fullWidth
                      data-testid={`additionalTitle`}
                    />
                  )}
                />
              </FieldRow>
              <FieldRow title="Additional field text">
                <Controller
                  name={`companyInformation[${index}][additionalValue]`}
                  control={control}
                  render={({ field: { name, value } }) => (
                    <CkEditor
                      placeholder="An optional box for you to use as you see fit. Want to ask your investors to help your company in any way? Is the company running an event that they want to publicise to investors? Let the investors know here."
                      name={name}
                      disabled={isViewPage}
                      onChange={(_, editor) => {
                        setValue(
                          `companyInformation[${index}][additionalValue]`,
                          editor.getData(),
                        );
                      }}
                      value={value}
                    />
                  )}
                />
              </FieldRow>
              <FieldRow title="Attach documents">
                <DropzoneComponent
                  disabled={isViewPage}
                  files={
                    attachments.map((file) => ({
                      fileName: file.title,
                      preview: file.preview || file.url,
                    })) ?? []
                  }
                  onDropFile={handleChangeAttachment}
                  onRemove={handleRemoveAttachment}
                  maxFiles={10}
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                />
              </FieldRow>
            </>
          )}
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

const Callout = () => {
  const classes = useStyles();
  const metrics = ['Value at time of investment', 'Current value', '% change'];
  return (
    <div className={clsx(classes.callout)}>
      <Typography className={classes.calloutTitle}>
        What metrics do investors see automatically?
      </Typography>
      <List dense={true}>
        {metrics.map((metric) => (
          <ListItem key={metric} disableGutters>
            <ListItemIcon>
              <Done className={classes.greenIcon} />
            </ListItemIcon>
            <ListItemText primary={metric} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default CompanyUpdate;
