import { Box, MenuItem, SvgIconTypeMap, makeStyles } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { CustomTheme } from 'theme/themeColors';

type Props = {
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  color?: 'primary' | 'danger';
  onClick?: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  item: {
    color: '#656565',
    fontSize: '14px',
    fontWeight: 500,
  },
  iconPrimary: {
    fontSize: '22px',
    color: theme.palette.primary.main,
  },
  iconDanger: {
    fontSize: '22px',
    color: theme.palette.text.red,
  },
}));

const Action: React.FC<Props> = ({
  label,
  onClick,
  icon: Icon,
  color,
  disabled,
}) => {
  const classes = useStyles();

  const iconClasses = {
    primary: classes.iconPrimary,
    danger: classes.iconDanger,
  };

  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <Box
        display="flex"
        gridGap={4}
        alignItems="center"
        className={classes.item}
        py={2}
      >
        {Icon && <Icon className={iconClasses[color]} />}
        <span>{label}</span>
      </Box>
    </MenuItem>
  );
};

export default Action;
