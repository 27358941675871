import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  fullWidth: {
    maxWidth: '100%',
  },
  heading: {
    lineHeight: 1,
    marginTop: theme.spacing(6),
  },
  tableHeader: {
    padding: 12,
    color: theme.palette.text.tableText,
    backgroundColor: theme.palette.background.header,
  },
  tooltip: {
    backgroundColor: theme.palette.text.white,
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(3),
    color: theme.palette.text.error,
    boxShadow: theme.shadows[8],
  },
  note: {
    fontSize: 15,
  },
}));

export default useStyles;
