import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import Typography from '@material-ui/core/Typography';
import { Disclosure } from 'further-ui/hooks/useDisclosure';

type Props = {
  disclosure: Disclosure;
};

const NegativeBalanceErrorModal: FC<Props> = ({ disclosure }) => (
  <AlertDialog
    open={disclosure.isOpen}
    title="Negative balance error"
    content={
      <>
        <Typography>
          Due to transactions (allocations and/or fees) processed on this
          subscription, there are insufficient funds available to change the
          total commitment as requested. Please review all investment
          transactions and delete or amend them before making this change.
        </Typography>
      </>
    }
    onClose={disclosure.onClose}
    btnLabels={{
      confirm: false,
      cancel: 'OK',
    }}
  />
);

export default NegativeBalanceErrorModal;
