import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { Grid, Button, makeStyles } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import GridContainer from 'components/GridContainer';
import AddDeploymentUpdateDetails from './AddDetail';
import Summary from '../Common/Summary';
import { INVESTOR_REPORTING } from 'constants/routes';
import { useDeploymentUpdate } from 'hooks/data/deploymentUpdate/useDeploymentUpdate';
import { useCompanies } from 'hooks/data/company/useCompanies';

const useStyles = makeStyles(() => ({
  cardContentPadding: {
    padding: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonLeftMargin: {
    marginLeft: 12,
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: ' Deployment update', link: '/', isActive: true },
];

const DeploymentUpdate = () => {
  const history = useHistory();
  const classes = useStyles();
  const heading = 'Investor reporting: Deployment update';
  const { shareAllocationUploadId, companyId } = useParams();
  const steps = { addDeploymentUpdateDetails: 1, summary: 2 };
  const [activeStep, setActiveStep] = useState(
    steps.addDeploymentUpdateDetails,
  );
  const [data, setData] = useState({ shareAllocationUploadId });
  const [errorText, setErrorText] = useState();
  const deploymentUpdate = useDeploymentUpdate({});
  const { company } = useCompanies({ params: { companyId } });

  const validate = () => {
    const errorMessage = {};
    if (!data.subject) {
      errorMessage.subject = 'Please enter an email subject.';
    }
    if (!data.title) {
      errorMessage.title = 'Please enter an email title.';
    }
    if (!data.emailTopParagraph) {
      errorMessage.emailTopParagraph = 'Please enter an email top paragraph.';
    }
    if (!data.emailBottomParagraph) {
      errorMessage.emailBottomParagraph =
        'Please enter an email bottom paragraph.';
    }
    setErrorText(errorMessage);
    return Object.keys(errorMessage).length;
  };

  const onClick = async () => {
    if (steps.addDeploymentUpdateDetails === activeStep) {
      if (!validate()) {
        setActiveStep(steps.summary);
      }
    } else {
      deploymentUpdate.create.mutate(data);
    }
  };

  useEffect(() => {
    if (deploymentUpdate.create.isSuccess) {
      history.push(INVESTOR_REPORTING);
    }
  }, [deploymentUpdate.create.isSuccess]);

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader
            title={
              activeStep === steps.addDeploymentUpdateDetails
                ? 'Deployment update details'
                : 'Deployment update summary'
            }
          />

          {steps.addDeploymentUpdateDetails === activeStep ? (
            <AddDeploymentUpdateDetails
              errorText={errorText}
              classes={classes}
              data={data}
              setData={setData}
              company={company.data ?? {}}
            />
          ) : (
            <Summary data={data} company={company.data ?? {}} />
          )}

          <GridContainer md={12}>
            <Grid item xs={12}>
              <div className={classes.buttonContainer}>
                {activeStep === steps.summary ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(steps.addDeploymentUpdateDetails);
                    }}
                  >
                    Back
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  className={classes.buttonLeftMargin}
                  color="primary"
                  variant="contained"
                  onClick={onClick}
                  disabled={deploymentUpdate.create.isLoading}
                >
                  {activeStep === steps.addDeploymentUpdateDetails
                    ? 'Proceed to Summary'
                    : 'Submit'}
                </Button>
              </div>
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default DeploymentUpdate;
