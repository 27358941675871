import { nationality } from 'further-ui/labels';
import FormHelperText from '@material-ui/core/FormHelperText';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import SectionLegend from 'components/SectionLegend';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import FieldRow from 'components/FormElements/FieldRow';
import { addYears } from 'date-fns';

const EisFormData = ({ setData, data, errorText }) => {
  const certificateOptions = [
    { key: 'EIS investment', value: 'EIS' },
    { key: 'SEIS investment', value: 'SEIS' },
  ];

  const onChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  const onBooleanChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value === 'true' ? true : false });
  };

  return (
    <GridContainer>
      <FieldRow title="Investment type*">
        <AppSelectBox
          required
          data={certificateOptions}
          valueKey="value"
          name="investmentType"
          labelKey="key"
          variant="outlined"
          renderValue={(value) =>
            !value
              ? 'Select investment type'
              : certificateOptions.find((opt) => opt.value === value)?.key
          }
          value={data.investmentType}
          error={errorText.investmentType}
          helperText={errorText.investmentType}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Unique investment reference*">
        <TextField
          required
          name="uir"
          fullWidth
          error={errorText?.uir}
          helperText={errorText?.uir}
          value={data?.uir}
          placeholder="Enter unique investment reference"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Description of shares*">
        <TextField
          required
          name="descriptionShare"
          fullWidth
          value={data?.descriptionShare}
          error={errorText?.descriptionShare}
          helperText={errorText?.descriptionShare}
          placeholder="Enter description of shares, e.g. Ordinary shares"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Knowledge-intensive company*">
        <SectionLegend>
          <AppRadioButton
            name="knowledgeIntensive"
            label="Yes"
            value={true}
            checked={data.knowledgeIntensive === true}
            onChange={onBooleanChange}
          />
          <AppRadioButton
            name="knowledgeIntensive"
            label="No"
            value={false}
            checked={data.knowledgeIntensive === false}
            onChange={onBooleanChange}
          />
          {errorText?.knowledgeIntensive && (
            <FormHelperText error>
              {errorText?.knowledgeIntensive}
            </FormHelperText>
          )}
        </SectionLegend>
      </FieldRow>
      <FieldRow title="Nominal value of each share*">
        <TextField
          required
          name="nominalValueOfEachShare"
          fullWidth
          onChange={onChange}
          value={data?.nominalValueOfEachShare}
          error={errorText?.nominalValueOfEachShare}
          helperText={errorText?.nominalValueOfEachShare}
          placeholder="Enter nominal value of each share"
        />
      </FieldRow>
      <FieldRow title="Date of share issue*">
        <KeyboardDatePicker
          required
          placeholder="Select date of share issue"
          InputLabelProps={{
            shrink: true,
          }}
          name="dateOfShareIssue"
          value={data?.dateOfShareIssue}
          onChange={(date) =>
            setData({
              ...data,
              dateOfShareIssue: date,
              terminationDateOfShare: date ? addYears(date.toDate(), 3) : null,
            })
          }
          error={errorText?.dateOfShareIssue}
          helperText={errorText?.dateOfShareIssue}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FieldRow>
      <FieldRow title="Termination date of these shares*">
        <KeyboardDatePicker
          required
          placeholder="Select termination date of these shares"
          InputLabelProps={{
            shrink: true,
          }}
          name="terminationDateOfShare"
          value={data?.terminationDateOfShare}
          onChange={(date) =>
            setData({ ...data, terminationDateOfShare: date })
          }
          error={errorText?.terminationDateOfShare}
          helperText={errorText?.terminationDateOfShare}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FieldRow>
      <FieldRow
        title="Authorisation date / EIS2 date (KI funds only)"
        tooltipText="This field is only required where this investment is part of an HMRC-approved knowledge intensive fund."
      >
        <KeyboardDatePicker
          required
          placeholder="Select date of HMRC authorisation of certificate"
          InputLabelProps={{
            shrink: true,
          }}
          name="hmrcAuthorisationDate"
          value={data?.hmrcAuthorisationDate}
          onChange={(date) => setData({ ...data, hmrcAuthorisationDate: date })}
          error={errorText?.hmrcAuthorisationDate}
          helperText={errorText?.hmrcAuthorisationDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FieldRow>
      <FieldRow title="Company address line 1*">
        <TextField
          required
          name="address1"
          fullWidth
          value={data?.address1}
          error={errorText?.address1}
          helperText={errorText?.address1}
          placeholder="Enter address"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Company address line 2">
        <TextField
          name="address2"
          fullWidth
          value={data?.address2}
          placeholder="Enter address"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Company address city*">
        <TextField
          required
          name="city"
          fullWidth
          value={data?.city}
          error={errorText?.city}
          helperText={errorText?.city}
          placeholder="Enter city"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Company address postal code*">
        <TextField
          required
          name="postcode"
          fullWidth
          value={data?.postcode}
          error={errorText?.postcode}
          helperText={errorText?.postcode}
          placeholder="Enter postal code"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Company address country*">
        <AppSelectBox
          required
          name="country"
          data={nationality}
          valueKey="Country"
          labelKey="Country"
          value={data.country}
          renderValue={(value) => value || 'Select country'}
          variant="outlined"
          onChange={onChange}
          error={errorText.country}
          helperText={errorText.country}
        />
      </FieldRow>
      <FieldRow title="Name of company representative*">
        <TextField
          required
          name="nameOfCompanyRepresentative"
          fullWidth
          value={data?.nameOfCompanyRepresentative}
          error={errorText?.nameOfCompanyRepresentative}
          helperText={errorText?.nameOfCompanyRepresentative}
          placeholder="Enter name of company representative"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Capacity in which signed*">
        <TextField
          required
          name="capicityInWhichSigned"
          fullWidth
          value={data?.capicityInWhichSigned}
          error={errorText?.capicityInWhichSigned}
          helperText={errorText?.capicityInWhichSigned}
          placeholder="Enter capacity in which signed"
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Signature">
        <SectionLegend>
          <AppRadioButton
            name="signatureType"
            label="Use plain text company representative name"
            value={0}
            checked={data.signatureType === '0'}
            onChange={onChange}
          />
          {errorText?.signatureType && (
            <FormHelperText error>{errorText?.signatureType}</FormHelperText>
          )}
        </SectionLegend>
      </FieldRow>
      <FieldRow title="Signature date*">
        <KeyboardDatePicker
          required
          placeholder="Select signature date"
          InputLabelProps={{
            shrink: true,
          }}
          name="signatureDate"
          value={data?.signatureDate}
          onChange={(date) => setData({ ...data, signatureDate: date })}
          error={errorText?.signatureDate}
          helperText={errorText?.signatureDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FieldRow>
    </GridContainer>
  );
};
export default EisFormData;
