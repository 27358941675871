import React from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';
import { CallSplit, CompareArrows } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { Api } from 'further-types/investment';
import useStyles from './styles';
import { Box } from '@material-ui/core';
import { NoOfSharesHistoryType } from 'further-types/shareholding';
import { createInvestmentEditRoute } from 'constants/routes';

type Shareholding = Api.GetInvestmentShareholdingsResponse[number] & {
  isTransferred?: boolean;
};

type Props = {
  currentShareholdings: Array<Shareholding>;
  transferDate?: string;
};

const getInitialTransferredSharesAmount = (shareholding: Shareholding) => {
  return (
    shareholding.noOfSharesHistory?.[0]?.noOfShares ??
    shareholding.sharesCurrentlyHeld
  );
};

const ShareholdingCompanyName: React.FC<{
  shareholding: Shareholding;
  transferDate?: string;
}> = ({ shareholding, transferDate }) => {
  const classes = useStyles();

  const previousShareSplits = shareholding.noOfSharesHistory?.filter(
    (item) => item.setVia === NoOfSharesHistoryType.ShareSplit,
  );

  const isPartOfTransfer =
    !!shareholding.sourceShareholding || shareholding.isTransferred;

  if (!previousShareSplits?.length && !isPartOfTransfer)
    return <>{shareholding.company.name}</>;

  const shareSplitDates = previousShareSplits
    .map((item) => dateToLabel(item.date))
    .join(' and ');

  const tooltipText = [
    ...(shareSplitDates?.length
      ? [`This company was subject to a share split on ${shareSplitDates}`]
      : []),
    ...(shareholding.sourceShareholding
      ? [
          `${getInitialTransferredSharesAmount(
            shareholding,
          )} shares were transferred from <a class="${
            classes.tooltipLink
          }" href="${createInvestmentEditRoute(
            shareholding.sourceShareholding.investmentId,
          )}" target="_blank">${
            shareholding.sourceShareholding.investor.fullName
          }</a> on ${dateToLabel(transferDate)}.`,
        ]
      : []),
    ...(shareholding.isTransferred
      ? [
          `${getInitialTransferredSharesAmount(
            shareholding,
          )} shares were transferred to  <a class="${
            classes.tooltipLink
          }" href="${createInvestmentEditRoute(
            shareholding.investmentId,
          )}" target="_blank">${
            shareholding.investor.fullName
          }</a> on ${dateToLabel(shareholding.transferDate)}.`,
        ]
      : []),
  ].join(' ');

  const icons = [
    ...(shareSplitDates ? [CallSplit] : []),
    ...(isPartOfTransfer ? [CompareArrows] : []),
  ];

  return (
    <>
      <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
        interactive
      >
        <Box alignItems="center" display="flex" gridGap={4}>
          {shareholding.company.name}{' '}
          {icons.map((Icon, i) => (
            <Icon key={i} className={classes.splitIcon} />
          ))}
        </Box>
      </Tooltip>
    </>
  );
};

const CurrentShareholdings: React.FC<Props> = ({
  currentShareholdings,
  transferDate,
}) => {
  const classes = useStyles();

  const combinedShareholdings =
    currentShareholdings?.reduce((combined, shareholding) => {
      return [
        ...combined,
        ...(shareholding.sharesCurrentlyHeld ? [shareholding] : []),
        ...shareholding.transferredShareholdings
          .filter(({ sharesCurrentlyHeld }) => !!sharesCurrentlyHeld)
          .map((transferredShareholding) => ({
            ...transferredShareholding,
            isTransferred: true,
          })),
      ];
    }, []) ?? [];

  const columns = [
    {
      label: 'Company',
      key: 'companyId',
      sort: false,
      render: (shareholding: Shareholding) => (
        <ShareholdingCompanyName
          shareholding={shareholding}
          transferDate={transferDate}
        />
      ),
    },
    {
      label: 'Investment date',
      key: 'createdAt',
      sort: false,
      render: (shareholding: Shareholding) =>
        dateToLabel(shareholding.investmentDate),
    },
    {
      label: 'Initial share price',
      key: 'initialSharePrice',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.initialSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Shares held',
      key: 'noOfShare',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToDisplayString(shareholding.sharesCurrentlyHeld),
    },
    {
      label: 'Capital allocated',
      key: 'subscriptionAmount',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentCapitalAllocated),
    },
    {
      label: 'Current share price',
      key: 'currentSharePrice',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Current share value',
      key: 'currentShareValue',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentShareValue),
    },
  ];

  const getTotalCapitalAndShareValue = (dataArray: Array<Shareholding>) => {
    let totalCapitalAllocated = 0;
    let totalShareValue = 0;

    dataArray.forEach((item) => {
      totalCapitalAllocated += item.isTransferred
        ? 0
        : item.currentCapitalAllocated;
      totalShareValue += item.isTransferred
        ? 0
        : Number(item.currentShareValue);
    });

    return {
      totalCapitalAllocated,
      totalShareValue,
    };
  };
  const nonEmptyShareHoldings =
    combinedShareholdings?.filter(
      ({ isFullyExited, sharesCurrentlyHeld }) =>
        !isFullyExited && sharesCurrentlyHeld,
    ) ?? [];
  const totals = getTotalCapitalAndShareValue(nonEmptyShareHoldings);

  return (
    <Table
      columns={columns.map((column) => ({
        ...column,
        getCellClassName: (shareholding: Shareholding) =>
          shareholding.isTransferred ? classes.inactiveCell : '',
      }))}
      tablebody={combinedShareholdings}
      emptyMessage="No current shareholdings found"
      variant="nohover"
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} align="right" colSpan={4}>
              Total capital allocated
            </td>
            <td className={classes.summaryValue}>
              <Box alignItems="center" display="flex" gridGap={4}>
                {numberToCurrencyString(totals.totalCapitalAllocated)}
              </Box>
            </td>
            <td className={classes.summaryValue} align="right">
              Total share value
            </td>
            <td className={classes.summaryValue}>
              <Box alignItems="center" display="flex" gridGap={4}>
                {numberToCurrencyString(totals.totalShareValue)}
              </Box>
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default CurrentShareholdings;
