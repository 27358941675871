import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { downloadExits } from '@redux/actions/Exit';
import Table from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  getCompanyLabel,
} from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { numberToDisplayString } from 'utils/numbers';
import { IconButton, Button, Grid, makeStyles } from '@material-ui/core';
import { CloudDownload, Delete, Edit, Email } from '@material-ui/icons';
import { useGetRole } from 'hooks/ui/useGetRole';
import AlertDialog from 'components/AlertDialog';
import {
  EXITS_ADD,
  createExitEditRoute,
  createExitStatementAddRoute,
  createExitStatementPdfDownloadRoute,
} from 'constants/routes';
import { useExit, useExits } from 'hooks/data/exit';
import CompanyListSelect from 'components/CompanyListSelect';

import GridContainer from 'components/GridContainer';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { usePagination } from 'hooks/ui/usePagination';
import { useNotification } from 'hooks/ui/useNotification';
import { CustomTheme } from 'theme/themeColors';
import useFilters from 'hooks/ui/useFilters';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme: CustomTheme) => ({
  mailto: {
    color: theme.palette.text.green,
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  actionItem: {
    display: 'flex',
  },
  exitLogContainer: {
    marginBottom: '1rem',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '4px',
  },
}));

const ListExits = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const deleteDisclosure = useDisclosure();
  const { isSuperAdmin } = useGetRole();
  const notification = useNotification();

  const pagination = usePagination({
    id: 'exits-table',
    orderBy: 'date',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('exits-table', {
    onFiltersChange: pagination.toFirstPage,
    defaultFilters: {
      company: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
  });

  const exits = useExits({
    params: {
      companyId: filters.company?._id,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      startDate: filters.dateRange?.startDate
        ? dateToLabel(filters.dateRange.startDate)
        : null,
      endDate: filters.dateRange?.endDate
        ? dateToLabel(filters.dateRange.endDate)
        : null,
      order: pagination.order,
      orderBy: pagination.orderBy,
    },
  });

  const { deleteExit } = useExit({});

  const handleDownload = async (id) => {
    await dispatch(downloadExits(id, notification));
  };

  const onDeleteSuccess = () => {
    deleteDisclosure.onClose();
  };

  const onDeleteExit = async () => {
    const exitId = deleteDisclosure.actionProps.id;
    const exitAmendmentId = deleteDisclosure.actionProps.exitAmendmentId;
    deleteExit.mutate(
      // @ts-ignore - hook is not typed
      { exitId, exitAmendmentId, onDeleteSuccess },
    );
  };

  const columns = [
    ...(isSuperAdmin
      ? [
          {
            label: 'Firm',
            key: 'firmId',
            sort: false,
            render: (elm) => elm?.firmId?.firmName,
          },
        ]
      : []),
    {
      label: 'Company',
      key: 'companyTradingName',
      sort: false,
      render: (elm) => getCompanyLabel(elm.companyId),
    },
    {
      label: 'First exit date',
      key: 'date',
      render: (elm) => dateToLabel(elm.date),
    },
    {
      label: 'Shares realised',
      key: 'noSharesExited',
      sort: false,
      render: (elm) => numberToDisplayString(elm.noSharesExited),
    },
    {
      label: 'Total exit value',
      key: 'totalExitValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.totalExitValue),
    },
    {
      label: 'Last edit date',
      key: 'lastEditDate',
      sort: false,
      render: (elm) =>
        elm.lastAmendmentDate ? dateToLabel(elm.lastAmendmentDate) : '-',
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <div className={classes.actionItem}>
          <Link to={createExitEditRoute(elm._id)}>
            <IconButton title="Edit" color="primary">
              <Edit />
            </IconButton>
          </Link>
          <IconButton
            title="Download"
            onClick={(e) => {
              handleDownload(elm._id);
              e.stopPropagation();
            }}
          >
            <CloudDownload color="primary" />
          </IconButton>
        </div>
      ),
    },
  ];

  const collapsedTableColumns = [
    {
      label: 'Edit date',
      key: 'submitDate',
      sort: false,
      render: (elm) =>
        elm.date && !elm.isInitial ? dateToLabel(elm.date) : 'Original',
    },
    {
      label: 'Received value share price',
      key: 'receivedValueSharePrice',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.sharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Taxable value share price',
      key: 'taxableValueSharePrice',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.taxableValueSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Exit value',
      key: 'exitValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.exitValue),
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <div className={classes.actionItem}>
          {elm?.deleteForbidden ? (
            <Tooltip title="To delete this exit, subsequent edits must be deleted first.">
              <span>
                <span>
                  <IconButton title="Delete" disabled>
                    <Delete />
                  </IconButton>
                </span>
              </span>
            </Tooltip>
          ) : (
            <IconButton
              title="Delete"
              onClick={() =>
                deleteDisclosure.stageAction({
                  id: elm._id || elm.exitId,
                  exitAmendmentId: elm.exitAmendmentId,
                })
              }
            >
              <Delete htmlColor="#E8453C" />
            </IconButton>
          )}
          <Link
            to={
              !elm?.exitStatementId
                ? createExitStatementAddRoute(elm.exitId, elm.exitAmendmentId)
                : createExitStatementPdfDownloadRoute(elm.exitStatementId)
            }
          >
            <IconButton
              title={
                !elm?.exitStatementId
                  ? 'Send exit statement'
                  : 'View exit statement PDFs'
              }
              color={!elm?.exitStatementId ? 'primary' : 'default'}
            >
              <Email />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <PageContainer heading="Exits">
      <CmtCard>
        <CmtCardContent>
          <GridContainer>
            <Grid item xs={12} md={3}>
              {
                //@ts-ignore
                <CompanyListSelect
                  includeFirmNameInLabel={false}
                  handleChangeCallback={(company) => {
                    handleChangeFilters({
                      company,
                    });
                  }}
                />
              }
            </Grid>
            <Grid item xs={12} md={3}>
              <MuiDateRangePicker
                dateRange={filters.dateRange}
                onDateRangeChange={(data) => {
                  handleChangeFilters({
                    dateRange: data,
                  });
                }}
                TextFieldProps={{ placeholder: 'Select date' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.actions}>
                <Link to={EXITS_ADD}>
                  <Button color="primary" variant="contained">
                    Process new exit
                  </Button>
                </Link>
              </div>
            </Grid>
          </GridContainer>
          <Table
            columns={columns}
            tablebody={exits.data?.result || []}
            emptyMessage="No exits have been created yet"
            variant="nohover"
            loading={exits.isFetching}
            pagination={true}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            order={pagination.order}
            orderBy={pagination.orderBy}
            onRequestSort={pagination.handleRequestSort}
            count={exits.data?.total}
            collapsedContent={(row) => {
              return (
                <div className={classes.exitLogContainer}>
                  <Table
                    columns={collapsedTableColumns}
                    tablebody={row.exitLog || []}
                  />
                </div>
              );
            }}
          />
        </CmtCardContent>
      </CmtCard>
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Are you sure you wish to delete this exit?"
        content="This cannot be undone and all information relating to the exit will be lost."
        btnLabels={{ confirm: 'Delete exit', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: deleteExit?.isLoading,
          isFetching: deleteExit?.isLoading,
        }}
        onConfirm={onDeleteExit}
      />
    </PageContainer>
  );
};

export default ListExits;
