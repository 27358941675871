export const fundStatusToLabel = {
  2: 'Draft',
  1: 'Open',
  0: 'Closed',
};

export const fundStatusMap = {
  open: 1,
  closed: 0,
  draft: 2,
};
