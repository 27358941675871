import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import { Delete, Edit, FileCopy } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { deleteCompany, exportCompanies } from '@redux/actions/Company';
import { FirmSelector } from 'components/FirmSelector';

import { getCurrentSharePrice } from 'helpers/company/getCurrentSharePrice';
import { COMPANY_ADD } from 'constants/routes';
import { Link } from 'react-router-dom';
import { numberToCurrencyString } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { useCompanies } from 'hooks/data/company/useCompanies';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDebounce } from 'usehooks-ts';
import { companiesHouseUrl } from 'constants/companyProperties';
import { useNotification } from 'hooks/ui/useNotification';
import { CustomTheme } from 'theme/themeColors';
import useFilters from 'hooks/ui/useFilters';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
  socialIcons: {
    maxWidth: 24,
  },
  investmentTypeWidth: {
    width: 110,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    paddingBottom: 8,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  actionButton: {
    marginRight: 8,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Companies', isActive: true },
];

const Company = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deleteDisclosure = useDisclosure();
  const { error, success } = useNotification();
  const { firmId, isSuperAdmin } = useGetRole();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions([
      'create:companies',
      'delete:companies',
      'edit:companies',
    ]);

  const pagination = usePagination({
    id: 'companies-table',
    orderBy: 'createdAt',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters('companies-table', {
    defaultFilters: {
      firmId,
      companyName: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });
  const params = new URLSearchParams(location.search);
  const fundId = params.get('fundId') || '';
  const debouncedCompanyName = useDebounce(filters.companyName, 150);
  const [isLoading, setLoading] = useState(false);
  localStorage.removeItem('duplicate-company-id');

  const { companies } = useCompanies({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      type: pagination.order,
      keyName: pagination.orderBy,
      fundId,
      firmId: filters.firmId,
      companyName: debouncedCompanyName,
      includeSharesPerInvestee: true,
    },
  });

  const columns = [
    ...(isSuperAdmin
      ? [
          {
            label: 'Firm',
            render: (elm) => elm?.firmId?.firmName,
            key: 'firmId',
          },
        ]
      : []),
    {
      label: 'Company',
      key: 'tradingName',
    },
    {
      label: 'Legal name',
      key: 'legalName',
    },
    {
      label: 'Share class',
      render: (elm) => elm?.shareClass,
      key: 'shareClass',
      ellipsis: true,
    },
    {
      label: 'Current share price',
      key: 'initialPrice',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(getCurrentSharePrice(elm), {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Current hold value',
      key: 'currentHoldValue',
      render: (elm) => numberToCurrencyString(elm.currentHoldValue),
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <div className={classes.dFlex}>
          {updatePermission && (
            <>
              <Button
                title="Duplicate"
                className={classes.customButton}
                color="primary"
                onClick={() => {
                  localStorage.setItem('duplicate-company-id', elm._id);
                  history.push(COMPANY_ADD);
                }}
              >
                <FileCopy />
              </Button>
              <Link to={`/edit-company/${elm._id}`}>
                <Button
                  title="Edit"
                  color="primary"
                  className={classes.customButton}
                >
                  <Edit />
                </Button>
              </Link>
            </>
          )}
          {deletePermission && (
            <Button
              title="Delete"
              className={classes.customButton}
              onClick={() => deleteDisclosure.stageAction(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
          {elm?.linkedinUrl && (
            <a
              href={elm?.linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary">
                <img
                  src="images/company/linkedin.png"
                  alt="linkedin"
                  className={classes.socialIcons}
                />
              </Button>
            </a>
          )}
          {elm?.twitterUrl && (
            <a href={elm?.twitterUrl} target="_blank" rel="noopener noreferrer">
              <Button color="primary">
                <img
                  src="images/company/twitter.png"
                  alt="twitter"
                  className={classes.socialIcons}
                />
              </Button>
            </a>
          )}
          {elm?.url && (
            <a href={elm?.url} target="_blank" rel="noopener noreferrer">
              <Button color="primary">
                <img
                  src="images/company/url.png"
                  alt="url"
                  className={classes.socialIcons}
                />
              </Button>
            </a>
          )}
          {elm?.companiesHouseNumber && (
            <a
              href={companiesHouseUrl + elm.companiesHouseNumber}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary">
                <img
                  src="images/company/companies-house-number.png"
                  alt="url"
                  className={classes.socialIcons}
                />
              </Button>
            </a>
          )}
        </div>
      ),
    },
  ];

  const handleConfirmDelete = async () => {
    const companiesList = companies?.data?.result;
    const record = deleteDisclosure.actionProps;

    try {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteCompany(record._id),
      );
      if (response.status === 200) {
        success(response.data.responseMsg);
        if (companiesList.length === 1) {
          pagination.pageBack();
        } else {
          companies.refetch();
        }
      }
    } catch (err) {
      error(err.response.data.responseMsg);
      companies.refetch();
    }
    deleteDisclosure.onClose();
  };

  const exportExcelSheet = async () => {
    setLoading(true);
    try {
      await dispatch(
        exportCompanies({
          isExport: true,
          ...filters,
          type: pagination.order,
          keyName: pagination.orderBy,
          includeSharesPerInvestee: true,
          fundId,
        }),
      );
    } catch (e) {
      error(e.response?.data?.responseMsg);
    }
    setLoading(false);
  };

  return (
    <PageContainer heading="Companies" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                {isSuperAdmin && (
                  <Grid item xs={12} md={4}>
                    <FirmSelector
                      firmId={filters.firmId}
                      allowDeselection
                      placeholder="Search by firm"
                      deselectionLabel="All firms"
                      onChange={(firmId) => {
                        handleChangeFilters({ firmId });
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <TextField
                    type="search"
                    name="companyName"
                    placeholder="Search by company name"
                    fullWidth
                    value={filters.companyName}
                    onChange={(event) => {
                      handleChangeFilters({ companyName: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={isSuperAdmin ? 4 : 8}>
                  <div className={classes.buttonContainer}>
                    {createPermission && (
                      <Link to={COMPANY_ADD}>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                        >
                          Add Company
                        </Button>
                      </Link>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={exportExcelSheet}
                      disabled={!companies?.data?.result?.length || isLoading}
                    >
                      {isLoading && (
                        <CircularProgress
                          style={{
                            color: 'rgba(0, 0, 0, 0.26)',
                            marginRight: 10,
                          }}
                          size={18}
                        />
                      )}
                      Export
                    </Button>
                  </div>
                </Grid>
              </GridContainer>
              <div className={classes.tableMt}>
                {
                  <Table
                    columns={columns}
                    onRequestSort={pagination.handleRequestSort}
                    order={pagination.order}
                    orderBy={pagination.orderBy}
                    tablebody={companies?.data?.result ?? []}
                    onPageChange={pagination.handleChangePage}
                    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                    page={pagination.page}
                    rowsPerPage={pagination.rowsPerPage}
                    count={companies?.data?.total}
                    pagination={true}
                    loading={companies.isFetching}
                    variant="nohover"
                  />
                }
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this company?`}
          content={
            <p>
              This cannot be undone and all information relating to the company
              will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete company',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default Company;
