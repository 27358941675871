import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  stepper: {
    background: 'transparent',
    marginBottom: '2rem',
    paddingLeft: 0,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    padding: theme.spacing(8),
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(4),
    marginTop: 10,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: theme.spacing(2),
  },
  toggle: {
    display: 'flex',
    gridGap: '.5rem',
    alignItems: 'center',
    marginRight: 15,
  },
  error: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  cardContentPadding: {
    padding: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonLeftMargin: {
    marginLeft: 12,
  },
}));

export default useStyles;
