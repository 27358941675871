// TODO: Refactor this file as events are from old screen
import { Button, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Table from 'components/Table';
import CmtAvatar from 'components/CmtAvatar';
import { Controller } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import Dialog from 'components/ConfirmDialog';
import AddPastInvestment from './AddPastInvestment';
import useFileUpload from 'hooks/ui/useFileUpload';
import { useDispatch } from 'react-redux';
import { Delete, Edit } from '@material-ui/icons';
import {
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from '@redux/actions/InvestmentFirm';
import { deleteNews } from '@redux/actions/Company';
import AlertDialog from 'components/AlertDialog';
import { useNotification } from 'hooks/ui/useNotification';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { UploadType } from 'further-types/files';
import useCommonStyles from '../commonStyles';

function FundPageInformation({
  control,
  firmId,
  portfolioData,
  errors,
  firm,
  isCreatePage,
}) {
  const commonClasses = useCommonStyles();
  const { uploadFile } = useFileUpload();
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState(portfolioData);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pastInvestmentId, setPastInvestmentId] = React.useState();
  const [btnLabels] = React.useState({ cancel: 'Cancel', confirm: 'Save' });
  const [record, setRecord] = React.useState();
  const { error, success } = useNotification();
  const handleApiRequest = useApiRequestHandler();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    React.useState(false);
  const [currentValue, setCurrentValue] = React.useState({
    title: '',
    currentPortfolioDescription: '',
    url: '',
    twitterUrl: '',
    linkedinUrl: '',
    isExited: false,
    order: '',
    firmPortfolioLogo: null,
    firmPortfolioLogoUrl: null,
    news: [],
  });

  const handleAddMore = (rec) => {
    setCurrentValue({
      title: firmId ? rec?.title : '',
      currentPortfolioDescription: firmId
        ? rec?.currentPortfolioDescription
        : '',
      url: firmId ? rec?.title : '',
      twitterUrl: firmId ? rec?.twitterUrl : '',
      linkedinUrl: firmId ? rec?.linkedinUrl : '',
      isExited: firmId ? rec?.isExited : false,
      order: firmId ? rec?.order : '',
      firmPortfolioLogo: firmId ? rec?.firmPortfolioLogo : null,
      firmPortfolioLogoUrl: firmId ? rec?.firmPortfolioLogoUrl : '',
      previewFirmPortfolioLogo: firmId ? rec?.firmPortfolioLogo : null,
      news: firmId && rec?.news ? rec?.news : [],
    });
    setPastInvestmentId(rec?._id);
    setDialogOpen(!dialogOpen);
  };

  const onClickClose = () => {
    setCurrentValue({
      title: '',
      currentPortfolioDescription: '',
      url: '',
      twitterUrl: '',
      linkedinUrl: '',
      isExited: false,
      order: '',
      firmPortfolioLogo: null,
      firmPortfolioLogoUrl: null,
      previewFirmPortfolioLogo: null,
      news: [],
    });
    setDialogOpen(false);
  };

  const handleConfirmPortfolio = async () => {
    if (!firmId) {
      const data = [...tableData, currentValue];
      setTableData(data);
    } else {
      if (pastInvestmentId) {
        currentValue.id = pastInvestmentId;
        if (currentValue?.firmPortfolioLogo?.includes('.com/')) {
          currentValue.firmPortfolioLogo =
            currentValue?.firmPortfolioLogo.split('.com/')[1];
        }
        await handleApiRequest(async (showSuccess) => {
          const updateResponse = await dispatch(updatePortfolio(currentValue));
          if (updateResponse) {
            showSuccess(updateResponse.responseMsg);
          }
          // eslint-disable-next-line no-unused-expressions
          tableData?.forEach((singleCurrent, index) => {
            if (singleCurrent?._id === updateResponse?.data._id) {
              tableData[index] = updateResponse.data;
            }
          });
          setTableData([...tableData]);
        });
      } else {
        currentValue.firmId = firmId;
        await handleApiRequest(async (showSuccess) => {
          const createResponse = await dispatch(createPortfolio(currentValue));
          if (createResponse) {
            showSuccess(createResponse.responseMsg);
          }
          setTableData([...tableData, createResponse.data]);
        });
      }
    }
    setDialogOpen(false);
  };

  const handleAddNews = () => {
    currentValue.news.push({ title: '', url: '', date: null });
    setCurrentValue({ ...currentValue });
  };

  const handleRemoveNews = (index) => {
    var selectedNews = currentValue?.news?.[index];
    if (selectedNews?._id) {
      dispatch(deleteNews(selectedNews?._id))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            // eslint-disable-next-line no-unused-expressions
            currentValue?.news?.splice(index, 1);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    } else {
      // eslint-disable-next-line no-unused-expressions
      currentValue?.news?.splice(index, 1);
    }
    setCurrentValue({ ...currentValue });
  };

  const handleInputChangeNews = (e, index, isDate) => {
    if (isDate) {
      currentValue.news[index]['date'] = e;
    } else {
      const { name, value } = e.target;
      currentValue.news[index][name] = value;
    }
    setCurrentValue({ ...currentValue });
  };

  const handleDeletePortFolio = () => {
    currentValue.firmPortfolioLogo = null;
    setCurrentValue({ ...currentValue });
  };

  const handleChangePortFolioLogo = async (files) => {
    if (!files?.[0]?.path) return;

    try {
      const { url, filePath } = await uploadFile(
        files[0],
        UploadType.FirmLogos,
      );

      setCurrentValue({
        ...currentValue,
        firmPortfolioLogo: filePath,
        firmPortfolioLogoUrl: url,
        previewFirmPortfolioLogo: files[0].preview,
      });
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDeleteDialog(!openConfirmDeleteDialog);
  };

  const handleConfirmDeletePortfolio = () => {
    let filter;
    if (firmId) {
      handleApiRequest(async (showSuccess) => {
        const response = await dispatch(deletePortfolio(record?._id));
        showSuccess(response.data.responseMsg);
        filter = tableData?.filter(function (value) {
          return value?._id !== record?._id;
        });
        setTableData(filter);
      });
    } else {
      filter = tableData?.filter(function (name) {
        return name?.title !== record?.title;
      });
      setTableData(filter);
    }
    setOpenConfirmDeleteDialog(!openConfirmDeleteDialog);
  };

  const columns = useMemo(
    () => [
      {
        label: 'Company logo',
        key: 'firmPortfolioLogo',
        render: (row) => {
          return (
            <div>
              <CmtAvatar
                size={40}
                src={
                  firmId && row._id
                    ? row.firmPortfolioLogo
                    : row.firmPortfolioLogoUrl
                }
              />
              <div>{row.firmName}</div>
            </div>
          );
        },
      },
      {
        label: 'Title',
        key: 'title',
      },
      {
        label: 'Company description',
        key: 'currentPortfolioDescription',
        ellipsis: true,
      },
      {
        label: 'Order',
        key: 'order',
      },
      {
        label: 'Actions',
        key: '',
        render: (elm) => (
          <div>
            {firmId ? (
              <Button onClick={() => handleAddMore(elm)} color="primary">
                <Edit />
              </Button>
            ) : (
              ''
            )}
            <Button onClick={() => handleDelete(elm)}>
              <Delete htmlColor="#E8453C" />
            </Button>
          </div>
        ),
      },
    ],
    [firmId],
  );

  return (
    <>
      <FieldRow title="Investment opportunities short description">
        <Controller
          name="description"
          defaultValue={firm.data?.description}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              multiline
              minRows={3}
              placeholder={`Max 125 characters. This text will be used on Further's "View Funds" page if in future you distribute your fund through Further.`}
              inputProps={{ maxLength: 125 }}
              fullWidth
            />
          )}
        />
        <p>{errors.description?.message}</p>
      </FieldRow>
      <FieldRow title="Tranche short description">
        <Controller
          name="fundDescription"
          defaultValue={firm.data?.fundDescription}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              multiline
              minRows={3}
              placeholder={`Max 200 characters. This text will be displayed at the top of your fund's summary page, under your fund name, when you use a fund page in your firm's investment flow.`}
              inputProps={{ maxLength: 200 }}
              fullWidth
            />
          )}
        />
        <p>{errors.fundDescription?.message}</p>
      </FieldRow>
      <FieldRow title="About your firm">
        <Controller
          name="aboutFirm"
          defaultValue={firm.data?.aboutFirm}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              multiline
              minRows={4}
              placeholder={`Please enter a description of your firm and it's history in approximately 200 words. This will be displayed on your fund page. Do not detail your investment strategy here, as this is addressed in the Tranches section.`}
              fullWidth
            />
          )}
        />
        <p>{errors.aboutFirm?.message}</p>
      </FieldRow>
      {!isCreatePage && (
        <div className={commonClasses.container}>
          <Typography className={commonClasses.bodyCopy}>
            Add the portfolio companies you wish to display on your fund page
            here. You may add as many past or present portfolio companies as you
            like. Please add at least 3 companies and ideally 6 or more. VCTs
            must add all portfolio companies.
          </Typography>
          <Table
            pagination={false}
            tablebody={tableData}
            columns={columns}
            order={'asc'}
            orderBy={'name'}
            page={1}
            rowsPerPage={1}
            variant="nohover"
          />
          <div className={commonClasses.buttonContainer}>
            <Button
              className={commonClasses.addMoreButton}
              onClick={() => setDialogOpen(true)}
              variant="text"
              color="primary"
              size="small"
            >
              Add more
            </Button>
          </div>
        </div>
      )}

      <Dialog
        open={dialogOpen}
        title={
          pastInvestmentId ? `Update Past Investment` : `Add Past Investments`
        }
        content={
          <AddPastInvestment
            setCurrentValue={setCurrentValue}
            currentValue={currentValue}
            handleDeletePortFolio={handleDeletePortFolio}
            handleChangePortFolioLogo={handleChangePortFolioLogo}
            handleInputChangeNews={handleInputChangeNews}
            handleRemoveNews={handleRemoveNews}
            handleAddNews={handleAddNews}
          />
        }
        onConfirm={handleConfirmPortfolio}
        onClose={onClickClose}
        btnLabels={btnLabels}
      />
      <AlertDialog
        open={openConfirmDeleteDialog}
        onClose={handleDelete}
        onConfirm={handleConfirmDeletePortfolio}
        title={`Are you sure you wish to delete this past investment?`}
        content={
          <p>
            This cannot be undone and all information relating to the past
            investment will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete past investment',
        }}
      />
    </>
  );
}

export default FundPageInformation;
