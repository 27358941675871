import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    background: '#fafafa',
    textAlign: 'center',
    borderRadius: '4px',
    width: '100%',
    padding: '20px',
    minHeight: '129.26px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  innerContent: {
    display: 'table-cell',
    verticalAlign: 'top',
  },
  cmdTitle: {
    fontSize: '26px',
  },
  cmdDesc: {
    fontSize: '14px',
    '& > span': {
      fontSize: '13px',
      color: '#8DCD03',
    },
  },
}));

const Boxlayout = ({ title, subtitle, children, titleTooltip, ...props }) => {
  const classes = useStyles();

  const boxContent = (
    <Box className={`${classes.boxRoot} ${props.classes}`}>
      <Box className={classes.innerContent}>
        <h5 className={classes.cmdTitle}>{title}</h5>
        <p className={classes.cmdDesc}>
          {subtitle && subtitle}
          {children && children}
        </p>
      </Box>
    </Box>
  );

  return titleTooltip ? (
    <Tooltip placement="top" arrow title={titleTooltip}>
      {boxContent}
    </Tooltip>
  ) : (
    boxContent
  );
};

export default Boxlayout;
