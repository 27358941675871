import axios from 'axios';
import {
  GET_SUBSCRIPTION_LIST,
  RESET_SUBSCRIPTION_LIST,
} from 'constants/actionTypes';

export const getSubscription = ({ page, perPage, keyName, type, search }) => {
  return (dispatch) => {
    return axios
      .get(`subscription/list`, {
        params: {
          page,
          perPage,
          type,
          keyName,
          search,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_SUBSCRIPTION_LIST,
            payload: response.data.data,
          });
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteSubscription = (id) => {
  return () => {
    return axios
      .delete(`subscription/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const resetSubcription = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SUBSCRIPTION_LIST,
    });
  };
};
