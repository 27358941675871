import React, { useEffect, useState } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router';
import clsx from 'clsx';
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import {
  BusinessCenter,
  SupervisedUserCircle,
  InfoOutlined,
} from '@material-ui/icons';
import {
  COMPANY,
  DASHBOARD_ALL_FIRMS,
  DASHBOARD_MY_FIRM,
} from 'constants/routes';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import HoverInfoCard from 'components/HoverInfoCard';
import PageLoader from 'components/PageLoader';
import { useFirmDashboard } from 'hooks/data/dashboards/useFirmDashboard';
import { TrancheDeploymentSummary } from 'components/DashboardWidgets/TrancheDeploymentSummary';
import { CompanyCategoryPieChart } from 'components/DashboardWidgets/CompanyCategoryPieChart';
import { EisAllotments } from 'components/DashboardWidgets/EisAllotments';
import { InvestmentsInflowSummary } from 'components/DashboardWidgets/InvestmentsInflowSummary';
import { TrancheCountsByStatus } from 'components/DashboardWidgets/TrancheCountsByStatus';
import CmtTitle from 'components/CmtTypography/CmtTitle';
import { Tooltip } from 'components/Tooltip';
import { OpenTranchesTable } from 'components/DashboardWidgets/OpenTranchesTable';
import { numberToShorthandCurrencyString } from 'further-ui/utils';
import { useGetRole } from 'hooks/ui/useGetRole';

const useStyles = makeStyles((theme) => ({
  scrollableTable: {
    maxHeight: '400px',
    overflow: 'auto',
    marginBottom: '24px',
    paddingBottom: '0 !important',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
    alignItems: 'stretch',

    '&> div': {
      height: 'auto',
    },
  },
  childDiv100: {
    '&> div': {
      height: '100%',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  viewAll: {
    color: theme.palette.text.rootColor,
    textTransform: 'inherit',
    fontWeight: 700,
    letterSpacing: '.5px',
    backgroundColor: 'transparent !important',
    padding: '0',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  compactCardHeader: {
    padding: '20px 30px 14px 25px',
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const FirmDashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const { firmId } = useParams();
  const { firmId: usersFirmId, isSuperAdmin } = useGetRole();
  const isMyFirmPage = useRouteMatch(DASHBOARD_MY_FIRM)?.isExact;
  const isAllFirmsDashboard = useRouteMatch(DASHBOARD_ALL_FIRMS)?.isExact;

  const [firmName, setFirmName] = useState('');
  const [investmentsInflowSummary, setInvestmentsInflowSummary] = useState({});
  const [capitalAwaitingDeployment, setCapitalAwaitingDeployment] = useState(0);
  const [companiesAssociated, setCompaniesAssociated] = useState([]);
  const [firmPortfolioBySector, setFirmPortfolioBySector] = useState([]);
  const [closedTrancheDeployments, setClosedTrancheDeployments] = useState([]);
  const [eisAllotments, setEisAllotments] = useState({});
  const [
    closedTranchesByDeploymentStatus,
    setClosedTranchesByDeploymentStatus,
  ] = useState({});
  const [openTranchesTableRows, setOpenTranchesTableRows] = useState([]);

  const firmIdToQuery = isMyFirmPage ? usersFirmId : firmId;
  const adminUserOnMyFirmsPage = isMyFirmPage && isSuperAdmin;

  const params = {};
  if (!isAllFirmsDashboard && !adminUserOnMyFirmsPage) {
    params.firmId = firmIdToQuery;
  }
  const firmDashboard = useFirmDashboard({ params });

  useEffect(() => {
    if (isAllFirmsDashboard && !isSuperAdmin) {
      history.push(DASHBOARD_MY_FIRM);
    }
  }, [isAllFirmsDashboard, isSuperAdmin]);

  useEffect(() => {
    const response = firmDashboard.data;
    if (response) {
      setFirmName(response?.firmName);
      setInvestmentsInflowSummary(response?.investmentsInflowSummary);
      setCompaniesAssociated(response?.companiesAssociated);
      setFirmPortfolioBySector(response?.firmPortfolioBySector);
      setClosedTrancheDeployments(response?.closedTrancheDeployments);
      setEisAllotments(response?.eisAllotments);
      setClosedTranchesByDeploymentStatus(
        response?.closedTranchesByDeploymentStatus,
      );
      setOpenTranchesTableRows(response?.openTranchesTableRows);
      setCapitalAwaitingDeployment(response?.capitalAwaitingDeployment);
    }
  }, [firmDashboard.data]);

  if (firmDashboard.isFetching) return <PageLoader />;

  return (
    <GridContainer spacing={5}>
      <Grid item xs={12}>
        <div>
          <h3 className={classes.masth}>
            {isAllFirmsDashboard || adminUserOnMyFirmsPage
              ? 'Master dashboard'
              : `${firmName} master dashboard`}
          </h3>
        </div>
        <div style={{ marginTop: '24px' }}></div>
      </Grid>

      <Grid item md={9} xs={12} className={classes.flexGrid}>
        <InvestmentsInflowSummary
          investmentsInflowSummary={investmentsInflowSummary}
          parentClasses={classes}
        />
      </Grid>

      <Grid
        item
        md={3}
        xs={12}
        className={clsx(classes.flexGrid, classes.childDiv100)}
      >
        <HoverInfoCard
          icon={<BusinessCenter style={{ color: '#ffffff' }} />}
          backgroundColor="#56B26C"
          title={companiesAssociated}
          subTitle="Companies"
          linkOnArrow={COMPANY}
        />
        <HoverInfoCard
          icon={<SupervisedUserCircle style={{ color: '#ffffff' }} />}
          backgroundColor="#ECC9A2"
          title={numberToShorthandCurrencyString(capitalAwaitingDeployment)}
          subTitle="Capital awaiting deployment"
        />
      </Grid>

      <Grid item md={8} xs={12} className={classes.flexGrid}>
        <EisAllotments eisAllotments={eisAllotments} classes={classes} />
        <TrancheCountsByStatus
          trancheCountsByStatus={closedTranchesByDeploymentStatus}
          classes={classes}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <CmtCard className={classes.flexGrow}>
          <CmtCardContent style={{ marginTop: '1rem' }}>
            <CompanyCategoryPieChart
              title="Fund portfolio by sector"
              subtitle="(Ordered by current value)"
              deploymentData={firmPortfolioBySector}
              height={390}
              outerRadius={110}
              innerRadius={80}
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>

      <Grid item xs={12}>
        <OpenTranchesTable rows={openTranchesTableRows} classes={classes} />
      </Grid>

      {closedTrancheDeployments.length > 0 && (
        <Grid
          item
          xs={12}
          className={classes.dFlex}
          style={{ margin: '1.5rem 0 0.5rem' }}
        >
          <CmtTitle content="Tranche deployment progress" />
          <Tooltip
            arrow
            title="This section is an overview of the fund's closed tranches that are currently in deployment. To view all tranches (including deployed tranches), navigate to the Closed Tranches section in the side menu."
          >
            <IconButton>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {/* only show 100 closed tranches for now, in lieu of proper pagination/searching */}
      {closedTrancheDeployments.slice(0, 100).map((closedTranche, i) => (
        <Grid item md={6} xs={12} key={i}>
          <TrancheDeploymentSummary
            tranche={closedTranche.tranche}
            deploymentSummary={closedTranche.deploymentSummary}
            classes={classes}
            displayFundsRaisedInsteadOfDeployed
          />
        </Grid>
      ))}
    </GridContainer>
  );
};

export default FirmDashboard;
