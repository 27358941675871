import { investorTypeToLabel } from 'utils/investorType';
import { InvestorType } from 'further-types/investor';
import { dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { Button, makeStyles } from '@material-ui/core';
import { useGetRole } from 'hooks/ui/useGetRole';
import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles(() => ({
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  certificationRow: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  reviewBtn: {
    height: 26,
  },
}));

type Props = {
  certification: {
    _id: string;
    investorType: InvestorType;
    certificationPerformedAt: string;
  };
  onReset: () => void;
  onDownload: (certification: Props['certification']) => void;
  isResetting: boolean;
};

const CurrentCertificationResult: React.FC<Props> = ({
  certification,
  onReset,
  isResetting,
  onDownload,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const resetDisclosure = useDisclosure();

  return (
    <div key={certification._id} className={classes.certificationRow}>
      <div>
        <span className={classes.value}>
          {investorTypeToLabel(certification.investorType)}
        </span>
        <span>
          {` ${dateToLabel(
            certification.certificationPerformedAt,
            null,
            true,
          )}`}
        </span>
      </div>
      <Button
        className={classes.reviewBtn}
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => {
          onDownload(certification);
        }}
      >
        Download investor certification
      </Button>
      {isSuperAdmin && (
        <Button
          className={classes.reviewBtn}
          size="small"
          variant="outlined"
          color="primary"
          disabled={isResetting}
          onClick={resetDisclosure.onOpen}
        >
          Reset certification status
        </Button>
      )}
      <AlertDialog
        onClose={resetDisclosure.onClose}
        open={resetDisclosure.isOpen}
        title="Warning"
        content="Are you sure you wish to reset this user’s investor certification? This cannot be undone and they will be requested to re-classify themselves when they next make an investment."
        btnLabels={{ confirm: 'Reset', cancel: 'Go back' }}
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          disabled: isResetting,
        }}
        onConfirm={() => {
          onReset();
          resetDisclosure.onClose();
        }}
      />
    </div>
  );
};

export default CurrentCertificationResult;
