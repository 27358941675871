import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtVertical from 'components/CmtNavigation';
import { createSidebarNav } from 'helpers/layout/menus';
import {
  createOpenTrancheRoute,
  createSingleFirmRoute,
} from 'constants/routes';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useFirm } from 'hooks/data/firm/useFirm';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 270px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 270px)',
    },
  },
}));

const SidebarInner = () => {
  const classes = useStyles();
  const [menuItems, setMenuItems] = useState(createSidebarNav());
  const { isFirmManager } = useGetRole();
  const { tranches } = useFunds({ fundStatus: 1, leanResponse: true });
  const { firms } = useFirm({ params: {} });

  useEffect(() => {
    if (tranches.data && tranches.data?.length) {
      const openTrancheDashboardLinks = [];
      tranches.data.forEach((fund) => {
        openTrancheDashboardLinks.push({
          name: `${fund.firmId.firmName} ${fund.fundName}`,
          type: 'item',
          link: createOpenTrancheRoute(fund?._id),
        });
      });

      if (isFirmManager) {
        setMenuItems(createSidebarNav(openTrancheDashboardLinks));
      } else if (
        !isFirmManager &&
        firms.data?.result &&
        firms.data?.result?.length
      ) {
        const firmDashboardLinks = [];
        firms.data.result.forEach((firm) => {
          firmDashboardLinks.push({
            name: `${firm.firmName}`,
            type: 'item',
            link: createSingleFirmRoute(firm?._id),
          });
        });

        setMenuItems(
          createSidebarNav(openTrancheDashboardLinks, firmDashboardLinks),
        );
      }
    }
  }, [tranches.data, firms.data]);

  return (
    <React.Fragment>
      {!menuItems ? (
        ''
      ) : (
        <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
          <CmtVertical menuItems={menuItems} />
        </PerfectScrollbar>
      )}
    </React.Fragment>
  );
};

export default SidebarInner;
