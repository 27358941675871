import React from 'react';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { Divider } from '@material-ui/core';
import PieChart, { useStyles } from 'components/PieChart';
import { RegionKeyToLabelMap } from 'constants/labels';
import { numberToCurrencyString } from 'further-ui/utils';

export const InvestmentsByLocation = ({ investmentData, classes }) => {
  const classnames = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader
        title="Investments by location"
        subTitle="(Ordered by initial investment)"
      ></CmtCardHeader>

      <Divider className={classes.divider} />
      <CmtCardContent>
        <PieChart
          height={300}
          data={Object.entries(investmentData)
            .filter(([_key, regionData]) => regionData.countOfInvestments > 0)
            .map(([key, regionData]) => ({
              key: RegionKeyToLabelMap[key],
              value: regionData.initialInvestment,
              ...regionData,
            }))}
          noDataMessage="No investments yet"
          CustomTooltip={({ payload }) => {
            const item = payload[0];
            if (!item) return null;

            return (
              <div className={classnames.tooltipWrapper}>
                <p>
                  <strong>{item.name}</strong>
                </p>
                <p>
                  Initial investment:{' '}
                  {numberToCurrencyString(item.value, {
                    hidePenniesIfInteger: true,
                  })}
                </p>
                <p>Investments: {item.payload?.countOfInvestments}</p>
              </div>
            );
          }}
        />
      </CmtCardContent>
    </CmtCard>
  );
};
