import CmtCard from 'components/CmtCard';
import PageContainer from 'components/PageContainer';
import useStyles from './styles';

import InvestmentTransferForm, {
  SubmittedCashBalance,
  SubmittedShareholding,
} from './InvestmentTransferForm';
import { Grid, Typography } from '@material-ui/core';
import { useMemo, useState } from 'react';
import Summary from './Summary';
import { useHistory, useParams } from 'react-router';
import Error from '../Common/Error';
import InvestorSearchRow from './InvestorSearchRow';
import SuccessMessage from './SuccessMessage';
import useInvestmentTransfer from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import { useGetRole } from 'hooks/ui/useGetRole';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { useFirm } from 'hooks/data/firm/useFirm';
import {
  CreateAddInvestmentTransferStep,
  createAddInvestmentTransferRoute,
} from 'constants/routes';
import useLocalStorage from 'hooks/ui/useLocalStorage';

const headingText = 'Investors: Transfer investments';

type SavedData = {
  recipientId: string | null;
  investorId: string | null;
  summary: {
    shareholdings: SubmittedShareholding[];
    cashBalances: SubmittedCashBalance[];
  } | null;
  firmId: string | null;
};

const AddInvestmentTransfer = () => {
  const classes = useStyles();
  const { isSuperAdmin, firmId: userFirmId } = useGetRole();
  const { firms } = useFirm({ params: {} });
  const { push } = useHistory();
  const { investorId, step = CreateAddInvestmentTransferStep.Form } =
    useParams<{
      investorId: string;
      step: CreateAddInvestmentTransferStep;
    }>();

  const { getData, saveData, clear } = useLocalStorage<SavedData>(
    'investment-transfer',
    {
      defaultData: {
        recipientId: null,
        investorId: null,
        firmId: null,
        summary: null,
      },
      validate: (data) =>
        (!userFirmId || data.firmId === userFirmId) &&
        data.investorId === investorId,
    },
  );

  const initialData = useMemo(getData, []);

  const [firmId, setFirmId] = useState(initialData.firmId ?? userFirmId);
  const [recipientId, setRecipientId] = useState<string>(
    initialData.recipientId,
  );
  const [transferId, setTransferId] = useState<string>();

  const handleSharesTransferSubmit = (
    submittedShareholdings: SubmittedShareholding[],
    submittedCashBalances: SubmittedCashBalance[],
  ) => {
    saveData({
      summary: {
        shareholdings: submittedShareholdings,
        cashBalances: submittedCashBalances,
      },
      investorId,
      recipientId,
      firmId,
    });

    push(
      createAddInvestmentTransferRoute(
        investorId,
        CreateAddInvestmentTransferStep.Summary,
      ),
    );
  };

  const handleRecipientSelect = (recipientId?: string) => {
    setRecipientId(recipientId);
  };

  const handleChangeFirm = (selectedFirmId: string) => {
    setFirmId(selectedFirmId);
  };

  const handleInvestmentTransferCreated = (investmentTransfer: {
    _id: string;
  }) => {
    clear();
    setTransferId(investmentTransfer._id);
  };

  const { createInvestmentTransfer, isCreatingInvestmentTransfer } =
    useInvestmentTransfer({ onCreate: handleInvestmentTransferCreated });

  const { summary } = initialData;

  const handleSubmit = () => {
    if (!recipientId) return;

    createInvestmentTransfer({
      firmId,
      recipientId,
      sourceInvestorId: investorId,
      shareholdings:
        summary?.shareholdings.map(({ id, investment, sharesToTransfer }) => ({
          shareholdingId: id ?? '',
          investmentId: investment?.id ?? '',
          sharesToTransfer,
        })) ?? [],
      cashBalances:
        summary?.cashBalances.map(({ balanceToTransfer, firm }) => ({
          balanceToTransfer,
          firmId: firm?._id,
        })) ?? [],
    });
  };

  if (!investorId) {
    return (
      <PageContainer heading={headingText}>
        <CmtCard className={classes.container}>
          <Error message="You have to select investor to create a transfer." />
        </CmtCard>
      </PageContainer>
    );
  }

  if (transferId) {
    return (
      <PageContainer heading={headingText}>
        <SuccessMessage sourceInvestorId={investorId} transferId={transferId} />
      </PageContainer>
    );
  }

  if (step === CreateAddInvestmentTransferStep.Summary && summary) {
    return (
      <PageContainer heading={headingText}>
        <CmtCard className={classes.container}>
          <Grid container spacing={6} direction="column">
            <Summary
              shareholdings={summary.shareholdings}
              cashBalances={summary.cashBalances}
              recipientId={recipientId}
              sourceInvestorId={investorId}
              onSubmit={handleSubmit}
              isCreatingInvestmentTransfer={isCreatingInvestmentTransfer}
            />
          </Grid>
        </CmtCard>
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={headingText}>
      <CmtCard className={classes.container}>
        <Grid container spacing={6} direction="column">
          <Grid item>
            <Typography variant="h2" color="textSecondary">
              1. Select firm and recipient investor account
            </Typography>
          </Grid>
          {isSuperAdmin && (
            <Grid item xs={12}>
              {
                <AppSelectBox
                  required
                  data={firms?.data?.result}
                  valueKey="_id"
                  labelKey="firmName"
                  label="Firm"
                  value={firmId}
                  variant="outlined"
                  onChange={(event) =>
                    handleChangeFirm(event.target.value as string)
                  }
                />
              }
            </Grid>
          )}
          {firmId ? (
            <>
              <InvestorSearchRow
                recipientId={recipientId}
                handleRecipientSelect={handleRecipientSelect}
                firmId={firmId}
              />
              {recipientId ? (
                <InvestmentTransferForm
                  defaultValues={summary}
                  firmId={firmId}
                  sourceInvestorId={investorId}
                  onSubmit={handleSharesTransferSubmit}
                />
              ) : null}
            </>
          ) : null}
        </Grid>
      </CmtCard>
    </PageContainer>
  );
};

export default AddInvestmentTransfer;
